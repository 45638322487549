var render = function render(){var _vm=this,_c=_vm._self._c;return _c('f7-actions',{ref:"modifiers",attrs:{"opened":_vm.show},on:{"actions:open":_vm.init,"actions:opened":_vm.selectRequiredModifiers,"actions:closed":_vm.reset}},[_c('f7-actions-group',[_c('f7-actions-label',{staticClass:"justify-content-space-between px-3"},[(_vm.variation)?_c('div',[_c('p',{staticClass:"m-0"},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.locale === 'en' ? _vm.variation.name : ( _vm.variation.alt_lang || _vm.variation.name))+"\n\t\t\t\t")]),_vm._v(" "),_c('small',{staticClass:"text-capitalize"},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t('total'))+": "+_vm._s(_vm.toCurrency(_vm.variation.original_price + _vm.selectedModifiersPrice))+"\n\t\t\t\t")])]):_vm._e(),_vm._v(" "),_c('f7-link',{attrs:{"icon-f7":"xmark","icon-size":"20px"},on:{"click":function($event){return _vm.$refs.modifiers.close()}}})],1),_vm._v(" "),(_vm.variation)?_c('div',{staticClass:"page-bg-color"},[_vm._l((_vm.variation.modifiers),function(modifier,modifierIndex){return _c('div',{key:modifierIndex},[_c('f7-block-title',{staticClass:"m-0 p-3"},[_vm._v("\n\t\t\t\t\t"+_vm._s(modifier.name)+"\n\t\t\t\t")]),_vm._v(" "),_c('f7-list',{staticClass:"m-0",attrs:{"media-list":""}},_vm._l((modifier.items),function(item,itemIndex){return _c('f7-list-item',{key:itemIndex,attrs:{"disabled":_vm.validateModifier(_vm.variation, modifier, item),"name":`${modifier.id}-${modifier.name.toLowerCase()}`,"title":_vm.locale === 'en' ? item.name : ( item.alt_lang || item.name),"footer":_vm.toCurrency(item.price),"checkbox":modifier.multi_choice,"radio":!modifier.multi_choice,"value":JSON.stringify({
							group_id: modifier.id,
							item_id: item.id,
							item_name: item.name,
							price: item.price,
							qty: 1
						})},on:{"change":_vm.checkModifiers}})}),1)],1)}),_vm._v(" "),_c('div',{staticClass:"p-3"},[_c('f7-button',{attrs:{"large":"","raised":"","fill":""},on:{"click":_vm.applyModifiers}},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t('addItem'))+"\n\t\t\t\t")])],1)],2):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }