<template>
	<f7-page
		id="cart"
		ptr
		:ptr-distance="60"
		@ptr:refresh="refresh"
	>
		<f7-navbar
			:title="$t('cart')"
			:subtitle="cart.store ? `${cart.items.length} ${ $tc('item', cart.items.length > 1 ? 2 : 1) } | ${
				cart.store.name
			}` : ''"
			inner-class="text-capitalize"
			back-link
		>
			<f7-nav-right>
				<f7-link v-if="cart.items.length > 0" icon-f7="trash" @click="emptyCart" />
			</f7-nav-right>
		</f7-navbar>
		<div v-if="cart.items.length > 0">
			<items :loading="loading.stock" :items="cart.items" />
			<div v-if="!loading.stock">
				<f7-list class="my-0">
					<f7-list-item
						link="#"
						:header="$t('order type')"
						:title="selectedOrderType ? $options.filters.capitalize($t(selectedOrderType.name.toLowerCase())) : $t('chooseOrderType')"
						:after="$options.filters.capitalize($t('change'))"
						@click="showOrderTypes = true"
					>
						<div slot="footer">
							<span v-if="validation.orderType" class="text-color-red">
								{{ $t('orderTypeRequired') }}
							</span>
							<span v-else-if="selectedOrderType && selectedOrderType.slug === 'pickup' && storeOperationalHours" class="text-capitalize">
								{{ $tc('timing', 2) }}: {{ storeOperationalHours }}
							</span>
						</div>
					</f7-list-item>
					<f7-list-item
						v-if="selectedOrderType && selectedOrderType.slug === 'delivery' && cart.store.settings.delivery_mode === 'slot' && merchant.subscription.products.advance_ordering"
						link="#"
						:header="$t('delivery slot')"
						:after="$options.filters.capitalize($t('change'))"
						@click="showDeliverySlots = true"
					>
						<div slot="title" class="text-capitalize">
							{{ deliverySlot ? `${
								$moment(deliverySlot.start_datetime).local().locale(locale).format('Do MMM')
							} ${$moment.utc(deliverySlot.start_datetime).local().locale(locale).format('hh:mm A')} - ${
								$moment.utc(deliverySlot.end_datetime).local().locale(locale).format('hh:mm A')
							}` : $t('chooseDeliverySlot') }}
						</div>
						<div v-if="validation.deliverySlot" slot="footer" class="text-color-red">
							{{ $t('deliverySlotRequired') }}
						</div>
					</f7-list-item>
					<f7-list-item
						v-if="appliedCoupon"
						:title="appliedCoupon.code || appliedCoupon.name"
						:footer="$t('offerApplied')"
						no-chevron
					>
						<f7-icon slot="media" material="local_offer" color="gray" />
						<f7-link
							slot="after"
							link="#"
							icon-f7="xmark_circle_fill"
							icon-color="gray"
							@click="$store.dispatch('removeDiscount')"
						/>
					</f7-list-item>
					<f7-list-item
						v-else
						class="text-capitalize"
						:link="user ? '/coupons' : '/login'"
						:title="$t('apply coupon')"
					>
						<f7-icon slot="media" f7="tag_circle" color="gray" />
					</f7-list-item>
				</f7-list>
				<div class="p-3">
					<h4 class="text-capitalize mt-0">
						{{ $t('bill details') }}
					</h4>
					<f7-row>
						<f7-col>
							<span class="text-capitalize">
								{{ $t('item total') }}
							</span>
						</f7-col>
						<f7-col>
							<span class="float-right">
								{{ toCurrency(cart.price.subtotal) }}
							</span>
						</f7-col>
					</f7-row>
					<f7-row
						v-if="!merchant.settings.general.tax_calculation_phase ||
							merchant.settings.general.tax_calculation_phase === 'before_discount' &&
							cart.price.tax"
					>
						<f7-col>
							<span class="text-capitalize">
								{{ $t('total tax') }}
							</span>
						</f7-col>
						<f7-col>
							<span class="float-right">
								{{ toCurrency(cart.price.tax) }}
							</span>
						</f7-col>
					</f7-row>
					<f7-row v-if="cart.price.discount">
						<f7-col>
							<span class="text-capitalize">
								{{ $t('total discount') }}
							</span>
						</f7-col>
						<f7-col>
							<span class="float-right">
								-{{ toCurrency(cart.price.discount) }}
							</span>
						</f7-col>
					</f7-row>
					<f7-row v-if="merchant.settings.general.tax_calculation_phase === 'after_discount' && cart.price.tax">
						<f7-col>
							<span class="text-capitalize">
								{{ $t('total tax') }}
							</span>
						</f7-col>
						<f7-col>
							<span class="float-right">
								{{ toCurrency(cart.price.tax) }}
							</span>
						</f7-col>
					</f7-row>
					<hr v-if="cart.price.charges.length" class="border-top">
					<f7-row v-for="(charge, index) in cart.price.charges" :key="index">
						<f7-col>
							<span class="text-capitalize">
								{{ charge.name }}
							</span>
						</f7-col>
						<f7-col>
							<span class="float-right">
								{{ toCurrency(charge.amount) }}
							</span>
						</f7-col>
					</f7-row>
					<hr v-if="cart.price.round_off_amount" class="border-top">
					<f7-row v-if="cart.price.round_off_amount">
						<f7-col>
							<span class="text-capitalize">
								{{ $t('round off') }}
							</span>
						</f7-col>
						<f7-col>
							<span class="float-right">
								{{ toCurrency(cart.price.roundOff) }}
							</span>
						</f7-col>
					</f7-row>
					<hr class="border-top">
					<f7-row>
						<f7-col class="font-weight-bold">
							<span class="text-capitalize">
								{{ $t('toPay') }}
							</span>
						</f7-col>
						<f7-col>
							<span class="float-right">
								{{ toCurrency(cart.price.total) }}
							</span>
						</f7-col>
					</f7-row>
				</div>
			</div>
		</div>
		<div
			v-else
			class="h-100 display-flex justify-content-center align-items-center"
			style="flex-direction: column"
		>
			<img src="/images/empty-cart.svg" height="200px">
			<h2>
				{{ $t('cartEmpty') }}
			</h2>
		</div>
		<div
			v-if="!loading.stock && cart.items.length > 0 && cart.price.total > 0"
			class="toolbar-button-container"
		>
			<f7-card v-if="selectedOrderType && selectedOrderType.slug === 'delivery' && deliveryAddress" class="m-0 mb-3">
				<f7-card-content class="p-0">
					<f7-row class="align-items-center" no-gap>
						<f7-col width="20" class="text-align-center">
							<f7-icon f7="placemark" class="mb-1" />
							<small class="display-block font-weight-bold">
								{{ deliveryAddress.distance.text }}
							</small>
						</f7-col>
						<f7-col width="80">
							<div class="py-2 pr-2" style="line-height: 1">
								<div class="display-flex justify-content-space-between align-items-center mb-2">
									<div>
										<h4 class="text-capitalize m-0">
											{{ $t('deliverTo', [$t(deliveryAddress.annotation)]) }}
										</h4>
									</div>
									<f7-button small round fill @click="showAddresses = true">
										{{ $t('change') }}
									</f7-button>
								</div>
								<p class="text-color-gray m-0">
									{{ deliveryAddress.line }}
								</p>
							</div>
						</f7-col>
					</f7-row>
				</f7-card-content>
			</f7-card>
			<f7-button
				v-if="!selectedOrderType || selectedOrderType.slug === 'pickup' || deliveryAddress"
				large
				fill
				raised
				@click="user ? initPayments() : $f7router.navigate('/login')"
			>
				{{ $t("proceedToPay") }}
			</f7-button>
			<f7-button
				v-else
				large
				fill
				raised
				:disabled="cart.items.length === 0"
				@click="user ? showAddresses = true : $f7router.navigate('/login')"
			>
				{{ $t('addAddress') }}
			</f7-button>
		</div>
		<f7-actions
			v-if="cart.store && cart.store.settings.order_types.length"
			ref="orderTypesActionSheet"
			:opened="showOrderTypes"
			@actions:closed="showOrderTypes = false"
		>
			<f7-actions-group>
				<f7-actions-label class="justify-content-space-between px-3 text-capitalize">
					{{ $t('chooseOrderType') }}
					<f7-link
						icon-f7="xmark"
						icon-size="20px"
						@click="showOrderTypes = false"
					/>
				</f7-actions-label>
				<f7-list class="m-0">
					<f7-list-item
						v-for="(orderType, index) in cart.store.settings.order_types"
						:key="index"
						class="text-capitalize"
						name="order-type"
						:title="$t(orderType.name.toLowerCase())"
						:checked="selectedOrderType && selectedOrderType.slug === orderType.slug"
						radio
						@click="setSelectedOrderType(orderType)"
					/>
				</f7-list>
			</f7-actions-group>
		</f7-actions>
		<f7-actions
			v-if="cart.store && cart.store.settings.delivery_mode === 'slot' && merchant.subscription.products.advance_ordering"
			id="delivery-slots"
			ref="deliverySlotsActionSheet"
			:opened="showDeliverySlots"
			@actions:open="getDeliverySlots"
			@actions:closed="showDeliverySlots = false"
		>
			<f7-actions-group>
				<f7-actions-label class="justify-content-space-between px-3 text-capitalize">
					{{ $t('chooseDeliverySlot') }}
					<f7-link
						icon-f7="xmark"
						icon-size="20px"
						@click="showDeliverySlots = false"
					/>
				</f7-actions-label>
				<div v-if="loading.slots" class="page-bg-color text-align-center p-3">
					<f7-preloader />
				</div>
				<div v-else class="page-bg-color">
					<f7-list class="m-0" accordion-list>
						<f7-list-item
							v-for="(slots, index) in deliverySlots"
							:key="index"
							:title="$moment(index).locale(locale).calendar({
								sameDay: 'Do MMM - [Today]',
								nextDay: 'Do MMM - [Tomorrow]',
								nextWeek: 'Do MMM - dddd',
								sameElse: 'Do MMM - dddd'
							})"
							accordion-item
						>
							<f7-accordion-content>
								<f7-list>
									<f7-list-item
										v-for="(slot, slotIndex) in slots"
										:key="slotIndex"
										name="delivery-slot"
										:title="`${$moment.utc(slot.start_datetime).local().locale(locale).format('hh:mm A')} - ${
											$moment.utc(slot.end_datetime).local().locale(locale).format('hh:mm A')
										}`"
										:checked="JSON.stringify(deliverySlot) === JSON.stringify(slot)"
										radio
										@click="setDeliverySlot(slot)"
									/>
								</f7-list>
							</f7-accordion-content>
						</f7-list-item>
					</f7-list>
				</div>
			</f7-actions-group>
		</f7-actions>
		<f7-actions
			v-if="cart.items.length > 0"
			:opened="showAddresses"
			@actions:open="getAddresses"
			@actions:closed="showAddresses = false"
			@action:done="updateAddresses"
		>
			<f7-actions-group>
				<f7-actions-label class="justify-content-space-between px-3 text-capitalize">
					{{ $t('chooseDeliveryAddress') }}
					<f7-link
						icon-f7="xmark"
						@click="showAddresses = false"
					/>
				</f7-actions-label>
				<div v-if="loading.addresses" class="page-bg-color text-align-center p-3">
					<f7-preloader />
				</div>
				<div v-else class="page-bg-color p-3">
					<f7-card
						v-for="(address, index) in addresses"
						:key="index"
						class="elevation-4 m-0 mb-3"
						@click.native="selectAddress(address)"
					>
						<f7-card-content class="p-0">
							<f7-row class="align-items-center" no-gap>
								<f7-col width="20" class="text-align-center">
									<f7-icon f7="placemark" />
								</f7-col>
								<f7-col width="80">
									<div class="py-2 pr-2" style="line-height: 1">
										<h4 class="text-capitalize m-0 mb-1">
											{{ $t(address.annotation) }}
										</h4>
										<p class="text-color-gray m-0">
											{{ address.door_no ? `${address.door_no}, ` : null }} {{ address.line }}
										</p>
									</div>
								</f7-col>
							</f7-row>
						</f7-card-content>
					</f7-card>
					<p v-if="addresses.length === 0" class="text-align-center mt-0 mb-3">
						{{ $t('noAddressFound') }}
					</p>
					<f7-button large fill raised @click="addNewAddress">
						{{ $t('addNewAddress') }}
					</f7-button>
				</div>
			</f7-actions-group>
		</f7-actions>
		<f7-actions
			ref="addNotes"
			:opened="selectedCartItemIndex !== -1"
			@actions:open="getNotes"
			@actions:closed="resetNote"
		>
			<f7-actions-group>
				<f7-actions-label class="justify-content-space-between px-3 py-0 text-capitalize">
					<p>
						<span>{{ $tc('note', 1) }}</span>
						<span v-if="selectedCartItemIndex !== -1 && cart.items[selectedCartItemIndex].variations[cart.items[selectedCartItemIndex].variation_index]">
							- {{ cart.items[selectedCartItemIndex].variations[cart.items[selectedCartItemIndex].variation_index].name }}
						</span>
					</p>
					<f7-link
						icon-f7="xmark"
						icon-size="20px"
						@click="$refs.addNotes.close()"
					/>
				</f7-actions-label>
				<div class="page-bg-color text-align-center p-3">
					<f7-list no-hairlines-md class="mb-3 mt-0">
						<f7-list-input
							v-model="notes"
							type="textarea"
							:placeholder="$t('typeHere')"
							outline
							resizable
							clear-button
							:value="notes"
							@input="notes = $event.target.value"
							@input:clear="clearNote"
						/>
					</f7-list>
					<f7-button large raised fill @click="addNote">
						{{ $t('add') }}
					</f7-button>
				</div>
			</f7-actions-group>
		</f7-actions>
	</f7-page>
</template>

<script>
	import Items from '~/components/items'

	export default {
		components: {
			Items
		},
		data () {
			return {
				loading: {
					stock: false,
					slots: false,
					addresses: false
				},
				showOrderTypes: false,
				deliverySlots: {},
				showDeliverySlots: false,
				showAddresses: false,
				validation: {
					orderType: false,
					deliverySlot: false
				},
				notes: null,
				selectedCartItemIndex: -1
			}
		},
		computed: {
			locale () {
				return this.$store.state.locale
			},
			merchant () {
				return this.$store.state.merchant
			},
			user () {
				return this.$store.state.user
			},
			processing: {
				get () {
					return this.$store.state.processing
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'processing',
						value
					})
				}
			},
			cart: {
				get () {
					return this.$store.state.cart
				},
				set (value) {
					this.$store.commit('setState', { key: 'cart', value })
				}
			},
			storeOperationalHours () {
				const today = this.$moment().format('dddd').toLowerCase()
				let operationalHours = null

				if (this.cart.store && this.cart.store.timings[today] && this.cart.store.timings[today].length) {
					operationalHours = this.cart.store.timings[today].map((t) => {
						return `${this.$moment(t.start_time, 'HH:mm:ss').format('hh:mm A')} - ${
							this.$moment(t.end_time, 'HH:mm:ss').format('hh:mm A')
						}`
					}).join(' | ')
				}

				return operationalHours
			},
			addresses () {
				return this.$store.state.addresses
			},
			selectedOrderType: {
				get () {
					return this.$store.state.selectedOrderType
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'selectedOrderType',
						value
					})
				}
			},
			deliverySlot: {
				get () {
					return this.$store.state.deliverySlot
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'deliverySlot',
						value
					})
				}
			},
			deliveryAddress: {
				get () {
					return this.$store.state.deliveryAddress
				},
				set (value) {
					this.$store.commit('setState', { key: 'deliveryAddress', value })
				}
			},
			appliedCoupon: {
				get () {
					return this.$store.state.appliedCoupon
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'appliedCoupon',
						value
					})
				}
			}
		},
		watch: {
			cart (cart) {
				if (cart.items.length === 0) {
					this.appliedCoupon = null
					this.deliveryAddress = null
					this.calculatePadding()
				}
			},
			selectedOrderType (selectedOrderType) {
				if (selectedOrderType) {
					this.validation.orderType = false

					if (selectedOrderType.slug === 'pickup') {
						this.deliverySlot = null
						this.deliveryAddress = null
					}
				}

				this.calculatePadding()
				this.$store.dispatch('cartCalculation')
			},
			deliverySlot (deliverySlot) {
				if (deliverySlot) {
					this.validation.deliverySlot = false
				}
			},
			addresses (addresses) {
				if (addresses.length === 0 || (this.deliveryAddress && addresses.findIndex((a) => {
					return a.id === this.deliveryAddress.id
				}) === -1)) {
					this.deliveryAddress = null
				}
			}
		},
		beforeMount () {
			if (this.cart.store && this.cart.store.settings.order_types.length && !this.selectedOrderType) {
				const defaultOrderType = this.cart.store.settings.order_types.find(ot => ot.default)

				this.selectedOrderType = defaultOrderType || null
			}

			this.checkStock()
		},
		mounted () {
			if (!this.$root._events.deliveryAddress) {
				this.$root.$on('deliveryAddress', this.selectAddress)
			}

			if (!this.$root._events.showNotes || this.$root._events.showNotes.length === 0) {
				this.$root.$on('showNotes', this.showNotes)
			}
		},
		destroyed () {
			this.$root.$off('showNotes', this.showNotes)
		},
		methods: {
			showNotes (selectedCartItemIndex) {
				this.selectedCartItemIndex = selectedCartItemIndex
			},
			getNotes () {
				this.notes = this.cart.items[this.selectedCartItemIndex].variations[this.cart.items[this.selectedCartItemIndex].variation_index].notes || ''
			},
			addNote () {
				this.cart.items[this.selectedCartItemIndex].variations[this.cart.items[this.selectedCartItemIndex].variation_index].notes = this.notes
				this.$refs.addNotes.close()
			},
			clearNote () {
				this.cart.items[this.selectedCartItemIndex].variations[this.cart.items[this.selectedCartItemIndex].variation_index].notes = ''
				this.notes = null
			},
			resetNote () {
				this.notes = null
				this.selectedCartItemIndex = -1
			},
			checkStock () {
				if (this.cart.items.length) {
					this.loading.stock = true
					this.$store.dispatch('getItems', {
						item_ids: this.cart.items.map(i => i.variations[i.variation_index].id),
						store_id: this.cart.store.id
					}).then((response) => {
						response.data.items.forEach((item) => {
							const variation = item.variations[0]
							const cartItemIndex = this.cart.items.findIndex(i => i.variations[i.variation_index].id === variation.id)

							if (cartItemIndex !== -1) {
								const cartItemVariation = this.cart.items[cartItemIndex].variations[
									this.cart.items[cartItemIndex].variation_index
								]

								Object.assign(cartItemVariation, variation)

								if (variation.track_inventory && cartItemVariation.quantity > variation.stock) {
									cartItemVariation.quantity = variation.stock
									cartItemVariation.stock_adjusted = true
								} else {
									delete cartItemVariation.stock_adjusted
								}
							}
						})

						this.cart = Object.assign({}, this.cart)
						this.$store.dispatch('cartCalculation')
					}).finally(() => {
						this.loading.stock = false
						this.calculatePadding()
						this.$nextTick(() => this.$f7.lazy.create(this.$$('#cart')[0]))
					})
				}
			},
			setSelectedOrderType (orderType) {
				this.selectedOrderType = orderType
				this.$refs.orderTypesActionSheet.close()
			},
			getDeliverySlots () {
				this.loading.slots = true
				this.$store.dispatch('getDeliverySlots').then((response) => {
					this.deliverySlots = response.data.delivery_slots

					if (!this.deliverySlot && Object.keys(this.deliverySlots).length) {
						this.deliverySlot = Object.values(this.deliverySlots)[0][0]
					}
				}).catch(this.showErrors).finally(() => {
					this.loading.slots = false
					setTimeout(() => {
						if (this.deliverySlot) {
							this.$$('#delivery-slots input[name="delivery-slot"]:checked')
								.closest('.accordion-item-content').prev().click()
						} else {
							this.$$('#delivery-slots .accordion-item .item-link').eq(0).click()
						}
					}, 50)
				})
			},
			setDeliverySlot (slot) {
				this.deliverySlot = slot
				this.$refs.deliverySlotsActionSheet.close()
			},
			getAddresses () {
				if (this.user && this.cart.store) {
					this.loading.addresses = true
					this.$store.dispatch('getAddresses', {
						lat: this.cart.store.address.coordinates[1],
						lng: this.cart.store.address.coordinates[0]
					}).finally(() => {
						this.loading.addresses = false
					})
				}
			},
			calculatePadding () {
				this.$nextTick(() => {
					const cartFooter = this.$$('#cart .toolbar-button-container')

					this.$$('#cart .page-content').css('padding-bottom', `${
						cartFooter[0] ? cartFooter[0].offsetHeight : 0
					}px`)
				})
			},
			selectAddress (address) {
				this.processing = true
				this.$store.dispatch('getDistance', {
					origins: `${this.cart.store.address.coordinates[1]},${this.cart.store.address.coordinates[0]}`,
					destinations: `${address.coordinates[1]},${address.coordinates[0]}`
				}).then((response) => {
					if (response.status === 'OK') {
						this.deliveryAddress = Object.assign(address, {
							distance: response.rows[0].elements[0].distance,
							duration: response.rows[0].elements[0].duration
						})
					} else {
						this.deliveryAddress = Object.assign(address, {
							distance: {
								text: `${(address.distance / 1000).toFixed(2)} km`,
								value: address.distance
							}
						})
					}
				}).catch(console.error).finally(() => { // eslint-disable-line
					this.$store.dispatch('cartCalculation')
					this.showAddresses = false
					this.processing = false
					setTimeout(() => {
						this.calculatePadding()
						setTimeout(() => {
							this.$$('#cart .page-content').scrollTop(this.$$('#cart .page-content').outerHeight(), 200)
						}, 100)
					}, 500)
				})
			},
			updateAddresses (address) {
				const addressIndex = this.addresses.findIndex(a => a.id === address.id)

				if (addressIndex !== -1) {
					this.$set(this.addresses, addressIndex, address)
				} else {
					this.addresses.push(address)
				}

				this.selectAddress(null, address)
			},
			emptyCart () {
				this.$f7.dialog.create({
					title: this.$t('areYouSure'),
					text: this.$t('emptyCart'),
					buttons: [{
							text: this.$options.filters.capitalize(this.$t('cancel')),
							close: true
						},
						{
							text: this.$options.filters.capitalize(this.$t('ok')),
							onClick: () => {
								this.$store.dispatch('resetCart')
								this.calculatePadding()
							}
						}
					]
				}).open()
			},
			addNewAddress () {
				this.showAddresses = false
				this.$f7router.navigate('/addresses/add?setDeliveryAddress=true')
			},
			validate () {
				let result = true

				if (!this.selectedOrderType) {
					this.validation.orderType = true
					result = false
				}

				if (this.cart.store.settings.delivery_mode === 'slot' && this.selectedOrderType?.slug === 'delivery' &&
					!this.deliverySlot && this.merchant.subscription.products.advance_ordering) {
					this.validation.deliverySlot = true
					result = false
				}

				return result
			},
			initPayments () {
				if (this.validate()) {
					if ((this.cart.price.total >= this.cart.store.min_order_value) || this.selectedOrderType.slug === 'pickup') {
						const promise = this.deliveryAddress
							? this.$store.dispatch('checkDeliveryAvailability')
							: Promise.resolve({ code: 200 })

						this.processing = true
						promise.then((response) => {
							this.processing = false

							if (response.code === 200) {
								return this.$f7router.navigate('/payments')
							}

							const err = new Error('Delivery unavailable')

							err.response = response

							return Promise.reject(err)
						}).catch((err) => {
							this.processing = false
							this.showErrors(err)
						})
					} else {
						this.$f7.dialog.alert(`${this.$t('deliveryLimitation',
							[this.toCurrency(this.cart.store.min_order_value)
							]
						)}`, this.$options.filters.capitalize(this.$t('alert')))
					}
				}
			}
		}
	}
</script>
