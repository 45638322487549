export default function ({ req, redirect }) {
  if (process.server) {
    const url = req.url.replace(/(\?.*)|(#.*)/g, '')

    switch (url.split('/')[1]) {
      case 'items':
        req.session.redirect_slug = url.split('/')[2]
        break
    }

    redirect('/')
  }
}
