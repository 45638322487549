<template>
	<f7-toolbar id="menu-toolbar" tabbar labels position="bottom">
		<f7-link
			:text="$options.filters.capitalize($t('home'))"
			href="/home"
			tab-link="#"
			:icon-f7="$f7router.currentRoute.name === 'home' ? 'house_fill' : 'house'"
			:tab-link-active="$f7router.currentRoute.name === 'home'"
			reload-all
		/>
		<f7-link
			:text="$options.filters.capitalize($t('explore'))"
			href="/items?reset_filters=1"
			tab-link="#"
			:icon-f7="$f7router.currentRoute.name === 'items' ? 'compass_fill' : 'compass'"
			:tab-link-active="$f7router.currentRoute.name === 'items'"
			reload-all
		/>
		<f7-link
			:text="$options.filters.capitalize($t('cart'))"
			href="/cart"
			tab-link="#"
			:icon-badge="cart.items.length"
			badge-color="red"
			:icon-f7="$f7router.currentRoute.name === 'cart' ? 'bag_fill' : 'bag'"
			:tab-link-active="$f7router.currentRoute.name === 'cart'"
		/>
		<f7-link
			:text="$options.filters.capitalize($t('account'))"
			href="/account"
			tab-link="#"
			:icon-f7="$f7router.currentRoute.name === 'account' ? 'person_fill' : 'person'"
			:tab-link-active="$f7router.currentRoute.name === 'account'"
			reload-all
		/>
	</f7-toolbar>
</template>

<script>
	export default {
		computed: {
			cart () {
				return this.$store.state.cart
			}
		}
	}
</script>

<style lang="scss">
	#menu-toolbar {
		.link i + span {
			margin-left: 0;
		}
	}
</style>
