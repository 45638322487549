<template>
	<f7-page id="addresses" :ptr="true" :ptr-distance="60" @ptr:refresh="refresh">
		<f7-navbar :title="$t('manage addresses')" inner-class="text-capitalize" :back-link="$t('back')" />
		<div v-if="loading" class="p-3">
			<f7-card v-for="i in 4" :key="i" class="skeleton-text skeleton-effect-blink border m-0 mb-3">
				<f7-card-content class="p-0">
					<f7-row class="align-items-center" no-gap>
						<div class="py-2 ml-2" style="line-height: 1">
							<h4 class="m-0 mb-2">
								<f7-icon f7="placemark" size="24" class="mr-1" /> xxxxx xxxxx xxxxx
							</h4>
							<p class="mt-0 mb-1">
								xxxxx xxxxx xxxxx xxxxx xxxxx xxxxx xxxxx
							</p>
							<p class="mt-0 mb-1">
								xxxxx xxxxx xxxxx xxxxx xxxxx xxxxx xxxxx
							</p>
							<p class="mt-0 mb-1">
								xxxxx xxxxx xxxxx
							</p>
						</div>
					</f7-row>
				</f7-card-content>
			</f7-card>
		</div>
		<div v-else-if="addresses.length > 0" class="p-3">
			<f7-card v-for="(address, index) in addresses" :key="index" class="elevation-4 m-0 mb-3">
				<f7-card-content class="p-2">
					<h4 class="text-capitalize mt-0 mb-1">
						<f7-icon f7="placemark" size="24" /> {{ address.annotation }}
						<f7-link
							class="float-right"
							icon-f7="ellipsis_vertical"
							icon-size="24"
							@click="setSelectedAddress(address, $event)"
						/>
					</h4>
					<p class="text-color-gray text-capitalize m-0">
						{{ address.door_no ? `${address.door_no}, ` : null }} {{ address.line }}
					</p>
				</f7-card-content>
			</f7-card>
		</div>
		<div
			v-else
			class="h-100 display-flex justify-content-center align-items-center"
			style="flex-direction: column"
		>
			<img src="/images/map.svg" height="200px">
			<h2>
				{{ $t('noAddressFound') }}
			</h2>
		</div>
		<f7-popover ref="popover" class="popover-menu" @popover:closed="selectedAddress = null">
			<f7-list>
				<f7-list-item :title="$t('edit')" class="text-capitalize" link="#" no-chevron @click="editAddress">
					<f7-icon slot="media" f7="square_pencil" size="24" />
				</f7-list-item>
				<f7-list-item :title="$t('delete')" class="text-capitalize" link="#" no-chevron @click="deleteAddress">
					<f7-icon slot="media" f7="trash" size="24" />
				</f7-list-item>
			</f7-list>
		</f7-popover>
		<div class="toolbar-button-container">
			<f7-button
				large
				fill
				href="/addresses/add"
			>
				{{ $t('addNewAddress') }}
			</f7-button>
		</div>
	</f7-page>
</template>

<script>
	export default {
		data () {
			return {
				loading: true,
				selectedAddress: null
			}
		},
		computed: {
			processing: {
				get () {
					return this.$store.state.processing
				},
				set (value) {
					this.$store.commit('setState', { key: 'processing', value })
				}
			},
			addresses () {
				return this.$store.state.addresses
			}
		},
		mounted () {
			this.getAddresses()
		},
		methods: {
			getAddresses () {
				this.$store.dispatch('getAddresses').then(() => {
					this.loading = false
				}).catch((err) => {
					this.showErrors(err)
					this.loading = false
				})
			},
			setSelectedAddress (address, $event) {
				this.selectedAddress = address
				this.$refs.popover.open($event.target)
			},
			deleteAddress () {
				const id = this.selectedAddress.id

				this.$refs.popover.close()
				this.$f7.dialog.create({
					title: this.$t('areYouSure'),
					text: this.$t('deleteAddress'),
					buttons: [{
							text: this.$options.filters.capitalize(this.$t('cancel')),
							close: true
						},
						{
							text: this.$options.filters.capitalize(this.$t('ok')),
							onClick: () => {
								this.processing = true
								this.$store.dispatch('deleteAddress', id).then(() => {
									this.processing = false
									this.$f7router.refreshPage()
								}).catch((err) => {
									this.showErrors(err)
									this.processing = false
								})
							}
						}
					]
				}).open()
			},
			editAddress () {
				const address = Object.assign({}, this.selectedAddress)

				this.$refs.popover.close()
				this.$f7router.navigate('/addresses/edit', {
					props: {
						address
					}
				})
			}
		}
	}
</script>

<style lang="scss">
	#addresses {
		.page-content {
			padding-bottom: 81px;
		}
	}
</style>
