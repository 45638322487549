<template>
	<f7-page hide-navbar-on-scroll>
		<f7-navbar :title="page && page.id ? page.meta.title : ''" :back-link="$t('back')" />
		<!-- eslint-disable-next-line vue/no-v-html -->
		<div class="px-3" v-html="page && page.id ? page.body : ''" />
	</f7-page>
</template>

<script>
	export default {
		props: {
			slug: {
				type: String,
				default: null
			}
		},
		data () {
			return {
				page: null
			}
		},
		computed: {
			processing: {
				get () {
					return this.$store.state.processing
				},
				set (value) {
					this.$store.commit('setState', { key: 'processing', value })
				}
			}
		},
		beforeMount () {
			this.getTerms()
		},
		methods: {
			getTerms () {
				this.processing = true
				this.$store.dispatch('getPages', {
					slug: this.slug || this.$f7route.params.slug.replace(/-/g, '_')
				}).then((response) => {
					this.page = response.data.pages
				}).catch((err) => {
					this.showErrors(err)
				}).finally(() => {
					this.processing = false
				})
			}
		}
	}
</script>
