<template>
	<div v-if="text" class="ribbon">
		{{ text }}
	</div>
</template>

<script>
	export default {
		props: {
			text: {
				type: String,
				required: true
			}
		}
	}
</script>

<style lang="scss" scoped>
	.ribbon {
		position: absolute;
		display: inline-block;
		padding: 0 0.5em;
		color: #FFFFFF;
		border-radius: 0 0.156em 0.156em 0;
		background: #ff3b30;
		box-shadow: -1px 2px 3px rgba(0,0,0,0.5);
		z-index: 1;

		&:before, &:after {
			position: absolute;
			content: '';
			display: block;
		}

		&:before {
			width: 0.469em;
			height: 100%;
			padding: 0 0 0.438em;
			top: 0;
			left: -0.469em;
			background:inherit;
			border-radius: 0.313em 0 0 0.313em;
		}

		&:after {
			width: 0.313em;
			height: 0.313em;
			background: rgba(0,0,0,0.35);
			bottom: -0.313em;
			left: -0.313em;
			border-radius: 0.313em 0 0 0.313em;
			box-shadow: inset -1px 2px 2px rgba(0,0,0,0.3);
		}
	}
</style>
