<template>
	<f7-page
		id="coupons"
		infinite
		:infinite-distance="1"
		:infinite-preloader="coupons.pagination && coupons.pagination.current_page < coupons.pagination.next_page"
		@infinite="loadMoreResults('coupons', 'getCoupons')"
	>
		<f7-navbar :title="$tc('coupon', 2)" inner-class="text-capitalize" :back-link="$t('back')" />
		<div class="px-3 pt-3">
			<ValidationObserver ref="validator">
				<ValidationProvider
					ref="couponValidator"
					v-slot="{ errors }"
					:name="$tc('coupon', 1)"
					rules="required"
				>
					<custom-input
						v-model="couponCode"
						type="text"
						name="coupon"
						:placeholder="$t('enter coupon code')"
						:error="errors[0]"
					/>
				</ValidationProvider>
			</ValidationObserver>
			<f7-button
				large
				raised
				fill
				@click="applyCoupon"
			>
				{{ $t('apply coupon') }}
			</f7-button>
		</div>
		<div v-if="loading" class="skeleton-text skeleton-effect-blink">
			<f7-block-title>
				xxxxx xxxxx xxxxx
			</f7-block-title>
			<f7-list media-list>
				<f7-list-item title="xxxxx">
					<span slot="subtitle">xxxxx xxxxx xxxxx</span>
					<div slot="text">
						xxxxx xxxxx xxxxx
					</div>
					<div slot="after">
						xxxxx
					</div>
				</f7-list-item>
				<f7-list-item title="xxxxx" link="#" />
				<f7-list-item title="xxxxx" link="#" />
			</f7-list>
		</div>
		<div v-else-if="coupons.data.length">
			<f7-block-title>
				{{ $t('moreDiscount') }}
			</f7-block-title>
			<f7-list media-list class="mb-0">
				<f7-list-item
					v-for="(coupon, index) in coupons.data"
					:key="index"
					:title="coupon.name"
					no-chevron
				>
					<span v-if="coupon.code" slot="subtitle">
						{{ $t('useCode') | capitalize }} {{ coupon.code }}
					</span>
					<span v-else-if="coupon.discount_entity === 'loyalty'" slot="subtitle">
						{{ $t('redeemPointsFromEarned', [coupon.loyalty.redeemable_points , coupon.loyalty.reward_points]) }}
					</span>
					<div v-if="coupon.terms" slot="text">
						<f7-link href="#" class="text-capitalize" @click="showTerms(coupon.terms)">
							{{ $t('t&cApply') }}
						</f7-link>
					</div>
					<div slot="after">
						<f7-link
							v-if="validateDiscount(coupon, cart)"
							link="#"
							class="text-uppercase"
							:text="$t('apply')"
							@click="applyDiscount(coupon)"
						/>
						<p v-else class="text-color-red my-0 text-capitalize">
							{{ $t('notApplicable') }}
						</p>
					</div>
				</f7-list-item>
			</f7-list>
		</div>
	</f7-page>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from 'vee-validate'
	import CustomInput from '~/components/custom-input'

	export default {
		components: {
			CustomInput,
			ValidationObserver,
			ValidationProvider
		},
		data () {
			return {
				loading: true,
				couponCode: '',
				coupons: {
					data: [],
					pagination: null
				}
			}
		},
		computed: {
			merchant () {
				return this.$store.state.merchant
			},
			processing: {
				get () {
					return this.$store.state.processing
				},
				set (value) {
					this.$store.commit('setState', { key: 'processing', value })
				}
			},
			appliedCoupon: {
				get () {
					return this.$store.state.appliedCoupon
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'appliedCoupon',
						value
					})
				}
			},
			cart () {
				return this.$store.state.cart
			},
			user () {
				return this.$store.state.user
			},
			allowLoadMore: {
				get () {
					return this.$store.state.allowLoadMore
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'allowLoadMore',
						value
					})
				}
			}
		},
		mounted () {
			this.getCoupons()
		},
		methods: {
			getCoupons (filters = {}) {
				filters = Object.assign({
					store_id: this.cart.store.id,
					sub_total: this.cart.price.subtotal
				}, filters)

				return this.$store.dispatch('getDiscounts', filters).then((response) => {
					if (response.code === 200) {
						this.coupons.data = filters.page
							? this.coupons.data.concat(response.data.discounts)
							: response.data.discounts
						this.coupons.pagination = response.data.pagination
					}

					this.loading = false
				}).catch((err) => {
					this.showErrors(err)
					this.loading = false
				})
			},
			async applyCoupon () {
				if (await this.$refs.validator.validate()) {
					this.processing = true
					this.$store.dispatch('applyCoupon', {
						code: this.couponCode,
						store_id: this.cart.store.id
					}).then((response) => {
						if (response.code === 200) {
							this.applyDiscount(response.data.discounts)
						}

						this.processing = false
					}).catch(() => {
						this.$refs.couponValidator.setErrors([this.$t('couponError.invalid')])
						this.processing = false
					})
				}
			},
			applyDiscount (coupon) {
				if (this.validateDiscount(coupon, this.cart)) {
					this.appliedCoupon = coupon
					this.$store.dispatch('applyDiscount')
					this.$store.dispatch('cartCalculation')
					this.$f7router.back()
				} else {
					this.$refs.couponValidator.setErrors([this.$t('couponError.notApplicable')])
				}
			},
			showTerms (terms) {
				this.$f7.dialog.create({
					title: this.$t('t&c'),
					text: terms,
					buttons: [{ text: this.$t('ok') }],
					cssClass: 'terms text-capitalize',
					closeByBackdropClick: true,
					destroyOnClose: true
				}).open()
			}
		}
	}
</script>

<style lang="scss">
	.terms .dialog-text {
		white-space: pre;
	}
</style>
