var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((Array(Math.ceil((_vm.loading ? 9 : _vm.categories.data.length) / 3)).fill(null)
			.map((v, i) => i * 3)),function(row,index){return _c('f7-row',{key:row,class:{
			'mb-3': index < Math.ceil((_vm.loading ? 9 : _vm.categories.data.length) / 3) - 1
		}},_vm._l((Array(3).fill(null).map((v, i) => row + i)),function(col){return _c('f7-col',{key:col},[(_vm.loading)?_c('f7-card',{staticClass:"h-100 skeleton-text skeleton-effect-blink border m-0"},[_c('f7-skeleton-block',{staticStyle:{"height":"100px"}}),_vm._v(" "),_c('f7-card-content',{staticClass:"text-align-center p-1"},[_vm._v("\n\t\t\t\t\txxxxxxxx\n\t\t\t\t")])],1):(_vm.categories.data[col])?_c('f7-card',{staticClass:"h-100 elevation-4 m-0",nativeOn:{"click":function($event){return _vm.selectCategory(_vm.categories.data[col])}}},[_c('f7-card-header',{staticClass:"lazy lazy-fade-in no-border p-0",style:({
						height: '100px',
						backgroundPosition: 'center',
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'cover'
					}),attrs:{"data-background":_vm.categories.data[col].image_path || '/images/placeholder.svg'}}),_vm._v(" "),_c('f7-card-content',{staticClass:"text-align-center word-wrap p-1"},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.locale === 'en' ? _vm.categories.data[col].name : (_vm.categories.data[col].alternate_language || _vm.categories.data[col].name))+"\n\t\t\t\t")])],1):_vm._e()],1)}),1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }