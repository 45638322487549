<template>
	<f7-page id="add-address">
		<f7-navbar :back-link="$t('back')">
			<div slot="title" class="text-capitalize">
				{{ this.$f7route.params.type }} {{ $tc('address', 1) }}
			</div>
			<f7-nav-right>
				<f7-link href="/locations" icon-f7="search" />
			</f7-nav-right>
		</f7-navbar>
		<google-map
			:map-config="mapConfig"
			:markers="markers"
			:event="!address && location.coordinates.length && Object.keys(location).length < 13 ? 'dragend' : null"
		/>
		<f7-block class="m-0">
			<small>{{ $t('dragToAdjustLocation') }}</small>
		</f7-block>
		<ValidationObserver ref="validator">
			<ValidationProvider>
				<input :value="location.door_no" type="hidden">
			</ValidationProvider>
			<ValidationProvider rules="required">
				<input :value="location.line" type="hidden">
			</ValidationProvider>
			<ValidationProvider rules="required">
				<input :value="location.city" type="hidden">
			</ValidationProvider>
			<ValidationProvider v-if="merchant.settings.general.country_code != 'AE'" vid="postalcode" :name="$t('postal code')" rules="required|postalCode">
				<input :value="location.zip" type="hidden">
			</ValidationProvider>
			<ValidationProvider rules="required">
				<input :value="location.landmark" type="hidden">
			</ValidationProvider>
			<ValidationProvider rules="required">
				<input :value="location.annotation" type="hidden">
			</ValidationProvider>
		</ValidationObserver>
		<f7-block class="my-1">
			<h4 class="display-flex align-items-center text-capitalize mt-0 mb-1">
				<f7-icon f7="placemark" size="24" class="mr-1" /> {{ location.area || $t('unnamed location') }}
			</h4>
			<p class="text-color-gray text-capitalize m-0">
				{{ location.line }}
			</p>
		</f7-block>
		<f7-list class="m-0" no-hairlines-md>
			<f7-list-input
				:label="$t('doorNoFloor')"
				type="text"
				:placeholder="$t('doorNoFloor')"
				:value="location.door_no"
				clear-button
				@input="location.door_no = $event.target.value"
				@input:clear="location.door_no = ''"
			/>
			<f7-list-input
				:label="`${$options.filters.capitalize($t('city'))} *`"
				type="text"
				:placeholder="$options.filters.capitalize($t('city'))"
				:value="location.city"
				clear-button
				@input="location.city = $event.target.value"
				@input:clear="location.city = ''"
			/>
			<f7-list-input
				v-if="merchant.settings.general.country_code != 'AE'"
				:label="`${$t('zipCode')} *`"
				type="text"
				:placeholder="$options.filters.capitalize($t('postal code'))"
				:value="location.zip"
				clear-button
				:error-message="$refs.validator && $refs.validator.errors.postalcode && $refs.validator.errors.postalcode.length ? $refs.validator.errors.postalcode[0].replace('{field}', 'postalcode') : ''"
				:error-message-force="$refs.validator && $refs.validator.errors.postalcode ? $refs.validator.errors.postalcode.length > 0 : false"
				@input="location.zip = $event.target.value"
				@input:clear="location.zip = ''"
			/>
			<f7-list-input
				:label="`${$options.filters.capitalize($t('landmark'))} *`"
				type="text"
				:placeholder="$options.filters.capitalize($t('landmark'))"
				:value="location.landmark"
				clear-button
				@input="location.landmark = $event.target.value"
				@input:clear="location.landmark = ''"
			/>
			<div class="px-3 mt-2">
				<small class="item-label text-capitalize">{{ $t('address category') }} *</small>
			</div>
			<f7-row class="px-3 pt-2 pb-3" style="background-color: var(--f7-list-bg-color)">
				<f7-col>
					<f7-button
						small
						round
						outline
						:fill="location.annotation === 'home'"
						@click="location.annotation = 'home'"
					>
						{{ $t('home') }}
					</f7-button>
				</f7-col>
				<f7-col>
					<f7-button
						small
						round
						outline
						:fill="location.annotation === 'work'"
						@click="location.annotation = 'work'"
					>
						{{ $t('work') }}
					</f7-button>
				</f7-col>
				<f7-col>
					<f7-button
						small
						round
						outline
						class="w-100"
						:fill="location.annotation === 'other'"
						@click="location.annotation = 'other'"
					>
						{{ $t('other') }}
					</f7-button>
				</f7-col>
			</f7-row>
		</f7-list>
		<div class="toolbar-button-container">
			<f7-button
				large
				fill
				:disabled="processing"
				@click="saveAddress"
			>
				{{ $t('save') }}
				<span v-if="$f7router.currentRoute.query.setDeliveryAddress === 'true'">
					&amp; {{ $t('proceed') }}
				</span>
			</f7-button>
		</div>
	</f7-page>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from 'vee-validate'
	import GoogleMap from '~/components/google-map'

	export default {
		components: {
			GoogleMap,
			ValidationObserver,
			ValidationProvider
		},
		props: {
			address: {
				type: Object,
				default: () => null
			}
		},
		data () {
			return {
				mapConfig: {
					zoom: 18,
					zoomControl: true,
					centerMarker: true,
					disableDefaultUI: true
				},
				markers: [],
				location: {
					door_no: '',
					line: '',
					area: '',
					city: '',
					state: '',
					country: '',
					zip: '',
					landmark: '',
					annotation: 'home',
					coordinates: []
				}
			}
		},
		computed: {
			processing: {
				get () {
					return this.$store.state.processing
				},
				set (value) {
					this.$store.commit('setState', { key: 'processing', value })
				}
			},
			currentLocation () {
				return this.$store.state.location
			},
			merchant () {
				return this.$store.state.merchant
			}
		},
		beforeMount () {
			if (this.address) {
				this.updateLocation(this.address)
			} else {
				this.getCurrentLocation()
			}
		},
		mounted () {
			window.emitCurrentLocation = this.emitCurrentLocation
			this.$root.$on('location', this.updateLocation)
		},
		destroyed () {
			this.$root.$off('location', this.updateLocation)
		},
		methods: {
			getCurrentLocation () {
				this.processing = true
				this.$bridge.getCurrentLocation()
			},
			updateLocation (location) {
				this.location = Object.assign({}, this.location, location)
				this.markers = [
					{
						position: {
							lat: parseFloat(this.location.coordinates[1]),
							lng: parseFloat(this.location.coordinates[0])
						}
					}
				]
			},
			emitCurrentLocation (coordinates) {
				if (coordinates) {
					if (typeof coordinates === 'string') {
						coordinates = JSON.parse(coordinates)
					}

					this.$store.dispatch('getPlaceByGeocode', {
						latlng: `${coordinates.lat},${coordinates.lng}`
					}).then((response) => {
						if (response.status === 'OK') {
							this.$store.commit('setLocation', response.location)
							this.updateLocation(response.location)
						} else {
							throw new Error(response.status)
						}
					}).finally(() => {
						this.processing = false
					}).catch(this.showErrors)
				} else {
					this.processing = false
					this.updateLocation(this.currentLocation)
				}
			},
			async saveAddress () {
				if (await this.$refs.validator.validate()) {
					const address = {
						id: this.address ? this.address.id : null,
						...this.location,
						is_default: 0
					}

					this.processing = true
					this.$store.dispatch('saveAddress', address).then((response) => {
						if ([200, 201].includes(response.code)) {
							if (this.$f7router.currentRoute.query.setDeliveryAddress === 'true') {
								this.$root.$emit('deliveryAddress', response.data.customer_addresses)
							}

							this.$f7router.back({
								ignoreCache: true,
								force: true
							})
						}

						this.processing = false
					}).catch((err) => {
						this.showErrors(err)
						this.processing = false
					})
				} else {
					this.$f7.toast.create({
						text: this.$t('toastMsg.allRequired'),
						closeTimeout: 3000
					}).open()
				}
			}
		}
	}
</script>

<style lang="scss">
	#add-address {
		.page-content {
			padding-bottom: 81px;

			.item-input-wrap input {
				padding-right: 25px;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
</style>
