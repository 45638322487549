<template>
	<div>
		<f7-row
			v-for="(row, index) in Array(Math.ceil((loading ? 9 : categories.data.length) / 3)).fill(null)
				.map((v, i) => i * 3)"
			:key="row"
			:class="{
				'mb-3': index < Math.ceil((loading ? 9 : categories.data.length) / 3) - 1
			}"
		>
			<f7-col
				v-for="col in Array(3).fill(null).map((v, i) => row + i)"
				:key="col"
			>
				<f7-card v-if="loading" class="h-100 skeleton-text skeleton-effect-blink border m-0">
					<f7-skeleton-block style="height: 100px" />
					<f7-card-content class="text-align-center p-1">
						xxxxxxxx
					</f7-card-content>
				</f7-card>
				<f7-card
					v-else-if="categories.data[col]"
					class="h-100 elevation-4 m-0"
					@click.native="selectCategory(categories.data[col])"
				>
					<f7-card-header
						:data-background="categories.data[col].image_path || '/images/placeholder.svg'"
						class="lazy lazy-fade-in no-border p-0"
						:style="{
							height: '100px',
							backgroundPosition: 'center',
							backgroundRepeat: 'no-repeat',
							backgroundSize: 'cover'
						}"
					/>
					<f7-card-content class="text-align-center word-wrap p-1">
						{{ locale === 'en' ? categories.data[col].name : (categories.data[col].alternate_language || categories.data[col].name) }}
					</f7-card-content>
				</f7-card>
			</f7-col>
		</f7-row>
	</div>
</template>

<script>
	export default {
		props: {
			loading: {
				type: Boolean,
				default: false
			},
			categories: {
				type: Object,
				required: true
			}
		},
		computed: {
			locale () {
				return this.$store.state.locale
			},
			filters: {
				get () {
					return this.$store.state.filters
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'filters',
						value
					})
				}
			},
			allowLoadMore: {
				get () {
					return this.$store.state.allowLoadMore
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'allowLoadMore',
						value
					})
				}
			}
		},
		methods: {
			selectCategory (category) {
				this.filters.category_ids = []
				this.filters.category_ids.push(category.id.toString())
				this.$f7router.navigate('/items')
			}
		}
	}
</script>
