<template>
	<f7-actions
		ref="modifiers"
		:opened="show"
		@actions:open="init"
		@actions:opened="selectRequiredModifiers"
		@actions:closed="reset"
	>
		<f7-actions-group>
			<f7-actions-label class="justify-content-space-between px-3">
				<div v-if="variation">
					<p class="m-0">
						{{ locale === 'en' ? variation.name : ( variation.alt_lang || variation.name) }}
					</p>
					<small
						class="text-capitalize"
					>
						{{ $t('total') }}: {{ toCurrency(variation.original_price + selectedModifiersPrice) }}
					</small>
				</div>
				<f7-link
					icon-f7="xmark"
					icon-size="20px"
					@click="$refs.modifiers.close()"
				/>
			</f7-actions-label>
			<div v-if="variation" class="page-bg-color">
				<div
					v-for="(modifier, modifierIndex) in variation.modifiers"
					:key="modifierIndex"
				>
					<f7-block-title class="m-0 p-3">
						{{ modifier.name }}
					</f7-block-title>
					<f7-list class="m-0" media-list>
						<f7-list-item
							v-for="(item, itemIndex) in modifier.items"
							:key="itemIndex"
							:disabled="validateModifier(variation, modifier, item)"
							:name="`${modifier.id}-${modifier.name.toLowerCase()}`"
							:title="locale === 'en' ? item.name : ( item.alt_lang || item.name)"
							:footer="toCurrency(item.price)"
							:checkbox="modifier.multi_choice"
							:radio="!modifier.multi_choice"
							:value="JSON.stringify({
								group_id: modifier.id,
								item_id: item.id,
								item_name: item.name,
								price: item.price,
								qty: 1
							})"
							@change="checkModifiers"
						/>
					</f7-list>
				</div>
				<div class="p-3">
					<f7-button
						large
						raised
						fill
						@click="applyModifiers"
					>
						{{ $t('addItem') }}
					</f7-button>
				</div>
			</div>
		</f7-actions-group>
	</f7-actions>
</template>

<script>
	export default {
		name: 'Modifiers',
		props: {
			show: {
				type: Boolean,
				default: false
			}
		},
		data () {
			return {
				selectedModifiers: [],
				variations: [],
				requiredModifiers: []
			}
		},
		computed: {
			locale () {
				return this.$store.state.locale
			},
			merchant () {
				return this.$store.state.merchant
			},
			cart () {
				return this.$store.state.cart
			},
			selectedItem: {
				get () {
					return this.$store.state.selectedItem
				},
				set (value) {
					this.$store.commit('setState', { key: 'selectedItem', value })
				}
			},
			variation () {
				return this.selectedItem ? this.selectedItem.variations[this.selectedItem.variation_index] : null
			},
			selectedModifiersPrice () {
				return this.selectedModifiers.reduce((sum, m) => {
					sum += m.price

					return sum
				}, 0)
			}
		},
		methods: {
			init () {
				this.variations = this.selectedItem.variations.map((v) => {
					v.modifiers.reduce((requiredModifiers, modifier) => {
						if (modifier.required) {
							requiredModifiers.push(`${modifier.id}-${modifier.name.toLowerCase()}`)
						}

						return requiredModifiers
					}, this.requiredModifiers)

					return {
						...v,
						quantity: v.quantity || 0
					}
				})
			},
			selectRequiredModifiers () {
				this.requiredModifiers.forEach((modifierName) => {
					const input = document.querySelector(`input[name="${modifierName}"]`)

					if (input && !input.checked) {
						input.click()
					}
				})
			},
			checkModifiers ($event) {
				const modifier = JSON.parse($event.target.value)

				if ($event.target.type === 'radio') {
					const index = this.selectedModifiers.findIndex(m => m.group_id === modifier.group_id)

					if (index !== -1) {
						this.selectedModifiers.splice(index, 1)
					}
				}

				if ($event.target.checked) {
					this.selectedModifiers.push(modifier)
				} else {
					this.selectedModifiers.splice(this.selectedModifiers.findIndex((m) => {
						return m.group_id === modifier.group_id && m.item_id === modifier.item_id
					}), 1)
				}
			},
			validateModifier (variation, modifier, mVariation) {
				const maxModifiersSelectable = (
					variation.max_selectable
						? variation.max_selectable
						: null
				) || null
				const maxOptionsSelectable = modifier.multi_choice ? modifier.max_selectable : 0
				const uniqModifiers = this.selectedModifiers
					? variation.modifiers.reduce((modifiers) => {
						for (const smk in this.selectedModifiers) {
							const sm = this.selectedModifiers[smk]

							if (sm) {
								if (modifiers[sm.group_id]) {
									const index = modifiers[sm.group_id].findIndex(element => element === sm.item_id)

									if (index === -1) {
										modifiers[sm.group_id].push(sm.item_id)
									}
								} else {
									modifiers[sm.group_id] = [sm.item_id]
								}
							}
						}

						return modifiers
					}, {})
					: {}

				return mVariation.track_inventory && mVariation.stock < 1
					? true
					: (
						(!uniqModifiers[modifier.id] && maxModifiersSelectable > 0 && Object.keys(uniqModifiers).length >= maxModifiersSelectable) ||
						(uniqModifiers[modifier.id] && maxOptionsSelectable > 0 && uniqModifiers[modifier.id].length >= maxOptionsSelectable && !uniqModifiers[modifier.id].includes(mVariation.id))
					)
			},
			applyModifiers () {
				this.variation.selected_modifiers = this.selectedModifiers
				this.$root.$emit('applyModifiers', this.selectedItem)
				this.$refs.modifiers.close()
			},
			reset () {
				this.selectedItem = null
				this.selectedModifiers = []
				this.$emit('close')
			}
		}
	}
</script>
