<template>
	<f7-actions
		ref="attributes"
		:opened="show"
		@actions:open="init"
		@actions:closed="reset"
	>
		<f7-actions-group>
			<f7-actions-label class="justify-content-space-between px-3">
				<div v-if="selectedItem">
					<p class="m-0">
						{{ variationIndex !== -1 ? selectedItem.variations[variationIndex].name : selectedItem.name }}
					</p>
					<small v-if="variationIndex !== -1">
						{{ toCurrency(selectedItem.variations[variationIndex].price) }}
					</small>
				</div>
				<f7-link
					icon-f7="xmark"
					icon-size="20px"
					@click="$refs.attributes.close()"
				/>
			</f7-actions-label>
			<div v-if="selectedItem" class="page-bg-color">
				<div
					v-for="(attribute, i) in selectedItem.item_attributes"
					:key="i"
				>
					<f7-block-title class="m-0 p-3">
						{{ attribute.name }}
					</f7-block-title>
					<f7-list class="m-0" media-list>
						<f7-list-item
							v-for="(option, j) in attribute.options"
							:key="j"
							:name="`attribute-${attribute.slug}`"
							:title="option.label"
							:value="JSON.stringify({
								label: option.label,
								slug: attribute.slug,
								value: option.value
							})"
							:checked="selectedItem.selected_attributes.findIndex(a => a.slug === attribute.slug && a.value === option.value) !== -1"
							radio
							@change="checkAttributes"
						/>
					</f7-list>
				</div>
				<div class="p-3">
					<f7-button
						large
						raised
						fill
						:disabled="variationIndex === -1"
						@click="applyAttributes"
					>
						{{ $t('select item') }}
					</f7-button>
				</div>
			</div>
		</f7-actions-group>
	</f7-actions>
</template>

<script>
	export default {
		props: {
			show: {
				type: Boolean,
				required: true
			}
		},
		data () {
			return {
				selectedAttributes: []
			}
		},
		computed: {
			locale () {
				return this.$store.state.locale
			},
			merchant () {
				return this.$store.state.merchant
			},
			selectedItem: {
				get () {
					return this.$store.state.selectedItem
				},
				set (value) {
					this.$store.commit('setState', { key: 'selectedItem', value })
				}
			},
			variationIndex () {
				return this.selectedItem.variations.findIndex((v) => {
					return v.item_attributes.every(a => this.selectedAttributes.findIndex((sa) => {
						return sa.slug === a.slug && sa.value === a.value
					}) !== -1)
				})
			}
		},
		methods: {
			init () {
				this.selectedAttributes = [...this.selectedItem.selected_attributes]
			},
			checkAttributes ($event) {
				const attribute = JSON.parse($event.target.value)
				const index = this.selectedAttributes.findIndex(a => a.slug === attribute.slug)

				if (index !== -1) {
					this.selectedAttributes.splice(index, 1)
				}

				this.selectedAttributes.push(attribute)
			},
			applyAttributes () {
				this.selectedItem.selected_attributes = [...this.selectedAttributes]
				this.selectedItem.variation_index = this.variationIndex
				this.$refs.attributes.close()
			},
			reset () {
				this.selectedItem = null
				this.$emit('close')
			}
		}
	}
</script>
