const state = () => ({
	locale: 'en',
	firebaseToken: null,
	themeColors: {
		default: { r: 0, g: 122, b: 255, hex: '#007aff' },
		red: { r: 255, g: 59, b: 48, hex: '#ff3b30' },
		green: { r: 76, g: 217, b: 100, hex: '#4cd964' },
		blue: { r: 33, g: 150, b: 243, hex: '#2196f3' },
		pink: { r: 255, g: 45, b: 85, hex: '#ff2d55' },
		yellow: { r: 255, g: 204, b: 0, hex: '#ffcc00' },
		orange: { r: 255, g: 149, b: 0, hex: '#ff9500' },
		purple: { r: 156, g: 39, b: 176, hex: '#9c27b0' },
		deeppurple: { r: 103, g: 58, b: 183, hex: '#673ab7' },
		lightblue: { r: 90, g: 200, b: 250, hex: '#5ac8fa' },
		teal: { r: 0, g: 150, b: 136, hex: '#009688' },
		lime: { r: 205, g: 220, b: 57, hex: '#cddc39' },
		deeporange: { r: 255, g: 107, b: 34, hex: '#ff6b22' },
		gray: { r: 142, g: 142, b: 147, hex: '#8e8e93' },
		black: { r: 0, g: 0, b: 0, hex: '#000000' }
	},
	merchant: null,
	user: null,
	referralCode: null,
	processing: false,
	otpVerification: {
		type: null,
		medium: null
	},
	allowLoadMore: true,
	location: null,
	nearbyStores: [],
	selectedStore: null,
	filters: {
		category_ids: [],
		order_by: null,
		dir: null,
		food_type: null
	},
	selectedItem: null,
	appliedCoupon: null,
	cart: {
		store: null,
		items: [],
		price: {
			subtotal: 0,
			tax: 0,
			inclusiveTaxTotal: 0,
			exclusiveTaxTotal: 0,
			taxes: [],
			discount: 0,
			discounts: [],
			total: 0,
			totalQuantity: 0
		}
	},
	addresses: [],
	selectedOrderType: null,
	deliverySlot: null,
	deliveryAddress: null,
	paymentMethod: null,
	navigationProps: {},
	redirectSlug: null
})

export default state
