<template>
	<f7-button
		v-if="!variation.available"
		small
		round
		outline
		color="red"
		style="width: 90px"
	>
		{{ $t('sold out') }}
	</f7-button>
	<f7-stepper
		v-else-if="isSelectedStoreActive && cartItemQuantity"
		:ref="`item-${item.id}-stepper`"
		:init="false"
		:input-readonly="true"
		:value="cartItemQuantity"
		fill
		small
		round
		raised
		@stepper:plusclick="stepperPlusClick(variation.unit_measure_type === 'weight' && merchant.settings.general.quantity_increment_value ? cartItemQuantity + +merchant.settings.general.quantity_increment_value : cartItemQuantity + 1)"
		@stepper:minusclick="stepperMinusClick(variation.unit_measure_type === 'weight' && merchant.settings.general.quantity_increment_value ? cartItemQuantity - +merchant.settings.general.quantity_increment_value : cartItemQuantity - 1)"
		@click.native.stop
	/>
	<f7-button
		v-else-if="isSelectedStoreActive"
		raised
		small
		fill
		round
		style="width: 90px"
		@click.stop="stepperPlusClick(variation.unit_measure_type === 'weight' && merchant.settings.general.quantity_increment_value ? +merchant.settings.general.quantity_increment_value : 1)"
	>
		{{ $t('add') }}
	</f7-button>
</template>

<script>
	export default {
		props: {
			item: {
				type: Object,
				required: true
			}
		},
		computed: {
			merchant () {
				return this.$store.state.merchant
			},
			selectedStore () {
				return this.$store.state.selectedStore
			},
			isSelectedStoreActive () {
				if (this.$f7router.currentRoute.name === 'cart') {
					return this.cart.store ? this.cart.store.available : false
				} else {
					return this.selectedStore ? this.selectedStore.available : false
				}
			},
			cart () {
				return this.$store.state.cart
			},
			variation () {
				return this.item.variations[this.item.variation_index]
			},
			cartItemQuantity () {
				return this.$f7router.currentRoute.name === 'cart'
					? this.variation.quantity
					: this.cart.items.reduce((quantity, item) => {
						const variation = item.variations[item.variation_index]

						if (variation.id === this.variation.id && this.selectedStore.id === this.cart.store.id) {
							quantity += variation.quantity
							// this.variation.selected_modifiers = variation.selected_modifiers
						}

						return quantity
					}, 0)
			},
			selectedItem: {
				get () {
					return this.$store.state.selectedItem
				},
				set (value) {
					this.$store.commit('setState', { key: 'selectedItem', value })
				}
			}
		},
		methods: {
			stepperPlusClick (quantity) {
				const itemTotalQuantity = this.cart.items
					.reduce((sum, item) => {
						const cartItemVariation = item.variations[item.variation_index]

						if (cartItemVariation.id === this.variation.id) {
							sum += cartItemVariation.quantity
						}

						return sum
					}, 0) + quantity

				if (itemTotalQuantity <= (this.variation.track_inventory ? Math.floor(this.variation.stock) : 100)) {
					if (this.$f7router.currentRoute.name !== 'cart' && this.variation.modifiers.length) {
						this.selectedItem = this.item
						this.$root.$emit('showModifiers')
					} else {
						this.$root.$emit('addItem', {
							item: this.item,
							quantity
						})
					}
				}
			},
			stepperMinusClick (quantity) {
				if (this.$f7router.currentRoute.name !== 'cart' && this.cart.items.filter((i) => {
					return i.variations[i.variation_index].id === this.item.variations[this.item.variation_index].id
				}).length > 1) {
					this.$f7.toast.create({
						text: this.$t('toastMsg.multipleCustomizations'),
						closeTimeout: 5000
					}).open()
				} else {
					this.$root.$emit('addItem', {
						item: this.item,
						quantity
					})
				}
			}
		}
	}
</script>
