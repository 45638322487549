<template>
	<f7-page id="intro" no-navbar>
		<f7-swiper v-if="intoScreenBanners.length" class="pb-4" pagination>
			<f7-swiper-slide
				v-for="(banner, index) in intoScreenBanners"
				:key="index"
				class="pt-0"
			>
				<div class="position-relative bg-color-primary elevation-4" style="height: 300px">
					<img
						:src="banner.file_path"
						:alt="banner.name"
						class="position-absolute m-auto"
						style="top: 0; bottom: 0; left: 0; right: 0; max-height: 100%; max-width: 100%"
					>
				</div>
				<h1 class="text-align-center my-3 px-5" style="line-height: 1">
					{{ banner.title }}
				</h1>
			</f7-swiper-slide>
		</f7-swiper>
		<div v-else class="position-relative bg-color-primary elevation-4 mb-3" style="height: 300px">
			<img
				:src="merchant.settings.general.logo_img_url || '/images/logo.png'"
				:alt="merchant.business.name"
				class="position-absolute m-auto"
				style="top: 0; bottom: 0; left: 0; right: 0; max-height: 100%; max-width: 100%"
			>
		</div>
		<small class="display-flex justify-content-center text-color-gray mb-3">
			{{ $t('readyToOrderOnline') }}
		</small>
		<div class="display-flex justify-content-center">
			<f7-button
				href="/locations"
				class="button-alt w-75"
				large
				raised
				fill
			>
				{{ $t('setDeliveryLocation') }}
			</f7-button>
		</div>
		<p class="text-align-center">
			{{ $t('haveAnAccount') }}
			<f7-link href="/login" class="underline text-capitalize">
				{{ $t('login') }}
			</f7-link>
		</p>
	</f7-page>
</template>

<script>
	export default {
		data () {
			return {
				intoScreenBanners: []
			}
		},
		computed: {
			merchant () {
				return this.$store.state.merchant
			},
			processing: {
				get () {
					return this.$store.state.processing
				},
				set (value) {
					this.$store.commit('setState', { key: 'processing', value })
				}
			}
		},
		mounted () {
			this.loadIntroScreenBanners()
			/* this.processing = process.env.NODE_ENV !== 'development'
				? !(this.getLocalStorage('afterPrecache') === 'true')
				: false */

			if ('serviceWorker' in navigator) {
				navigator.serviceWorker.onmessage = (e) => {
					if (e.data === 'afterPrecache') {
						this.processing = false
						this.setLocalStorage('afterPrecache', 'true')
					}
				}
			} else {
				this.processing = false
				this.setLocalStorage('afterPrecache', 'true')
			}
		},
		methods: {
			loadIntroScreenBanners () {
				this.$axios.$get('/api/guest/home/banners', {
					params: {
						type: 'intro'
					}
				}).then((response) => {
					this.intoScreenBanners = response.data.banners
				})
			}
		}
	}
</script>
