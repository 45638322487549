var render = function render(){var _vm=this,_c=_vm._self._c;return _c('f7-page',{ref:"page",attrs:{"id":"store-details","infinite":"","infinite-distance":1,"infinite-preloader":_vm.items.pagination && _vm.items.pagination.current_page < _vm.items.pagination.next_page},on:{"infinite":function($event){return _vm.loadMoreResults('items', 'getStoreItems')}}},[(_vm.selectedStore.images && _vm.selectedStore.images.length)?_c('f7-photo-browser',{ref:"photos",attrs:{"type":"popup","theme":"dark","photos":_vm.selectedStore.images}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"position-fixed",staticStyle:{"top":"0","left":"0","right":"0","background-color":"#F5F5F5","z-index":"2"}},[_c('div',{staticClass:"position-relative lazy lazy-fade-in",style:({
				height: '200px',
				background: '#ccc',
				backgroundPosition: 'center',
				backgroundRepeat: 'no-repeat',
				backgroundSize: _vm.selectedStore.images && _vm.selectedStore.images[0] ? '100% 100%' : '60% auto'
			}),attrs:{"data-background":_vm.selectedStore.images && _vm.selectedStore.images.length ?
				_vm.selectedStore.images[0] : '/images/placeholder.svg'},on:{"click":function($event){_vm.selectedStore.images && _vm.selectedStore.images.length ? _vm.$refs.photos.open(0) : null}}},[_c('f7-link',{staticClass:"bg-color-white elevation-4",staticStyle:{"position":"absolute","right":"15px","top":"15px","height":"22px","width":"22px","border-radius":"50%"},attrs:{"href":"#","icon-f7":"xmark_circle_fill","back":"","icon-only":""},on:{"click":function($event){$event.stopPropagation();return _vm.$store.commit('removeNavigationProp', 'store')}}}),_vm._v(" "),_c('f7-row',{staticClass:"w-100 position-absolute p-2",staticStyle:{"background":"rgb(0, 0, 0, .5)","bottom":"0px"}},[_c('f7-col',{staticClass:"text-align-center",attrs:{"width":"25"}},[_c('f7-icon',{staticClass:"mb-1",attrs:{"f7":"placemark","color":"white"}}),_vm._v(" "),_c('small',{staticClass:"display-block text-color-white font-weight-bold"},[_vm._v("\n\t\t\t\t\t\t"+_vm._s((_vm.selectedStore.distance).toFixed(2))+" "+_vm._s(_vm.$t('km'))+"\n\t\t\t\t\t")])],1),_vm._v(" "),_c('f7-col',{attrs:{"width":"75"}},[_c('h4',{staticClass:"display-flex align-items-center text-capitalize text-color-white m-0"},[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.selectedStore.name))]),_vm._v(" "),_c('f7-link',{staticClass:"store-info",attrs:{"icon-ios":"f7:info","icon-md":"material:info_fill","icon-size":"20px","icon-color":"white"}})],1),_vm._v(" "),_c('p',{staticClass:"text-capitalize text-color-white m-0"},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.selectedStore.address.line)+"\n\t\t\t\t\t")])])],1)],1)]),_vm._v(" "),_c('items',{attrs:{"loading":_vm.loading,"items":_vm.items.data}}),_vm._v(" "),_c('cart-toolbar')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }