<template>
	<f7-page
		id="home"
		ptr
		:ptr-distance="60"
		@ptr:refresh="refresh"
	>
		<div
			v-if="merchant.settings.general.show_alert_banner && merchant.settings.general.alert_banner_template && showAlert"
			ref="alertBanner"
			class="m-3 p-3 alert-banner"
			@click="handleClick"
		>
			<!-- eslint-disable-next-line vue/no-v-html -->
			<div v-html="merchant.settings.general.alert_banner_template" />
			<span class="material-icons alert-close" @click.stop="showAlert = false">close</span>
		</div>
		<f7-panel left cover>
			<f7-page
				infinite
				:infinite-distance="1"
				:infinite-preloader="categories.pagination && categories.pagination.current_page < categories.pagination.next_page"
				@infinite="loadMoreResults('categories', 'getCategories')"
			>
				<f7-block-title
					class="text-capitalize"
				>
					<div class="display-flex justify-content-start align-items-center">
						<f7-link
							v-if="validSelectedCategories.length"
							icon-f7="arrow_left"
							icon-size="20px"
							class="mr-2 text-white"
							@click="onPressCategoryBack()"
						/>
						<span class="text-right">{{ category?.id ? category.name: $t('shop by category') }}</span>
					</div>
				</f7-block-title>
				<f7-block v-if="loading.categories" class="text-align-center mt-5">
					<f7-preloader />
				</f7-block>
				<f7-list v-else>
					<f7-list-item
						v-for="(c, index) in categories.data"
						:key="index"
						:title="locale === 'en' ? c.name : (c.alternate_language || c.name)"
						class="text-capitalize cursor-pointer"
						@click="selectCategory(c)"
					>
						<f7-icon v-if="c.child_categories_count" f7="chevron_right" size="22" />
					</f7-list-item>
				</f7-list>
			</f7-page>
		</f7-panel>
		<f7-navbar>
			<f7-nav-left>
				<f7-link panel-open="left" icon-f7="bars" />
			</f7-nav-left>
			<f7-nav-title class="text-capitalize" @click.native="$f7router.navigate('/locations')">
				<f7-icon f7="map_pin_ellipse" size="22" />
				{{ location ? (location.area || location.city || (
					location.line ? location.line.split(', ')[0] : $t('unnamed location')
				)) : $t('set location') }}
			</f7-nav-title>
			<f7-nav-right>
				<f7-link
					v-if="!loading.nearbyStores && nearbyStores.length"
					class="searchbar-enable"
					data-searchbar=".searchbar"
					icon-ios="f7:search"
					icon-md="material:search"
				/>
			</f7-nav-right>
			<f7-searchbar
				class="searchbar"
				:placeholder="['restaurant','qsr'].includes(merchant.business.type)?
					$t('searchPlaceholder.restraunt'):$t('searchPlaceholder.grocery')"
				custom-search
				expandable
				@input="catalogSearch"
				@searchbar:enable="catalogSearchResults = []"
				@searchbar:disable="catalogSearchResults = null"
			/>
		</f7-navbar>
		<div
			v-if="!loading.nearbyStores && nearbyStores.length === 0"
			class="h-100 display-flex justify-content-center align-items-center"
			style="flex-direction: column"
		>
			<img src="/images/no-result.svg" height="200px">
			<h2 class="text-align-center">
				{{ $t('noStoresToServe') }}
			</h2>
			<f7-button large fill raised @click="$f7router.navigate('/locations')">
				<f7-icon f7="map_pin_ellipse" size="24" />
				{{ $t('change location') }}
			</f7-button>
		</div>
		<div v-else>
			<div v-if="catalogSearchResults">
				<f7-block-title
					class="text-capitalize"
				>
					{{ $t('search results') }}
				</f7-block-title>
				<f7-list media-list>
					<f7-list-item
						v-for="(result, index) in catalogSearchResults"
						:key="index"
						:title="result.name"
						:subtitle="`in ${result.type}`"
						link="#"
						no-chevron
						@click="processSearchResult(result)"
					/>
				</f7-list>
			</div>
			<div v-else>
				<f7-list
					v-if="loading.nearbyStores || selectedStore"
					:class="{
						'skeleton-text': loading.nearbyStores,
						'skeleton-effect-blink': loading.nearbyStores,
						'my-0': true
					}"
				>
					<f7-list-item
						link="#"
						:header="$options.filters.capitalize($t('selected store'))"
						:after="$options.filters.capitalize($t('change'))"
						@click="showNearbyStores = true"
					>
						<span slot="title" class="text-capitalize">
							{{ selectedStore ? selectedStore.name : 'xxxxx xxxxx' }}
						</span>
						<f7-icon slot="media" material="store" size="28" color="gray" />
						<div
							v-if="!loading.nearbyStores && !isSelectedStoreActive"
							slot="footer"
							class="text-color-red"
						>
							{{ $t('onlineOrdersClosed') }}
						</div>
					</f7-list-item>
				</f7-list>
				<f7-swiper
					v-if="loading.home || banners.length &&
						merchant.settings.app.appearance.home_page &&
						merchant.settings.app.appearance.home_page.show_banners"
					:params="{
						autoplay: {
							delay: 5000
						}
					}"
					pagination
				>
					<f7-swiper-slide
						v-for="(banner, index) in loading.home ? [1, 2, 3] : banners"
						:key="index"
						:class="{
							'skeleton-text': loading.home,
							'skeleton-effect-blink': loading.home
						}"
						style="height: 160px"
					>
						<f7-skeleton-block v-if="loading.home" style="height: 160px" />
						<img
							v-else
							:src="banner.file_path"
							:alt="banner.name"
							class="display-block lazy lazy-fade-in m-auto"
							style="max-height: 100%; max-width: 100%"
							@click="onBannerSelected(banner)"
						>
					</f7-swiper-slide>
				</f7-swiper>
				<div v-if="merchant.settings.app.appearance.home_page && merchant.settings.app.appearance.home_page.show_categories">
					<f7-block-title
						:class="{
							'skeleton-text': loading.categories,
							'skeleton-effect-blink': loading.categories,
							'text-capitalize': true
						}"
					>
						{{ $t('shop by category') }}
					</f7-block-title>
					<categories :loading="loading.categories" :categories="categories" class="px-3" />
				</div>
				<f7-block
					v-if="categories.pagination && categories.pagination.current_page < categories.pagination.next_page"
					v-observe-visibility="(isVisible) => {
						if (isVisible) {
							loadMoreResults('categories', 'getCategories')
						}
					}"
					class="text-align-center"
				>
					<f7-preloader />
				</f7-block>
				<div
					v-for="(itemBlock, itemBlockKey, position) in itemBlocks.data"
					:key="itemBlockKey"
					:class="{
						'mb-2': position === Object.keys(itemBlocks.data).length - 1
					}"
				>
					<div v-if="merchant.settings.app.appearance.home_page && merchant.settings.app.appearance.home_page[itemBlockKey]">
						<f7-block-title
							v-if="loading[itemBlockKey] || itemBlock.data.length"
							:class="{
								'skeleton-text': loading[itemBlockKey],
								'skeleton-effect-blink': loading[itemBlockKey],
								'text-capitalize': true
							}"
						>
							{{ itemBlock.title }}
							<f7-link href="#" :text="$t('view all')" class="float-right" @click="viewAllItems" />
						</f7-block-title>
						<f7-swiper
							:params="{
								slidesPerView: 'auto',
								spaceBetween: 30,
								preloadImages: false,
								lazy: true
							}"
							class="px-3"
						>
							<f7-swiper-slide
								v-for="(item, index) in loading[itemBlockKey] ? [1, 2, 3] : itemBlock.data"
								:key="`popular-item-${index}`"
								class="mt-1 mb-2"
								style="width: 225px"
							>
								<f7-card v-if="loading[itemBlockKey]" class="skeleton-text skeleton-effect-blink border m-0">
									<f7-skeleton-block style="height: 150px" />
									<f7-card-content class="p-2">
										<h4 class="text-capitalize m-0">
											{{ $t('item name') }}
										</h4>
										<h4 class="text-capitalize m-0">
											{{ $t('price details') }}
										</h4>
									</f7-card-content>
								</f7-card>
								<f7-card
									v-else
									class="elevation-4 m-0"
									@click.native="$f7router.navigate(`/items/${item.id}`)"
								>
									<ribbon
										v-if="item.mrp > item.price"
										class="text-uppercase"
										:text="`${merchant.settings.app.appearance && merchant.settings.app.appearance.discount_banner_type === 'percentage' ?
											`${((item.mrp - item.price) * 100 / item.mrp).toFixed(2)}%` :
											toCurrency(item.mrp - item.price)
										} ${$t('off')}`"
									/>
									<f7-card-header
										:data-background="item.images[0] || '/images/placeholder.svg'"
										class="lazy lazy-fade-in no-border p-0"
										:style="{
											height: '150px',
											backgroundPosition: 'center',
											backgroundRepeat: 'no-repeat',
											backgroundSize: 'contain'
										}"
									/>
									<f7-card-content class="p-2">
										<p class="word-wrap m-0">
											{{ locale === 'en' ? item.name : (item.alt_lang || item.name) }}
										</p>
										<p class="m-0">
											<span class="font-weight-bold">
												{{ toCurrency(item.price) }}
											</span>
											<del v-if="item.mrp > item.price">
												<small>
													{{ toCurrency(item.mrp) }}
												</small>
											</del>
										</p>
									</f7-card-content>
								</f7-card>
							</f7-swiper-slide>
						</f7-swiper>
					</div>
				</div>
			</div>
			<f7-actions
				ref="nearbyStoresActionSheet"
				:opened="showNearbyStores"
				@actions:closed="showNearbyStores = false"
			>
				<f7-actions-group>
					<f7-actions-label class="justify-content-space-between px-3 text-capitalize">
						{{ $t('nearby stores') }}
						<f7-link
							icon-f7="xmark"
							icon-size="20px"
							@click="$refs.nearbyStoresActionSheet.close()"
						/>
					</f7-actions-label>
					<f7-list class="my-0">
						<f7-list-item
							v-for="(store, index) in nearbyStores"
							:key="index"
							radio
							:checked="selectedStore && store.id === selectedStore.id"
							@click="setSelectedStore(store)"
						>
							<span slot="title" class="text-capitalize">
								{{ store.name }}
							</span>
							<f7-badge
								:color="store.available ? 'green' : 'red'"
								class="text-capitalize"
								style="height: auto"
							>
								{{ $t(store.available ? 'openNow' : 'closedNow') }}
							</f7-badge>
							<f7-icon slot="media" material="store" size="28" color="gray" />
							<div slot="footer">
								{{ (store.distance / 1000).toFixed(2) }} {{ $t('km') }}
							</div>
						</f7-list-item>
					</f7-list>
				</f7-actions-group>
			</f7-actions>
		</div>
		<menu-toolbar />
	</f7-page>
</template>

<script>
	import Categories from '@/components/categories'
	import Ribbon from '~/components/ribbon'
	import MenuToolbar from '~/components/menu-toolbar'

	export default {
		components: {
			Ribbon,
			Categories,
			MenuToolbar
		},
		data () {
			return {
				loading: {
					categories: true,
					nearbyStores: true,
					home: true,
					show_best_selling_items: true,
					show_recommended_items: true
				},
				showNearbyStores: false,
				categories: {
					data: [],
					pagination: null
				},
				banners: [],
				itemBlocks: {
					available: [],
					data: {
						show_best_selling_items: {
							title: this.$t('best seller'),
							data: []
						},
						show_recommended_items: {
							title: this.$t('recommended'),
							data: []
						}
					}
				},
				timer: null,
				catalogSearchResults: null,
				selectedCategories: [],
				showAlert: true
			}
		},
		computed: {
			locale () {
				return this.$store.state.locale
			},
			merchant () {
				return this.$store.state.merchant
			},
			user () {
				return this.$store.state.user
			},
			location () {
				return this.$store.state.location
			},
			nearbyStores: {
				get () {
					return this.$store.state.nearbyStores
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'nearbyStores',
						value
					})
				}
			},
			selectedStore: {
				get () {
					return this.$store.state.selectedStore
				},
				set (store) {
					this.$store.commit('setSelectedStore', store)
				}
			},
			isSelectedStoreActive () {
				return this.selectedStore ? this.selectedStore.available : false
			},
			cart: {
				get () {
					return this.$store.state.cart
				},
				set (value) {
					this.$store.commit('setState', { key: 'cart', value })
				}
			},
			processing: {
				get () {
					return this.$store.state.processing
				},
				set (value) {
					this.$store.commit('setState', { key: 'processing', value })
				}
			},
			allowLoadMore: {
				get () {
					return this.$store.state.allowLoadMore
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'allowLoadMore',
						value
					})
				}
			},
			filters: {
				get () {
					return this.$store.state.filters
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'filters',
						value
					})
				}
			},
			validSelectedCategories () {
				return this.selectedCategories.filter(c => c.id !== null)
			},
			category () {
				let category = { id: null, name: 'all' }

				if (this.validSelectedCategories.length) {
					category = this.validSelectedCategories[this.validSelectedCategories.length - 1]
				}

				return category
			}
		},
		beforeDestroy () {
			if (this.$refs.alertBanner) {
				this.$refs.alertBanner.removeEventListener('click', this.handleClick)
			}
		},
		mounted () {
			if (this.user && this.$f7route.query.track) {
				this.loading = false
				window.history.replaceState({}, document.title, '/')

				return this.$f7router.navigate(`/orders/${this.$f7route.query.track}/track`, {
					clearPreviousHistory: true
				})
			}

			if (!this.location) {
				this.loading = false
				setTimeout(() => this.$f7router.navigate('/locations'), 500)

				return false
			}

			this.getNearbyStores()
				.then(() => this.getCategories())
				.then(() => this.getHomePageDetails())
				.then(() => this.loadItemBlocks())
		},
		methods: {
			getCategories (filters = {}) {
				filters = Object.assign({
					active: true
				}, filters)

				return this.$store.dispatch('getCategories', filters).then((response) => {
					if (response.code === 200) {
						this.categories.data = filters.page
							? this.categories.data.concat(response.data.categories)
							: response.data.categories
						this.categories.pagination = response.data.pagination
					}
				}).catch(this.showErrors).finally(() => {
					this.loading.categories = false
					this.$nextTick(() => this.$f7.lazy.create(this.$$('#home')[0]))
				})
			},
			getNearbyStores () {
				this.loading.nearbyStores = true

				return this.$store.dispatch('getNearbyStores').finally(() => {
					this.loading.nearbyStores = false
				})
			},
			getHomePageDetails () {
				if (this.selectedStore) {
					this.loading.home = true

					return this.$axios.$get('/api/guest/home', {
						params: {
							store_id: this.selectedStore.id
						}
					}).then((response) => {
						this.banners = response.data.banners
						this.itemBlocks.available = response.data.item_blocks
					}).catch(() => {}).finally(() => {
						this.loading.home = false
						this.$nextTick(() => this.$f7.lazy.create(this.$$('#home')[0]))
					})
				}
			},
			loadItemBlocks () {
				if (this.selectedStore) {
					this.itemBlocks.available.forEach((itemBlock) => {
						this.loading[itemBlock] = true
						this.$axios.$get(`/api/guest/home/item-blocks/${itemBlock}`, {
							params: {
								store_id: this.selectedStore.id
							}
						}).then((response) => {
							if (this.itemBlocks.data[itemBlock]) {
								this.itemBlocks.data[itemBlock].data = response.data[itemBlock]
							}
						}).finally(() => {
							this.loading[itemBlock] = false
							this.$nextTick(() => this.$f7.lazy.create(this.$$('#home')[0]))
						})
					})
				}
			},
			setSelectedStore (store) {
				this.selectedStore = store
				this.$refs.nearbyStoresActionSheet.close()
				this.refresh()
			},
			selectCategory (category) {
				if (category.id) {
					this.selectedCategories.pop()
					this.selectedCategories.push(this.categories.data.find(c => c.id === category.id))

					if (category.child_categories_count > 0) {
						this.getCategories({ parent_id: category.id })
						this.selectedCategories.push({ id: null, name: 'all' })
					} else {
						this.closePanel()
						this.filters.category_ids = []
						this.filters.category_ids.push(category.id.toString())
						this.$f7router.navigate('/items')
					}
				} else {
					this.closePanel()
					this.selectedCategories.pop()
					this.selectedCategories.push({ id: null, name: 'all' })
					this.filters.category_ids = []
					this.$f7router.navigate('/items')
				}
			},
			handleClick (event) {
				const link = event.target.closest('a')

				if (link) {
					event.preventDefault()

					try {
						const urlObj = new URL(link.href)

						if (urlObj.pathname.startsWith('/items')) {
							const categoryId = urlObj.searchParams.get('category_id')

							if (categoryId) {
								this.filters.category_ids.push(categoryId.toString())
							}

							this.$f7router.navigate('/items')
						}
					} catch (error) {
						console.error('Error parsing link:', error)
					}
				}
			},
			onPressCategoryBack () {
				this.selectedCategories.splice(this.selectedCategories.length - 2)
				this.selectedCategories.push({ id: null, name: 'all' })

				this.getCategories(this.category.id ? { parent_id: this.category.id } : {})
			},
			closePanel () {
				const panel = this.$f7.panel.get()

				if (panel && panel.opened) {
					panel.close()
				}
			},
			viewAllItems () {
				this.filters.category_ids = []
				this.$f7router.navigate('/items')
			},
			showItemDetails (item) {
				this.$store.commit('setNavigationProps', { item })
				this.$f7router.navigate(`/items/${item.id}`)
			},
			catalogSearch ($event) {
				clearTimeout(this.timer)
				this.timer = setTimeout(() => {
					if ($event.target.value) {
						this.processing = true
						this.$axios.$get('/api/guest/home/search', {
							params: {
								query: $event.target.value,
								store_id: this.selectedStore.id
							}
						}).then((response) => {
							this.catalogSearchResults = response.data.catalogs
						}).finally(() => {
							this.processing = false
						})
					} else {
						this.catalogSearchResults = []
					}
				}, 500)
			},
			processSearchResult (result) {
				if (result.type === 'category') {
					this.selectCategory(result)
				} else if (result.type === 'item') {
					this.$f7router.navigate(`/items/${result.id}`)
				}
			},
			onBannerSelected (banner) {
				if (this.selectedStore && banner.entity) {
					if (banner.entity.type === 'item') {
						this.$f7router.navigate(`/items/${banner.entity.id}`)
					} else if (banner.entity.type === 'category') {
						this.selectCategory(banner.entity)
					}
				}
			}
		}
	}
</script>

<style lang="scss">
	#home {
		.navbar .title {
			border-bottom: 1px dotted;
		}

		#stores-swiper .swiper-slide {
			width: auto !important;
		}

		.chip-media {
			border: 1px solid;
		}
	}
	.alert-banner {
		background-color: #f8d7da;
		color: #721c24;
		border-radius: 4px;
		position: relative;
	}
	.alert-close {
		position: absolute;
		top: 12px;
		right: 12px;
		cursor: pointer;
	}
</style>
