<template>
	<dynamic-page slug="about_us" />
</template>

<script>
	import DynamicPage from '~/components/dynamic-page'

	export default {
		components: {
			DynamicPage
		}
	}
</script>
