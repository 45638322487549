import Vue from 'vue'
import f7Utils from 'framework7/utils/utils'
import utils from '~/assets/js/utils'

export default ({ app }) => {
	app.validateDiscount = utils.validateDiscount
	app.validateConditions = utils.validateConditions

	Vue.mixin({
		methods: {
			...utils,
			colorThemeCSSProperties: f7Utils.colorThemeCSSProperties
		}
	})
}
