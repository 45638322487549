<template>
	<f7-page
		id="items"
		ref="page"
		ptr
		:ptr-distance="60"
		infinite
		:infinite-distance="1"
		:infinite-preloader="items.pagination && items.pagination.current_page < items.pagination.next_page"
		@ptr:refresh="refresh"
		@infinite="loadMoreResults('items', 'getItems')"
	>
		<f7-navbar
			:title="$f7route.query.type === 'favorites' ? $t('favorites') : $tc('item', 2)"
			inner-class="text-capitalize"
			:back-link="$f7router.history.length > 1"
		>
			<f7-nav-right>
				<f7-link
					href="/items/filters"
					icon-material="filter_list"
					:icon-badge="filters.category_ids.length"
					badge-color="red"
				/>
			</f7-nav-right>
			<f7-subnavbar :inner="false">
				<f7-searchbar
					:placeholder="$t('searchItem')"
					custom-search
					@input="searchItems"
				/>
			</f7-subnavbar>
		</f7-navbar>

		<f7-row
			v-if="merchant.settings.general.catalog && merchant.settings.general.catalog.food_type_filter"
			class="p-3 page-bg-color shadow"
			:style="{
				position: 'sticky',
				top: 0,
				zIndex: 2
			}"
		>
			<f7-col
				v-for=" (type, i) in foodTypeFilter"
				:key="i"
			>
				<f7-button
					small
					round
					outline
					:fill="filters.food_type === type.value"
					class="display-flex align-items-center"
					@click="setFoodType(type.value)"
				>
					<f7-icon f7="dot_square" size="12" :color="type.color" />
					<span>{{ $t(type.label) }}</span>
				</f7-button>
			</f7-col>
		</f7-row>
		<items :loading="loading" :items="items.data" />
		<menu-toolbar v-if="$f7router.history.length === 1" />
		<cart-toolbar v-else />
	</f7-page>
</template>

<script>
	import Items from '~/components/items'
	import MenuToolbar from '~/components/menu-toolbar'
	import CartToolbar from '~/components/cart-toolbar'

	export default {
		components: {
			Items,
			MenuToolbar,
			CartToolbar
		},
		data () {
			return {
				loading: true,
				items: {
					data: [],
					pagination: null,
					filters: []
				},
				timer: null,
				searchTerm: null
			}
		},
		computed: {
			foodTypeFilter () {
				const filter = this.items.filters.find(filter => filter.filter_type === 'food_type')

				return filter?.values.length ? filter.values.slice(1) : []
			},
			processing: {
				get () {
					return this.$store.state.processing
				},
				set (value) {
					this.$store.commit('setState', { key: 'processing', value })
				}
			},
			allowLoadMore: {
				get () {
					return this.$store.state.allowLoadMore
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'allowLoadMore',
						value
					})
				}
			},
			filters: {
				get () {
					return this.$store.state.filters
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'filters',
						value
					})
				}
			},
			selectedStore () {
				return this.$store.state.selectedStore
			},
			merchant () {
				return this.$store.state.merchant
			}
		},
		mounted () {
			if (this.$f7route.query.reset_filters === '1') {
				this.$store.dispatch('resetFilters')
			}

			this.getNearbyStores()
				.catch(console.error) // eslint-disable-line
				.finally(() => this.getItems())
		},
		methods: {
			getNearbyStores () {
				return !this.selectedStore ? this.$store.dispatch('getNearbyStores') : Promise.resolve()
			},
			getItems (filters = {}) {
				filters = Object.assign({
					query: this.searchTerm,
					favorite: this.$f7route.query.type === 'favorites' ? 1 : 0
				}, filters, this.filters)

				return this.$store.dispatch('getItems', filters).then((response) => {
					this.loading = false

					if (response.code === 200) {
						this.items.data = filters.page ? this.items.data.concat(response.data.items) : response.data.items
						this.items.pagination = response.data.pagination
						this.items.filters = response.data.filters

						this.$nextTick(() => {
							if (this.$refs.page) {
								this.$f7.lazy.create(this.$refs.page.$el)
							}
						})
					}
				}).catch((err) => {
					this.showErrors(err)
					this.loading = false
				})
			},
			searchItems ($event) {
				clearTimeout(this.timer)
				this.timer = setTimeout(() => {
					this.processing = true
					this.searchTerm = $event.target.value
					this.getItems().then(() => {
						this.processing = false
					})
				}, 500)
			},
			setFoodType (type) {
				this.filters.food_type = this.filters.food_type !== type ? type : null
				this.processing = true
				this.getItems().finally(() => {
					this.processing = false
				})
			}
		}
	}
</script>
