<template>
	<div id="ev" class="h-100">
		<img v-if="$route.query.s === '1'" src="/images/tick.svg" alt="success" width="60%" class="my-3">
		<img v-else src="/images/cancel.svg" alt="failed" width="60%" class="my-3">
		<h1 class="text-color-white mt-0 mb-3 text-capitalize">
			<span v-if="$route.query.s === '1'">{{ $t('success') }}</span>
			<span v-else>{{ $t('failed') }}</span>
		</h1>
		<h3 class="text-color-white mt-0 mb-4 text-capitalize">
			<span v-if="$route.query.s === '1'">{{ $t('emailVerified') }}</span>
			<span v-else>{{ $t('tokenInvalid') }}</span>!
		</h3>
		<button class="button button-large button-raised mb-3 text-uppercase" @click="redirect">
			{{ $t('continue') }}
		</button>
	</div>
</template>

<script>
	export default {
		layout: 'ssr',
		methods: {
			redirect () {
				window.location.href = '/'
			}
		}
	}
</script>

<style lang="scss">
	#ev {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background: linear-gradient(to bottom, #39C0EE, #0AA4DA);

		button {
			height: 40px;
			width: 100px;
			background: #fff;
		}
	}
</style>
