<template>
	<f7-page
		id="filters"
		:infinite="activeTab === 'category'"
		:infinite-distance="1"
		:infinite-preloader="categories.pagination && categories.pagination.current_page < categories.pagination.next_page"
		@infinite="loadMoreResults('categories', 'getCategories')"
	>
		<f7-navbar :title="$t('filters')" inner-class="text-capitalize" back-link back-link-url="/items" back-link-force>
			<f7-nav-right>
				<f7-link class="text-capitalize" @click="resetFilters">
					{{ $t('clear all') }}
				</f7-link>
			</f7-nav-right>
		</f7-navbar>
		<f7-toolbar tabbar labels bottom>
			<f7-link tab-link="#categories-tab" :text="$options.filters.capitalize($tc('category', 1))" icon-f7="square_list" tab-link-active />
			<f7-link tab-link="#sorting-tab" :text="$options.filters.capitalize($t('sort'))" icon-f7="arrow_up_arrow_down" />
		</f7-toolbar>
		<f7-tabs ref="tabs">
			<f7-tab id="categories-tab" class="page-content py-0" tab-active @tab:show="activeTab = 'category'">
				<div class="px-3 pt-3">
					<custom-input
						v-model="searchTerm"
						type="text"
						:placeholder="$options.filters.capitalize($t('search category'))"
					/>
				</div>
				<f7-block-title class="text-capitalize">
					{{ $tc('category', 2) }}
				</f7-block-title>
				<f7-list
					:class="{
						'skeleton-text': loading,
						'skeleton-effect-blink': loading
					}"
				>
					<f7-list-item
						v-for="(category, index) in loading ? [1, 2, 3] : categories.data"
						:key="index"
						:title="loading ? 'xxxxx xxxxx xxxxx' : locale === 'en' ? category.name : ( category.alternate_language || category.name)"
						:checkbox="!loading"
						:value="category.id"
						:checked="!loading ? filters.category_ids.includes(category.id.toString()) : false"
						@change="checkCategory"
					>
						<f7-skeleton-block v-if="loading" slot="media" style="width: 30px; height: 30px; border-radius: 50%" />
					</f7-list-item>
				</f7-list>
			</f7-tab>
			<f7-tab id="sorting-tab" class="page-content py-0" @tab:show="activeTab = 'sort'">
				<f7-block-title
					class="text-capitalize"
				>
					{{ $t('sortItemsBy') }}
				</f7-block-title>
				<f7-list>
					<f7-list-item
						v-for="(sort, index) in sortingList"
						:key="index"
						:title="sort.title"
						name="sort"
						radio
						:value="JSON.stringify(sort.value)"
						:checked="filters.order_by === sort.value.order_by && filters.dir === sort.value.dir"
						@change="checkSort"
					/>
				</f7-list>
			</f7-tab>
		</f7-tabs>
	</f7-page>
</template>

<script>
	import CustomInput from '~/components/custom-input'

	export default {
		components: {
			CustomInput
		},
		data () {
			return {
				loading: true,
				searchTerm: '',
				activeTab: 'category',
				categories: {
					data: [],
					pagination: null
				},
				sortingList: [
					{
						title: this.$t('sortOptions.AZ'),
						value: {
							order_by: 'name',
							dir: 1
						}
					},
					{
						title: this.$t('sortOptions.ZA'),
						value: {
							order_by: 'name',
							dir: -1
						}
					},
					{
						title: this.$t('sortOptions.LH'),
						value: {
							order_by: 'price',
							dir: 1
						}
					},
					{
						title: this.$t('sortOptions.HL'),
						value: {
							order_by: 'price',
							dir: -1
						}
					}
				]
			}
		},
		computed: {
			allowLoadMore: {
				get () {
					return this.$store.state.allowLoadMore
				},
				set (value) {
					this.$store.commit('setState', { key: 'allowLoadMore', value })
				}
			},
			locale () {
				return this.$store.state.locale
			},
			filters: {
				get () {
					return this.$store.state.filters
				},
				set (value) {
					this.$store.commit('setState', { key: 'filters', value })
				}
			}
		},
		watch: {
			searchTerm (value) {
				this.searchCategories(value)
			}
		},
		mounted () {
			this.getCategories()
		},
		methods: {
			getCategories (filters = {}) {
				filters = Object.assign({
					active: true,
					query: this.searchTerm
				}, filters)

				return this.$store.dispatch('getCategories', filters).then((response) => {
					this.loading = false

					if (response.code === 200) {
						this.categories.data = filters.page
							? this.categories.data.concat(response.data.categories)
							: response.data.categories
						this.categories.pagination = response.data.pagination
					}
				}).catch((err) => {
					this.showErrors(err)
					this.loading = false
				})
			},
			searchCategories (value) {
				clearTimeout(this.timer)
				this.timer = setTimeout(() => {
					this.processing = true
					this.searchTerm = value
					this.getCategories().then(() => {
						this.processing = false
					})
				}, 500)
			},
			checkCategory ($event) {
				if ($event.target.checked) {
					this.filters.category_ids.push($event.target.value)
				} else {
					this.filters.category_ids.splice(this.filters.category_ids.indexOf($event.target.value), 1)
				}
			},
			checkSort ($event) {
				if ($event.target.checked) {
					this.filters = Object.assign({}, this.filters, JSON.parse($event.target.value))
				} else {
					this.filters.order_by = null
					this.filters.dir = null
				}
			},
			resetFilters () {
				this.$store.dispatch('resetFilters')
			}
		}
	}
</script>

<style lang="css" scoped>
</style>
