<template>
	<f7-page id="pg">
		<f7-navbar
			:title="$t('paymentGateway.title')"
			:subtitle="`${cart.items.length} ${ $tc('item', cart.items.length > 1 ? 2 : 1) }, ${ $t('toPay') }: ${
				toCurrency(cart.price.total)
			}`"
			inner-class="text-capitalize"
		/>
		<div
			class="h-100 display-flex justify-content-center align-items-center px-3"
			style="flex-direction: column"
		>
			<img src="/images/online-payment.svg" height="200px" class="mb-3">
			<f7-preloader v-if="loading" />
			<p v-if="loading" class="mb-0">
				{{ message }}, {{ $t('pleaseWait') | capitalize }}
			</p>
			<div v-else-if="pgUrl">
				<p class="my-0">
					{{ $t('paymentGateway.start') }} {{ toCurrency(cart.price.total) }}
				</p>
				<p class="mt-0">
					{{ $t('paymentGateway.redirect') }}
				</p>
				<f7-button
					large
					raised
					fill
					@click.once="startPayment"
				>
					{{ $t('startPayment') }}
				</f7-button>
			</div>
			<p v-if="loading && pgUrl" class="m-0">
				{{ $t('paymentWarning') }}
			</p>
		</div>
	</f7-page>
</template>

<script>
	export default {
		data () {
			return {
				loading: true,
				pgUrl: null,
				pgWindow: null,
				pgStatusInterval: null,
				message: this.$t('paymentGateway.initPayment')
			}
		},
		computed: {
			locale () {
				return this.$store.state.locale
			},
			merchant () {
				return this.$store.state.merchant
			},
			user () {
				return this.$store.state.user
			},
			cart () {
				return this.$store.state.cart
			},
			paymentMethod () {
				return this.$store.state.paymentMethod
			},
			deliveryAddress () {
				return this.$store.state.deliveryAddress
			}
		},
		mounted () {
			window.initPg = this.initPg
			window.closePg = this.closePg
			setTimeout(this.initPgRequest, 2000)
		},
		destroyed () {
			clearInterval(this.pgStatusInterval)
		},
		methods: {
			startPayment () {
				this.loading = true
				this.message = this.$t('paymentGateway.redirecting')
				this.$bridge.initPg(this.pgUrl)

				setTimeout(() => {
					this.checkPaymentStatus()
					this.message = this.$t('paymentGateway.waiting')
				}, 2000)
			},
			initPgRequest () {
				let data = null

				switch (this.paymentMethod) {
					case 'credopay':
						data = {
							amount: process.env.NODE_ENV === 'production' ? +this.cart.price.total.toFixed(2) : 1,
							currency: 'INR',
							timestamp: this.$moment().format('YYYYMMDDHHmmss'),
							transaction_id: this.$f7route.query.order_id,
							transaction_type: 'AA',
							payment_channel: 'Pg'
						}

						break
					case 'cashfree':
						data = {
							order_id: this.$f7route.query.order_id,
							order_amount: +this.cart.price.total.toFixed(2),
							order_currency: 'INR',
							order_note: `${this.$t('billingForOrder', [this.$f7route.query.order_id])}`
						}

						break
					case 'airpay':
						data = {
							orderid: (new Date()).getTime(),
							amount: +this.cart.price.total.toFixed(2),
							currency: 356,
							isocurrency: 'INR',
							chmod: '',
							buyerAddress: '',
							buyerCity: '',
							buyerState: '',
							buyerCountry: '',
							buyerPinCode: '',
							customvar: this.$f7route.query.order_id,
							date: this.$moment().format('YYYY-MM-DD')
						}

						if (this.deliveryAddress) {
							// data.buyerAddress = this.deliveryAddress.line
							data.buyerCity = this.deliveryAddress.city
							data.buyerState = this.deliveryAddress.state
							data.buyerCountry = this.deliveryAddress.country
							data.buyerPinCode = this.deliveryAddress.zip
						}

						break
					case 'paytabs':
						data = {
							tran_type: 'sale',
							tran_class: 'ecom',
							cart_id: this.$f7route.query.order_id,
							cart_description: this.$t('billingForOrder', [this.$f7route.query.order_id]),
							cart_currency: this.merchant.settings.general.currency_code,
							cart_amount: +this.cart.price.total.toFixed(3),
							customer_details: {
								name: this.user.name,
								email: this.user.email || process.env.POSBYTZ_EMAIL
							}
						}

						if (this.deliveryAddress) {
							data.customer_details.street1 = this.deliveryAddress.line
							data.customer_details.city = this.deliveryAddress.city
							data.customer_details.state = this.deliveryAddress.state
							data.customer_details.country = this.deliveryAddress.country
						} else {
							data.hide_shipping = true
						}

						break
					case 'addpay':
						data = {
							reference: this.$f7route.query.order_id,
							description: `${this.$t('billingForOrder', [this.$f7route.query.order_id])}`,
							amount: {
								value: +this.cart.price.total.toFixed(2),
								currency_code: 'ZAR'
							}
						}
						break
					case 'smartpay':
						data = {
							order_id: this.$f7route.query.order_id,
							merchant_order_no: (new Date()).getTime(),
							amount: parseFloat(this.cart.price.total).toFixed(2),
							currency: 'PHP',
							description: this.$t('billingForOrder', [this.$f7route.query.order_id])
						}

						break
					case 'stripe':
						data = {
							order_id: this.$f7route.query.order_id,
							merchant_order_no: (new Date()).getTime(),
							amount: parseFloat(this.cart.price.total).toFixed(2) * 100,
							currency: this.merchant.settings.general.currency_code,
							customer_email: this.user.email || process.env.POSBYTZ_EMAIL
						}

						break
					case 'razorpay':
						data = {
							order_id: this.$f7route.query.order_id,
							merchant_order_no: (new Date()).getTime(),
							amount: parseFloat(this.cart.price.total).toFixed(2) * 100,
							currency: this.merchant.settings.general.currency_code,
							customer_email: this.user.email || process.env.POSBYTZ_EMAIL,
							description: this.$t('billingForOrder', [this.$f7route.query.order_id]),
							logo: this.merchant.settings.general.logo_img_url,
							merchant_name: this.merchant.name
						}
						break
				}

				this.$store.dispatch('generatePgRequest', data).then((response) => {
					this.loading = false
					this.pgUrl = `${window.location.origin}/api/payments/${this.paymentMethod}?data=${
						encodeURIComponent(response.data)
					}`
				}).catch((err) => {
					this.showErrors(err)
				})
			},
			initPg (url) {
				this.pgWindow = window.open(url, '_blank')
			},
			closePg () {
				this.pgWindow.close()
			},
			checkPaymentStatus () {
				let attempt = 0
				let checking = false

				this.pgStatusInterval = setInterval(() => {
					if (!checking) {
						checking = true
						this.$store.dispatch('checkPaymentStatus', {
							order_id: this.$f7route.query.order_id
						}).then((response) => {
							if (['S', 'F'].includes(response.status) || attempt >= 100) {
								clearInterval(this.pgStatusInterval)
								this.$bridge.closePg()
								setTimeout(() => {
									if (response.status === 'S') {
										this.$f7router.navigate(`/orders/${this.$f7route.query.order_id}`, {
											clearPreviousHistory: true
										})

										this.$store.dispatch('resetCart')
									} else {
										this.$f7router.back()
										this.$f7.toast.create({
											text: this.$t('toastMsg.paymentFailed'),
											closeTimeout: 3000
										}).open()
									}
								}, 3000)
							} else if (this.pgWindow && this.pgWindow.closed) {
								attempt = 100
							}

							checking = false
						}).catch(() => {
							checking = false
						})
					}

					attempt++
				}, 3000)
			}
		}
	}
</script>
