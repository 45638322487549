<template>
	<f7-page id="referrals">
		<f7-navbar :title="$t('referrals')" inner-class="text-capitalize" :back-link="$t('back')" />
		<div class="text-align-center mt-4">
			<img src="/images/referral.svg" height="200px">
		</div>
		<f7-block-title>{{ $t('inviteYourFriends') }}</f7-block-title>
		<f7-list>
			<f7-list-item
				link="#"
				:footer="user.referral_code"
				:after="$options.filters.capitalize($t('copy'))"
				no-chevron
				@click="copyToClipboard"
			>
				<f7-icon slot="media" material="content_copy" color="gray" />
				<span slot="title" class="text-capitalize">{{ user.name }}</span>
			</f7-list-item>
		</f7-list>
		<div class="toolbar-button-container">
			<f7-button large fill @click="share">
				{{ $t('share') }}
			</f7-button>
		</div>
	</f7-page>
</template>

<script>
	export default {
		computed: {
			merchant () {
				return this.$store.state.merchant
			},
			user () {
				return this.$store.state.user
			},
			referralLink () {
				return `${window.location.origin}?referral_code=${this.user.referral_code}`
			}
		},
		methods: {
			copyToClipboard () {
				const input = document.createElement('input')

				input.value = this.user.referral_code
				document.body.appendChild(input)
				input.select()
				document.execCommand('copy')
				document.body.removeChild(input)
				this.$f7.toast.create({
					text: this.$t('toastMsg.referralCodeCopied'),
					closeTimeout: 3000
				}).open()
			},
			share () {
				if (typeof this.$bridge.share === 'function') {
					const data = {
						title: `${this.$t('refer friends')}!`,
						text: this.$t('toastMsg.referrerMsg', [this.merchant.business.name, this.referralLink]),
						url: this.referralLink
					}

					this.$bridge.share(this.$bridge.name === 'ANDROID' ? JSON.stringify(data) : data)
				}
			}
		}
	}
</script>

<style lang="scss">
	#referrals {
		.page-content {
			padding-bottom: 81px;
		}
	}
</style>
