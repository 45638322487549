<template>
	<f7-page id="order-detail" :ptr="true" :ptr-distance="60" @ptr:refresh="refresh">
		<f7-navbar
			:title="`${ $tc('order', 1) } #${order ? order.pos_order_id : ''}`"
			:subtitle="order ? `${order.items.length} ${$tc('item', order.items.length > 1 ? 2 : 1)} | ${
				toCurrency(order.total)
			} | ${$t(order.order_type)}` : ''"
			inner-class="text-capitalize"
			:back-link="$t('back')"
			@click:back="$f7router.back('/orders')"
		>
			<f7-nav-right v-if="order">
				<f7-link
					v-if="order.order_status !== 'completed' && order.store_details.phone.number"
					icon-f7="phone_circle"
					@click="$bridge.openDialer(
						order.store_details.phone.number
					)"
				/>
				<f7-link icon-f7="ellipsis_vertical" popover-open=".popover-menu" />
			</f7-nav-right>
		</f7-navbar>
		<f7-popover class="popover-menu">
			<f7-list>
				<f7-list-item
					link="#"
					popover-close
					:title="$options.filters.capitalize($t('download invoice'))"
					no-chevron
					@click="downloadInvoice"
				>
					<f7-icon slot="media" f7="cloud_download" />
				</f7-list-item>
				<f7-list-item
					v-if="freshChatLoaded"
					link="#"
					popover-close
					:title="$options.filters.capitalize($t('contact support'))"
					no-chevron
					@click="initFreshChatWidget"
				>
					<f7-icon slot="media" f7="bubble_left_bubble_right" />
				</f7-list-item>
			</f7-list>
		</f7-popover>
		<div v-if="loading" class="skeleton-text skeleton-effect-blink">
			<div class="timeline my-3">
				<div v-for="i in 2" :key="i" class="timeline-item">
					<div class="timeline-item-date" style="width: auto">
						xx xxx
					</div>
					<div class="timeline-item-divider" />
					<div class="timeline-item-content">
						<div class="timeline-item-time">
							xxxxxxx
						</div>
						<div class="timeline-item-title">
							xxxxx
						</div>
						<div class="timeline-item-subtitle">
							xxxxx xxxxx xxxxx
						</div>
						<div class="timeline-item-text">
							xxxxx xxxxx xxxxx xxxxx
						</div>
					</div>
				</div>
			</div>
			<p class="mx-3 my-0">
				xxxxx xxxxx xxxxx xxxxx xxxxx xxxxx xxxxx
			</p>
			<div v-for="i in 2" :key="`i-${i}`">
				<f7-block-title>xxxxx</f7-block-title>
				<f7-row v-for="j in 3" :key="`j-${j}`" class="justify-content-between px-3 py-2">
					<f7-col width="50">
						xxxxx xxxxx
					</f7-col>
					<f7-col width="25">
						xx
					</f7-col>
					<f7-col width="25">
						<p class="float-right m-0">
							xxxxx
						</p>
					</f7-col>
				</f7-row>
			</div>
		</div>
		<div
			v-else-if="order"
			:style="{
				paddingBottom: order.can_cancel_order ? '80px' : 0
			}"
		>
			<div v-if="order.order_type === 'delivery'" class="timeline my-3">
				<div class="timeline-item">
					<div class="timeline-item-date" style="width: auto">
						<f7-icon material="store" color="gray" />
					</div>
					<div class="timeline-item-divider" />
					<div class="timeline-item-content">
						<div class="timeline-item-title text-capitalize">
							{{ order.store_details.name }}
						</div>
						<div class="timeline-item-subtitle">
							{{ $t('orderedOn') }} {{ $moment.utc(order.order_date).local().locale(locale).format('D MMM LT') }}
						</div>
						<div class="timeline-item-text">
							{{ order.store_details.address.line }}
						</div>
					</div>
				</div>
				<div class="timeline-item">
					<div class="timeline-item-date" style="width: auto">
						<f7-icon
							:material="order.shipping_address.annotation === 'other' ?
								'location_city' : order.shipping_address.annotation"
							color="gray"
						/>
					</div>
					<div class="timeline-item-divider" />
					<div class="timeline-item-content">
						<div class="timeline-item-title text-capitalize">
							{{ $t(order.shipping_address.annotation) }}
						</div>
						<div v-if="order.delivered_at" class="timeline-item-subtitle">
							{{ $t('deliveredOn') | capitalize }} {{ $moment.utc(order.delivered_at).local().locale(locale).format('D MMM LT') }}
						</div>
						<div v-else-if="order.scheduled_at" class="timeline-item-subtitle">
							{{ $t('scheduledDelivery') }} {{ $moment.utc(order.scheduled_at).local().locale(locale).format('D MMM LT') }}
						</div>
						<div class="timeline-item-text">
							{{ order.shipping_address.door_no ? `${order.shipping_address.door_no}, ` : null }}  {{ order.shipping_address.line }}
						</div>
					</div>
				</div>
			</div>
			<f7-row v-else-if="order.order_type === 'pickup'" class="align-items-center m-3">
				<f7-col width="15">
					<f7-icon material="store" size="30" color="gray" />
				</f7-col>
				<f7-col width="85">
					<p class="display-flex align-items-center timeline-item-title m-0">
						<span class="mr-1">{{ order.store_details.name }}</span>
						<f7-link icon-ios="f7:info" icon-md="material:info_fill" icon-color="primary" class="store-info" />
					</p>
					<p class="m-0">
						{{ $t('orderedOn') }} {{ $moment.utc(order.order_date).local().locale(locale).format('D MMM LT') }}
					</p>
					<small>{{ order.store_details.address.line }}</small>
				</f7-col>
			</f7-row>
			<f7-row class="align-items-center m-3">
				<f7-col width="10">
					<f7-icon
						v-if="[
							'pending', 'payment_authorized', 'cod_authorized'
						].includes(order.order_status)"
						material="access_time"
						color="gray"
					/>
					<f7-icon
						v-if="['confirmed'].includes(order.order_status)"
						material="thumb_up"
						color="gray"
					/>
					<f7-icon v-if="order.order_status === 'dispatched'" material="local_shipping" color="gray" />
					<f7-icon v-if="order.order_status === 'ready'" material="done" color="gray" />
					<f7-icon v-if="order.order_status === 'delivered'" material="done_all" color="gray" />
					<f7-icon v-if="order.order_status === 'completed'" material="check_circle" color="gray" />
					<f7-icon v-if="order.order_status === 'cancelled'" material="thumb_down" color="gray" />
					<f7-icon v-if="order.order_status === 'refunded'" material="undo" color="gray" />
				</f7-col>
				<f7-col width="90">
					<p class="m-0">
						<span
							v-if="[
								'pending', 'payment_authorized', 'cod_authorized'
							].includes(order.order_status)"
						>
							{{ $t('statusMessage.processed') }}
						</span>
						<span v-else-if="order.order_status === 'ready'">
							{{ $t('statusMessage.ready', [order.order_type]) }}
						</span>
						<span
							v-else-if="[
								'confirmed', 'dispatched', 'delivered',
								'completed', 'cancelled', 'refunded'
							].includes(order.order_status)"
						>
							{{ $t(`statusMessage.${order.order_status}`) }}
						</span>
					</p>
					<p v-if="order.cancel_notes" class="m-0">
						{{ $t('reason') | capitalize }}: {{ order.cancel_notes }}
					</p>
				</f7-col>
			</f7-row>
			<!-- <div v-if="order && order.feedback">
				<f7-block-title>
					Your rating
				</f7-block-title>
				<div class="display-flex align-items-center px-3">
					<span class="mr-1" style="color: #FFC107">&starf;</span>
					<span>{{ order.feedback.rating }} | {{ feedback.messages[order.feedback.rating] }}</span>
				</div>
			</div>
			<f7-button
				v-else-if="order && order.order_status === 'completed'"
				class="mt-3 mx-3"
				outline
				@click="feedback.show = true"
			>
				Rate Order
			</f7-button> -->
			<f7-button
				v-if="order && order.order_type === 'delivery' && ![
					'delivered', 'completed', 'cancelled', 'refunded'
				].includes(order.order_status) && merchant.subscription.products.delivery_app &&
					merchant.platforms.findIndex((i) => i.platform_type === 'delivery') !== -1"
				:href="`/orders/${order.pos_order_id}/track`"
				class="mt-3 mx-3"
				outline
			>
				{{ $t('track order') }}
			</f7-button>
			<f7-button
				v-else-if="order && [
					'delivered', 'completed', 'cancelled', 'refunded'
				].includes(order.order_status)"
				class="mt-3 mx-3"
				outline
				@click="reorder"
			>
				{{ $t('reorder') }}
			</f7-button>
			<div v-if="order.active_conversations && order.active_conversations.length">
				<f7-block-title
					class="text-capitalize"
				>
					{{ $t('activeConversations') }}
				</f7-block-title>
				<f7-list class="mb-0">
					<f7-list-item
						v-for="(conversation, index) in order.active_conversations"
						:key="index"
						:link="`/conversations/${conversation.id}`"
						:route-props="{ orderId: order.id }"
						:title="conversation.issue.name"
					>
						<f7-icon slot="media" f7="chat_bubble_text" color="gray" />
					</f7-list-item>
				</f7-list>
			</div>
			<f7-block-title
				class="text-capitalize"
			>
				{{ order.items.length }} {{ $tc('item', order.items.length > 1 ? 2 : 1) }}
			</f7-block-title>
			<f7-row
				v-for="(item, index) in order.items"
				:key="index"
				class="justify-content-space-between align-items-center px-3 py-2"
				style="font-size: var(--f7-list-font-size); line-height: 1.2"
			>
				<f7-col width="50">
					<p class="m-0">
						{{ item.item_name }}
					</p>
					<small v-if="item.combos && item.combos.length" class="display-block">
						{{ item.combos.map(c => c.item_name).join(', ') }}
					</small>
					<small v-if="item.modifiers && item.modifiers.length" class="text-capitalize">
						{{ $t('with') }} {{ item.modifiers.map(m => m.item_name).join(', ') }}
						<br>
					</small>
					<small v-if="item.custom_attributes.notes">
						{{ $tc('note', 1) | capitalize }}: {{ item.custom_attributes.notes }}
					</small>
				</f7-col>
				<f7-col width="25" class="text-align-center">
					x{{ item.qty }}
				</f7-col>
				<f7-col width="25">
					<p class="float-right m-0">
						{{ toCurrency(item.sub_total) }}
					</p>
				</f7-col>
			</f7-row>
			<f7-block-title
				class="text-capitalize"
			>
				{{ $t('bill details') }}
			</f7-block-title>
			<f7-row
				class="justify-content-space-between align-items-center px-3 py-2"
				style="font-size: var(--f7-list-font-size)"
			>
				<f7-col width="70">
					<span class="text-capitalize">
						{{ $t('subtotal') }}
					</span>
				</f7-col>
				<f7-col width="30">
					<p class="float-right m-0">
						{{ toCurrency(order.sub_total) }}
					</p>
				</f7-col>
			</f7-row>
			<f7-row
				v-if="(
					!order.custom_attributes.tax_calculation_phase || order.custom_attributes.tax_calculation_phase === 'before_discount'
				) && order.tax"
				class="justify-content-space-between align-items-center px-3 py-2"
				style="font-size: var(--f7-list-font-size)"
			>
				<f7-col width="70">
					<span class="text-capitalize">
						{{ $tc('tax', 1) }}
					</span>
				</f7-col>
				<f7-col width="30">
					<p class="float-right m-0">
						{{ toCurrency(order.tax) }}
					</p>
				</f7-col>
			</f7-row>
			<f7-row
				v-if="order.discount"
				class="justify-content-space-between align-items-center px-3 py-2"
				style="font-size: var(--f7-list-font-size)"
			>
				<f7-col width="70" class="text-capitalize">
					<span class="text-capitalize">
						{{ $t('discount') }}
					</span>
				</f7-col>
				<f7-col width="30">
					<p class="float-right m-0">
						- {{ toCurrency(order.discount) }}
					</p>
				</f7-col>
			</f7-row>
			<f7-row
				v-if="order.custom_attributes.tax_calculation_phase === 'after_discount' && order.tax"
				class="justify-content-space-between align-items-center px-3 py-2"
				style="font-size: var(--f7-list-font-size)"
			>
				<f7-col width="70">
					<span class="text-capitalize">
						{{ $tc('tax', 1) }}
					</span>
				</f7-col>
				<f7-col width="30">
					<p class="float-right m-0">
						{{ toCurrency(order.tax) }}
					</p>
				</f7-col>
			</f7-row>
			<f7-row
				v-for="(charge, index) in order.charges"
				:key="`charge-${index}`"
				class="justify-content-space-between align-items-center px-3 py-2"
				style="font-size: var(--f7-list-font-size)"
			>
				<f7-col width="70" class="text-capitalize">
					{{ charge.name }}
				</f7-col>
				<f7-col width="30">
					<p class="float-right m-0">
						{{ toCurrency(charge.amount) }}
					</p>
				</f7-col>
			</f7-row>
			<f7-row
				v-if="order.round_off_amount"
				class="justify-content-space-between align-items-center px-3 py-2"
				style="font-size: var(--f7-list-font-size)"
			>
				<f7-col width="70">
					<span class="text-capitalize">
						{{ $t('round off') }}
					</span>
				</f7-col>
				<f7-col width="30">
					<p class="float-right m-0">
						{{ toCurrency(order.round_off_amount) }}
					</p>
				</f7-col>
			</f7-row>
			<f7-row
				class="justify-content-space-between align-items-center font-weight-bold px-3 py-2 mb-2"
				style="font-size: var(--f7-list-font-size)"
			>
				<f7-col width="70">
					{{ $t('total') | capitalize }} ({{
						order.payment_method === 'cod' && order.order_status !== 'completed' ?
							$options.filters.capitalize($t('payable')) : $options.filters.capitalize($t('paid'))
					}} {{ $t('via') }} {{ order.payment_method }})
				</f7-col>
				<f7-col width="30">
					<p class="float-right m-0">
						{{ toCurrency(order.total) }}
					</p>
				</f7-col>
			</f7-row>
		</div>
		<div
			v-else
			class="h-100 display-flex justify-content-center align-items-center"
			style="flex-direction: column"
		>
			<img src="/images/no-result.svg" height="200px">
			<h2 class="mb-0 text-capitalize">
				{{ $t('orderNotFound') }}
			</h2>
			<p class="m-0">
				{{ $t('pleaseTryAgain') }}
			</p>
		</div>
		<div v-if="order && order.can_cancel_order" class="toolbar-button-container">
			<f7-button
				color="red"
				large
				fill
				@click="orderCancellation.show = true"
			>
				{{ $t('cancel order') }}
			</f7-button>
		</div>
		<f7-popup
			v-if="order"
			id="cancel-order"
			:opened="orderCancellation.show"
			@popup:opened="getOrderCancelReasons"
			@popup:closed="resetCancellation"
		>
			<f7-page>
				<f7-navbar :title="`${$t('cancel order')} #${order.pos_order_id}`" inner-class="text-capitalize">
					<f7-nav-right>
						<f7-link popup-close>
							{{ $tc('close', 1) | capitalize }}
						</f7-link>
					</f7-nav-right>
				</f7-navbar>
				<f7-block-title v-if="orderCancellation.reasons.length" class="mt-3">
					{{ $t('selectAnReason') }}
				</f7-block-title>
				<f7-list>
					<f7-list-item
						v-for="(reason, index) in orderCancellation.reasons"
						:key="index"
						radio
						radio-icon="start"
						:title="$t(`cancelOrderReasons.${reason.name}`)"
						name="cancellation-reason"
						:value="JSON.stringify(reason)"
						:checked="orderCancellation.selectedReason && orderCancellation.selectedReason.slug === reason.slug"
						@change="orderCancellation.selectedReason = JSON.parse($event.target.value)"
					/>
					<f7-list-input
						v-if="orderCancellation.selectedReason && orderCancellation.selectedReason.slug === 'other'"
						:label="$t('pleaseSpecify')"
						type="textarea"
						:placeholder="$t('typeReason')"
						:value="orderCancellation.otherReason"
						:info="$t('minimumCharLength', [16])"
						resizable
						@input="orderCancellation.otherReason = $event.target.value"
					/>
				</f7-list>
				<div class="toolbar-button-container">
					<f7-button
						large
						fill
						color="red"
						:disabled="!validateCancellation || processing"
						@click="cancelOrder"
					>
						{{ $t('confirm') }}
					</f7-button>
				</div>
			</f7-page>
		</f7-popup>
		<f7-popup
			v-if="order"
			id="feedback"
			:opened="feedback.show"
			@popup:closed="feedback.show = false"
		>
			<f7-page>
				<f7-navbar :title="`${$t('order from')} ${order.store_details.name}`">
					<f7-nav-right>
						<f7-link popup-close>
							{{ $tc('close', 1) | capitalize }}
						</f7-link>
					</f7-nav-right>
				</f7-navbar>
				<f7-block-title class="mt-3 text-capitalize">
					{{ $t('overall rating') }}
				</f7-block-title>
				<f7-card>
					<f7-card-content>
						<h2 class="text-align-center">
							{{ feedback.messages[feedback.rating] }}
						</h2>
						<star-rating
							v-model="feedback.rating"
							class="justify-content-center"
							:show-rating="false"
						/>
					</f7-card-content>
				</f7-card>
				<f7-card>
					<f7-card-content>
						<h4 class="text-align-center mt-0">
							<span v-if="feedback.rating < 3">{{ $t('feedbackRating.bad') }}</span>
							<span v-else-if="feedback.rating > 3">{{ $t('feedbackRating.good') }}</span>
							<span v-else>{{ $t('feedbackRating.improve') }}</span>
						</h4>
						<f7-row>
							<f7-col>
								<f7-button
									:fill="feedback.impressions.includes('quality')"
									outline
									@click="setImpression('quality')"
								>
									{{ $t('quality') }}
								</f7-button>
							</f7-col>
							<f7-col>
								<f7-button
									:fill="feedback.impressions.includes('package')"
									outline
									@click="setImpression('package')"
								>
									{{ $t('package') }}
								</f7-button>
							</f7-col>
							<f7-col>
								<f7-button
									:fill="feedback.impressions.includes('delivery')"
									outline
									@click="setImpression('delivery')"
								>
									{{ $t('delivery') }}
								</f7-button>
							</f7-col>
						</f7-row>
					</f7-card-content>
				</f7-card>
				<f7-card>
					<f7-card-content>
						<f7-list no-hairlines-md>
							<f7-list-input
								type="textarea"
								resizable
								:placeholder="feedback.rating < 3 ? $t('feedbackRating.more') : (
									feedback.rating > 3 ? $t('feedbackRating.liked') :
									$t('feedbackRating.suggestions')
								)"
								@input="feedback.remarks = $event.target.value"
							/>
							<p class="mt-0 px-3" style="font-size: 12px">
								{{ $t('feedbackRating.listening') }}}
							</p>
						</f7-list>
					</f7-card-content>
				</f7-card>
				<f7-button
					style="position: fixed; left: 0; right: 0; bottom: 0; border-radius: unset; z-index: 1"
					large
					round
					fill
					@click="submitFeedback"
				>
					{{ $t('submitFeedback') }}
				</f7-button>
			</f7-page>
		</f7-popup>
	</f7-page>
</template>

<script>
	import StarRating from 'vue-star-rating'

	export default {
		components: {
			StarRating
		},
		data () {
			return {
				loading: true,
				order: null,
				freshChatLoaded: false,
				orderCancellation: {
					show: false,
					reasons: [],
					selectedReason: null,
					otherReason: ''
				},
				feedback: {
					show: false,
					rating: 5,
					messages: {
						1: this.$t('feedBackMessage.veryBad'),
						2: this.$t('feedBackMessage.bad'),
						3: this.$t('feedBackMessage.average'),
						4: this.$t('feedBackMessage.good'),
						5: this.$t('feedBackMessage.lovedIt')
					},
					impressions: [],
					remarks: ''
				}
			}
		},
		computed: {
			locale () {
				return this.$store.state.locale
			},
			themeColors () {
				return this.$store.state.themeColors
			},
			merchant () {
				return this.$store.state.merchant
			},
			user () {
				return this.$store.state.user
			},
			processing: {
				get () {
					return this.$store.state.processing
				},
				set (value) {
					this.$store.commit('setState', { key: 'processing', value })
				}
			},
			cart: {
				get () {
					return this.$store.state.cart
				},
				set (value) {
					this.$store.commit('setState', { key: 'cart', value })
				}
			},
			isOrderStoreActive () {
				return this.order ? this.order.store_details.available : false
			},
			validateCancellation () {
				let validation = false

				if (this.orderCancellation.selectedReason && (
					this.orderCancellation.selectedReason.slug !== 'other' || this.orderCancellation.otherReason.length >= 16
				)) {
					validation = true
				}

				return validation
			}
		},
		beforeMount () {
			this.getOrderDetails()
		},
		mounted () {
			if (this.merchant.settings.integrations.freshchat.active &&
				this.merchant.settings.integrations.freshchat.token) {
				if (!window.fcWidget) {
					this.loadScript('https://wchat.freshchat.com/js/widget.js', () => {
						this.freshChatLoaded = true
					})
				} else {
					this.freshChatLoaded = true
				}
			}
		},
		destroyed () {
			if (window.fcWidget) {
				window.fcWidget.destroy()
			}
		},
		methods: {
			getOrderDetails () {
				this.$store.dispatch('getOrderDetails', this.$f7route.params.id).then((response) => {
					this.order = response.data.orders

					return this.$store.dispatch('getStoreDetails', this.order.store_details.id)
				}).then((response) => {
					this.order.store_details = response.data.stores
				}).catch(this.showErrors).finally(() => {
					this.loading = false
					setTimeout(() => {
						this.$f7.tooltip.create({
							targetEl: '.store-info',
							trigger: 'click',
							text: `<p class="text-capitalize m-0">
								${this.isOrderStoreActive ? this.$t('openNow') : this.$t('closedNow')}
							</p><p class="m-0">
								${this.storeClosingTime(this.order.store_details, this.locale)}
							</p><p class="m-0">
								${this.storeNextOpeningTime(this.order.store_details, this.locale)}
							</p>`
						})
					}, 100)
				})
			},
			setImpression (impression) {
				const index = this.feedback.impressions.indexOf(impression)

				if (index !== -1) {
					this.feedback.impressions.splice(index, 1)
				} else {
					this.feedback.impressions.push(impression)
				}
			},
			submitFeedback () {
				this.processing = true
				this.$store.dispatch('submitFeedback', {
					order_id: this.order.id,
					rating: this.feedback.rating,
					feedback: {
						impressions: this.feedback.impressions,
						remarks: this.feedback.remarks
					}
				}).then(() => {
					this.processing = false
					this.feedback.show = false
					this.refresh()
				}).catch((err) => {
					this.processing = false
					this.showErrors(err)
				})
			},
			reorder () {
				this.processing = true
				this.$store.dispatch('resetCart')
				this.$store.dispatch('getItems', {
					item_ids: this.order.items.map(i => i.item_id),
					store_id: this.order.store_details.id
				}).then((response) => {
					let toastMsg = null

					if (response.data.items.length) {
						this.cart.store = this.order.store_details
						this.order.items.forEach((orderItem) => {
							const item = response.data.items.find(i => i.variations[0].id === orderItem.item_id)

							if (item) {
								const variation = item.variations[0]
								const isStockAdjusted = variation.track_inventory && variation.stock > 0 && orderItem.qty > variation.stock

								variation.quantity = isStockAdjusted ? variation.stock : orderItem.qty
								variation.selected_modifiers = orderItem.modifiers || []
								variation.notes = orderItem.custom_attributes.notes || ''
								variation.stock_adjusted = isStockAdjusted
								this.cart.items.push(item)
							}
						})

						this.cart = Object.assign({}, this.cart)
						this.$store.dispatch('cartCalculation')
						this.$f7router.navigate('/cart')

						if (this.cart.items.length !== this.order.items.length) {
							toastMsg = this.$t('toastMsg.partialAvailable')
						}
					} else {
						toastMsg = this.$t('toastMsg.notAvailable')
					}

					if (toastMsg) {
						this.$f7.toast.create({
							text: toastMsg,
							closeTimeout: 3000
						}).open()
					}
				}).catch(this.showErrors).finally(() => {
					this.processing = false
				})
			},
			downloadInvoice () {
				if (this.$bridge.name === 'WEB') {
					window.open(`/api/orders/${this.order.pos_order_id}/invoice`)
				} else {
					const data = {
						url: `${
							process.env.SERVICES_API_DOMAIN
						}/utils/generate/customer-order-invoice?order_id=${
							this.order.pos_order_id
						}`,
						headers: {
							'x-api-key': process.env.SERVICES_API_KEY,
							'x-api-merchant': this.merchant.id
						}
					}

					this.$bridge.download(this.$bridge.name === 'ANDROID' ? JSON.stringify(data) : data)
				}
			},
			getOrderCancelReasons () {
				this.processing = true
				this.$store.dispatch('getMaster', {
					master_type: 'customer_cancellation_reason'
				}).then((response) => {
					this.orderCancellation.reasons = response.data.masters
				}).catch(this.showErrors).finally(() => {
					this.processing = false
				})
			},
			cancelOrder () {
				if (this.validateCancellation) {
					this.processing = true
					this.$store.dispatch('updateOrderStatus', {
						id: this.order.id,
						order_status: 'cancelled',
						message: this.orderCancellation.selectedReason.slug === 'other'
							? this.orderCancellation.otherReason
							: this.orderCancellation.selectedReason.name
					}).then(() => {
						setTimeout(this.refresh, 500)
					}).catch(this.showErrors).finally(() => {
						this.processing = false
						this.orderCancellation.show = false
					})
				}
			},
			resetCancellation () {
				this.orderCancellation.otherReason = ''
				this.orderCancellation.selectedReason = null
				this.orderCancellation.reasons = []
				this.orderCancellation.show = false
			},
			initFreshChatWidget () {
				this.processing = true

				if (!window.fcWidget.isInitialized()) {
					window.fcWidget.init({
						token: this.merchant.settings.integrations.freshchat.token,
						host: this.merchant.settings.integrations.freshchat.host,
						siteId: window.location.hostname,
						externalId: this.user.id,
						restoreId: (
							this.order.custom_attributes && this.order.custom_attributes.external_conversation_id
						) || null,
						open: true,
						config: {
							headerProperty: {
								backgroundColor: this.themeColors[
									this.merchant.settings.app.appearance.theme_color || 'default'
								].hex,
								foregroundColor: 'white',
								hideChatButton: true
							}
						}
					})
				}

				window.fcWidget.user.setProperties({
					firstName: this.user.name,
					orderId: this.order.pos_order_id,
					orderStatus: this.order.order_status
				})

				window.fcWidget.on('widget:opened', () => {
					this.processing = false
				})

				window.fcWidget.on('user:created', (response) => {
					if (response && response.status === 200) {
						if (response && response.data && response.data.restoreId) {
							this.$store.dispatch('patchOrder', {
								id: this.order.id,
								custom_attributes: {
									external_conversation_id: response.data.restoreId
								}
							}).then(() => {
								if (this.order.custom_attributes) {
									this.order.custom_attributes.external_conversation_id = response.data.restoreId
								} else {
									this.order.custom_attributes = {
										external_conversation_id: response.data.restoreId
									}
								}
							}).catch(() => {})
						}
					}
				})

				window.fcWidget.open({ name: 'Support' })
			}
		}
	}
</script>

<style lang="scss">
	#order-detail {
		#items li {
			white-space: normal;

			p {
				line-height: 1.2;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}
		}
	}

	#feedback {
		.page-content {
			padding-bottom: 48px;
		}
	}
</style>
