<template>
	<f7-page
		id="payments"
		ptr
		:ptr-distance="60"
		@ptr:refresh="refresh"
	>
		<f7-navbar
			:title="$options.filters.capitalize($t('payment'))"
			:subtitle="`${cart.items.length } ${ $options.filters.capitalize($tc('item', cart.items.length > 1 ? 2 : 1)) },
				${$options.filters.capitalize($t('toPay'))}: ${
				toCurrency(cart.price.total)
			}`"
			:back-link="$t('back')"
			back-link-force
		/>
		<f7-block-title v-if="loading || paymentMethods.length" class="text-capitalize">
			{{ $tc('payment method', 2) }}
		</f7-block-title>
		<f7-list v-if="loading" class="skeleton-text skeleton-effect-blink mb-0">
			<f7-list-item v-for="i in 4" :key="`payment-method-${i}`" title="Payment Method" link="#" />
		</f7-list>
		<f7-list v-else-if="paymentMethods.length" class="mb-0">
			<f7-list-item
				v-for="method in paymentMethods"
				:key="method.id"
				:title="method.name"
				link="#"
				:disabled="!validateConditions(cart.price, method.conditions)"
				@click="initPayment(method.slug)"
			>
				<div v-if="!validateConditions(cart.price, method.conditions)" slot="footer">
					<p
						v-for="(condition, i) in method.conditions.total"
						:key="i"
						class="m-0 text-color-red"
					>
						<span v-if="condition.operator === '>=' && condition.value >= cart.price.total">
							{{ $t('minOrderValue') }} {{ toCurrency(condition.value) }}
						</span>
						<span v-else-if="condition.operator === '<=' && condition.value <= cart.price.total">
							{{ $t('maxOrderValue') }} {{ toCurrency(condition.value) }}
						</span>
					</p>
				</div>
			</f7-list-item>
		</f7-list>
		<div
			v-else
			class="h-100 display-flex justify-content-center align-items-center"
			style="flex-direction: column"
		>
			<img src="/images/payment-methods.svg" height="200px">
			<h2>
				{{ $t('noPaymentMethod') }}
			</h2>
		</div>
	</f7-page>
</template>

<script>
	export default {
		data () {
			return {
				loading: true,
				paymentMethods: []
			}
		},
		computed: {
			locale () {
				return this.$store.state.locale
			},
			merchant () {
				return this.$store.state.merchant
			},
			user () {
				return this.$store.state.user
			},
			processing: {
				get () {
					return this.$store.state.processing
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'processing',
						value
					})
				}
			},
			cart () {
				return this.$store.state.cart
			},
			deliveryAddress () {
				return this.$store.state.deliveryAddress
			},
			paymentMethod: {
				get () {
					return this.$store.state.paymentMethod
				},
				set (value) {
					this.$store.commit('setState', { key: 'paymentMethod', value })
				}
			}
		},
		mounted () {
			this.getPaymentMethods()
		},
		methods: {
			getPaymentMethods () {
				return this.$store.dispatch('getPaymentMethods').then((response) => {
					if (response.code === 200) {
						this.paymentMethods = response.data.payment_methods
					}

					this.loading = false
				}).catch((err) => {
					this.showErrors(err)
					this.loading = false
				})
			},
			initPayment (slug) {
				const promise = this.deliveryAddress
					? this.$store.dispatch('checkDeliveryAvailability')
					: Promise.resolve({ code: 200 })

				this.processing = true
				this.paymentMethod = slug
				promise.then((response) => {
					if (response.code === 200) {
						return this.$store.dispatch('completeOrder')
					}

					const err = new Error('Delivery unavailable')

					err.response = response

					return Promise.reject(err)
				}).then((response) => {
					this.processing = false

					if (response.code === 201) {
						switch (slug) {
							case 'cod':
								this.$f7router.navigate(`/orders/${response.data.orders.id}`, {
									clearPreviousHistory: true
								})

								this.$store.dispatch('resetCart')
								break
							default:
								this.$f7router.navigate(`/payments/pg?order_id=${response.data.orders.id}`)
						}
					}
				}).catch((err) => {
					this.processing = false
					this.showErrors(err)
				})
			}
		}
	}
</script>
