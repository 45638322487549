<template>
	<div
		:class="{
			'item-card': true,
			'h-100': !loading && items.length === 0 && $f7route.name !== 'cart'
		}"
	>
		<div v-if="loading">
			<f7-card v-for="i in 3" :key="i" class="skeleton-text skeleton-effect-blink border m-3">
				<f7-card-content class="p-0">
					<f7-row no-gap>
						<f7-col width="30">
							<f7-skeleton-block style="height: 88px" />
						</f7-col>
						<f7-col width="70">
							<div class="position-relative" style="height: 88px">
								<div class="display-flex justify-content-space-between align-items-center p-2">
									<div style="line-height: 1">
										<h4 class="text-capitalize m-0">
											xxxxx xxxxx xxxxx
										</h4>
									</div>
									<span>Icon</span>
								</div>
								<f7-row
									class="position-absolute align-items-center p-2"
									style="left: 0; right: 0; bottom: 0"
									no-gap
								>
									<f7-col width="60">
										<p class="font-weight-bold m-0" style="bottom: 5px">
											xxxxx
										</p>
									</f7-col>
									<f7-col
										width="40"
										class="display-flex justify-content-flex-end"
									>
										xxxxx xxxxx
									</f7-col>
								</f7-row>
							</div>
						</f7-col>
					</f7-row>
				</f7-card-content>
			</f7-card>
		</div>
		<div v-else-if="items.length">
			<f7-card
				v-for="(item, index) in items"
				:key="`${item.variations[item.variation_index].id}-${index}`"
				class="elevation-4 m-3"
				@click.native="showItemDetails(item)"
			>
				<f7-card-content class="p-0">
					<ribbon
						v-if="item.variations[item.variation_index].mrp > item.variations[item.variation_index].price"
						class="text-uppercase"
						:text="`${merchant.settings.app.appearance && merchant.settings.app.appearance.discount_banner_type === 'percentage' ?
							`${((item.variations[item.variation_index].mrp - item.variations[item.variation_index].price) * 100 / item.variations[item.variation_index].mrp).toFixed(2)}%` :
							toCurrency(item.variations[item.variation_index].mrp - item.variations[item.variation_index].price)
						} ${$t('off')}`"
					/>
					<f7-row no-gap style="align-items: inherit">
						<f7-col width="30">
							<div
								:data-background="item.variations[item.variation_index].images[0] || '/images/placeholder.svg'"
								class="h-100 lazy lazy-fade-in"
								:style="{
									backgroundPosition: 'center',
									backgroundRepeat: 'no-repeat',
									borderBottomLeftRadius: '4px',
									backgroundSize: 'cover',
									borderTopLeftRadius: item.variations[item.variation_index].discount && item.variations[item.variation_index].discount.id ? '0px' : '4px'
								}"
							/>
						</f7-col>
						<f7-col width="70">
							<div :class="`px-2 pb-2 pt-${item.variations[item.variation_index].discount && item.variations[item.variation_index].discount.id ? 4 : 2}`">
								<div class="display-flex justify-content-space-between align-items-center mb-1">
									<div style="line-height: 1.2">
										<p class="text-capitalize m-0">
											{{
												locale === 'en'
													? ($f7router.currentRoute.name === 'cart'
														? item.variations[item.variation_index].name
														: (
															item.item_attributes.length
																? item.variations[item.variation_index].item_attributes.length
																	? item.name
																	: item.variations[item.variation_index].name
																: item.variations[item.variation_index].name
														)
													)
													: (
														($f7router.currentRoute.name === 'cart'
															? item.variations[item.variation_index].alt_lang
															: (
																item.item_attributes.length
																	? item.variations[item.variation_index].item_attributes.length
																		? item.alt_lang || item.name
																		: item.variations[item.variation_index].alt_lang || item.variations[item.variation_index].name
																	: item.variations[item.variation_index].alt_lang || item.variations[item.variation_index].name
															)
														) || item.name
													)
											}}
										</p>
										<small
											v-if="item.variations[item.variation_index].combos.length"
											class="display-block text-capitalize"
										>
											{{ item.variations[item.variation_index].combos[0].name }}: {{ item.variations[item.variation_index].combos[0].items.map(i => i.name).join(', ') }}
										</small>
										<small
											v-if="$f7router.currentRoute.name === 'cart' && item.variations[item.variation_index].selected_modifiers &&
												item.variations[item.variation_index].selected_modifiers.length"
											class="display-block"
										>
											<span class="text-capitalize">{{ $t('with') }}</span> {{ item.variations[item.variation_index].selected_modifiers.map(m => m.item_name).join(', ') }}
										</small>
										<small v-if="item.variations[item.variation_index].stock_adjusted" class="text-color-red text-capitalize">
											{{ $t('stock changed') }}
											<f7-icon f7="exclamationmark_circle_fill" color="red" size="15px" />
										</small>
									</div>
									<f7-link
										v-if="$f7router.currentRoute.name === 'cart'"
										icon-f7="ellipsis_vertical"
										icon-size="20px"
										@click="setSelectedCartItemIndex(index, $event)"
									/>
									<f7-link
										v-if="$f7router.currentRoute.name !== 'cart'"
										:icon-f7="item.variations[item.variation_index].favorite ? 'heart_fill' : 'heart'"
										:color="item.variations[item.variation_index].favorite ? 'red' : 'primary'"
										@click.stop="favorite(item.variations[item.variation_index])"
									/>
								</div>
								<f7-list v-if="$f7router.currentRoute.name !== 'cart' && item.variations[item.variation_index].item_attributes.length" class="attributes border">
									<f7-list-item
										:title="item.selected_attributes.map(a => a.label).join(', ')"
										link="#"
										@click.native.stop="showAttributes(item)"
									/>
								</f7-list>
								<f7-row class="align-items-center mt-2" no-gap>
									<f7-col width="60">
										<p class="m-0">
											<span class="font-weight-bold">
												{{ toCurrency((item.variations[item.variation_index].price) / (item.variations[item.variation_index].unit_measure_type === 'weight' && merchant.settings.general.quantity_increment_value === '0.5' ? 2 : 1)) }}
												<span v-if="item.variations[item.variation_index].unit_measure_type === 'weight' && merchant.settings.general.quantity_increment_value">{{ `/ ${merchant.settings.general.quantity_increment_value} kg` }}</span>
											</span>
											<del v-if="item.variations[item.variation_index].mrp > item.variations[item.variation_index].price">
												<small>
													{{ toCurrency(item.variations[item.variation_index].mrp) }}
												</small>
											</del>
										</p>
									</f7-col>
									<f7-col width="40" style="line-height: 1">
										<div class="display-flex justify-content-flex-end">
											<cart-button :item="JSON.parse(JSON.stringify(item))" />
										</div>
										<div
											v-if="$f7router.currentRoute.name !== 'cart' && item.variations[item.variation_index].modifiers && item.variations[item.variation_index].modifiers.length"
											class="text-align-center"
										>
											<small class="text-color-gray text-capitalize">{{ $t('customisable') }}</small>
										</div>
									</f7-col>
								</f7-row>
							</div>
						</f7-col>
					</f7-row>
				</f7-card-content>
			</f7-card>
		</div>
		<div
			v-else-if="$f7route.name !== 'cart'"
			class="h-100 display-flex justify-content-center align-items-center"
			style="flex-direction: column"
		>
			<img src="/images/no-result.svg" height="200px">
			<h2 class="text-capitalize">
				{{ $t('noItems') }}
			</h2>
		</div>
		<f7-popover ref="popover" class="popover-menu" @popover:closed="selectedCartItemIndex = null">
			<f7-list>
				<f7-list-item
					link="#"
					popover-close
					:title="$options.filters.capitalize($t('add notes'))"
					no-chevron
					@click="showAddNotes(selectedCartItemIndex)"
				>
					<f7-icon slot="media" f7="pencil" />
				</f7-list-item>
				<f7-list-item
					link="#"
					popover-close
					:title="$options.filters.capitalize($t('remove item'))"
					no-chevron
					@click="removeItem(selectedCartItemIndex)"
				>
					<f7-icon slot="media" f7="trash" />
				</f7-list-item>
			</f7-list>
		</f7-popover>
	</div>
</template>

<script>
	import Ribbon from '~/components/ribbon'
	import CartButton from '~/components/cart-button'

	export default {
		name: 'Items',
		components: {
			Ribbon,
			CartButton
		},
		props: {
			loading: {
				type: Boolean,
				default: false
			},
			items: {
				type: Array,
				required: true
			}
		},
		data () {
			return {
				selectedCartItemIndex: null
			}
		},
		computed: {
			locale () {
				return this.$store.state.locale
			},
			merchant () {
				return this.$store.state.merchant
			},
			user () {
				return this.$store.state.user
			},
			location () {
				return this.$store.state.location
			},
			nearbyStores () {
				return this.$store.state.nearbyStores
			},
			cart: {
				get () {
					return this.$store.state.cart
				},
				set (value) {
					this.$store.commit('setState', { key: 'cart', value })
				}
			},
			selectedStore () {
				return this.$store.state.selectedStore
			}
		},
		methods: {
			removeItem (index) {
				this.cart.items.splice(index, 1)
				this.cart = Object.assign({}, this.cart)
				this.$store.dispatch('cartCalculation')
			},
			showItemDetails (item) {
				if (!['cart', 'items-id', 'stores-id'].includes(this.$f7router.currentRoute.name)) {
					this.$store.commit('setNavigationProps', { item })
					this.$f7router.navigate(`/items/${item.variations[item.variation_index].id}`)
				}
			},
			showAttributes (item) {
				this.$store.commit('setState', { key: 'selectedItem', value: item })
				this.$root.$emit('showAttributes')
			},
			setSelectedCartItemIndex (index, $event) {
				this.selectedCartItemIndex = index
				this.$refs.popover.open($event.target)
			},
			showAddNotes (index) {
				this.$root.$emit('showNotes', index)
			}
		}
	}
</script>

<style lang="scss">
	.item-card {
		.stepper-button-minus, .stepper-button-plus {
			width: 30px;
		}

		.stepper-button-plus:after, .stepper-button-minus:after {
			width: 10px;
			height: 2px;
		}

		.stepper-button-plus:before {
			height: 10px;
			width: 2px;
		}

		.stepper .stepper-input-wrap input {
			width: 30px;
		}
	}
</style>
