<template>
	<f7-page
		id="categories"
		infinite
		:infinite-distance="1"
		:infinite-preloader="categories.pagination && categories.pagination.current_page < categories.pagination.next_page"
		@infinite="loadMoreResults('categories', 'getCategories')"
	>
		<f7-navbar :title="$t('all categories')" inner-class="text-capitalize" back-link />
		<categories :loading="loading" :categories="categories" class="p-3" />
	</f7-page>
</template>

<script>
	import Categories from '@/components/categories'

	export default {
		components: {
			Categories
		},
		data () {
			return {
				loading: true,
				categories: {
					data: [],
					pagination: null
				}
			}
		},
		computed: {
			filters: {
				get () {
					return this.$store.state.filters
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'filters',
						value
					})
				}
			},
			allowLoadMore: {
				get () {
					return this.$store.state.allowLoadMore
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'allowLoadMore',
						value
					})
				}
			}
		},
		mounted () {
			this.getCategories()
		},
		methods: {
			getCategories (filters = {}) {
				filters = Object.assign({
					active: true
				}, filters)

				return this.$store.dispatch('getCategories', filters).then((response) => {
					if (response.code === 200) {
						this.categories.data = filters.page
							? this.categories.data.concat(response.data.categories)
							: response.data.categories
						this.categories.pagination = response.data.pagination
					}
				}).catch(this.showErrors).finally(() => {
					this.loading = false
					this.$nextTick(() => this.$f7.lazy.create(this.$$('#categories')[0]))
				})
			}
		}
	}
</script>
