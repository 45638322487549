<template>
	<f7-page id="edit-profile">
		<f7-navbar :title="$t('edit profile')" inner-class="text-capitalize" :back-link="$t('back')" />
		<div class="display-flex justify-content-center mt-3 mb-3">
			<div
				class="display-flex align-items-center justify-content-center bg-color-primary"
				:style="{
					height: '100px',
					width: '100px',
					borderRadius: '50%',
					fontSize: '50px',
					color: 'white'
				}"
			>
				{{ user.name[0] }}
			</div>
		</div>
		<h2 class="text-align-center mt-0 mb-3">
			{{ user.name }}
		</h2>
		<ValidationObserver ref="validator">
			<ValidationProvider vid="name" :name="$t('name')" rules="required|alpha_spaces">
				<input v-model="name" type="hidden">
			</ValidationProvider>
			<ValidationProvider vid="gender" :name="$t('gender')" rules="required">
				<input v-model="gender" type="hidden">
			</ValidationProvider>
			<ValidationProvider vid="birthday" :name="$t('birthday')" rules="required">
				<input v-model="dob" type="hidden">
			</ValidationProvider>
		</ValidationObserver>
		<f7-list no-hairlines-md class="m-0">
			<f7-list-input
				:label="`${$options.filters.capitalize($t('name'))} *`"
				type="text"
				:placeholder="$options.filters.capitalize($t('name'))"
				clear-button
				:value="name"
				:error-message="$refs.validator && $refs.validator.errors.name && $refs.validator.errors.name.length ? $refs.validator.errors.name[0].replace('{field}', 'name') : ''"
				:error-message-force="$refs.validator && $refs.validator.errors.name ? $refs.validator.errors.name.length > 0 : false"
				@input="name = $event.target.value"
			>
				<f7-icon slot="media" material="person" />
			</f7-list-input>
			<f7-list-input
				:label="`${$options.filters.capitalize($t('gender'))} *`"
				type="select"
				:value="gender"
				:error-message="$refs.validator && $refs.validator.errors.gender && $refs.validator.errors.gender.length ? $refs.validator.errors.gender[0].replace('{field}', 'gender') : ''"
				:error-message-force="$refs.validator && $refs.validator.errors.gender ? $refs.validator.errors.gender.length > 0 : false"
				@input="gender = $event.target.value"
			>
				<f7-icon slot="media" material="people" />
				<option value="">
					{{ $t('selectGender') }}
				</option>
				<option value="male">
					{{ $t('male') | capitalize }}
				</option>
				<option value="female">
					{{ $t('female') | capitalize }}
				</option>
			</f7-list-input>
			<f7-list-input
				:label="`${$options.filters.capitalize($t('birthday'))} *`"
				type="datepicker"
				:placeholder="$options.filters.capitalize($t('birthday'))"
				readonly
				:calendar-params="{
					header: true,
					footer: true,
					dateFormat: 'dd M yyyy',
					value: dob ? [new Date(dob)] : [],
					minDate: minDate,
					maxDate: maxDate,
					toolbarCloseText: $t('done'),
					locale: locale
				}"
				:error-message="$refs.validator && $refs.validator.errors.birthday && $refs.validator.errors.birthday.length ? $refs.validator.errors.birthday[0].replace('{field}', 'birthday') : ''"
				:error-message-force="$refs.validator && $refs.validator.errors.birthday ? $refs.validator.errors.birthday.length > 0 : false"
				@calendar:change="dob = $event.length ? $moment($event[0]).format('YYYY-MM-DD') : null"
			>
				<f7-icon slot="media" material="cake" />
			</f7-list-input>
		</f7-list>
		<f7-button
			large
			fill
			style="position: fixed; left: 0; right: 0; bottom: 0; border-radius: unset"
			:disabled="processing"
			@click="editProfile"
		>
			{{ $t('save') }}
		</f7-button>
	</f7-page>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from 'vee-validate'

	export default {
		components: {
			ValidationObserver,
			ValidationProvider
		},
		data () {
			return {
				name: '',
				gender: '',
				dob: '',
				minDate: new Date(),
				maxDate: new Date()
			}
		},
		computed: {
			locale () {
				return this.$store.state.locale
			},
			user () {
				return this.$store.state.user
			},
			processing: {
				get () {
					return this.$store.state.processing
				},
				set (value) {
					this.$store.commit('setState', { key: 'processing', value })
				}
			}
		},
		beforeMount () {
			this.name = this.user.name || ''
			this.gender = this.user.gender || ''
			this.dob = this.user.dob || ''
			this.minDate = this.minDate.setFullYear(this.minDate.getFullYear() - 100)
			this.maxDate = this.maxDate.setFullYear(this.maxDate.getFullYear() - 18)
		},
		methods: {
			async editProfile () {
				if (await this.$refs.validator.validate()) {
					this.processing = true
					this.$store.dispatch('updateProfile', {
						name: this.name,
						gender: this.gender,
						dob: this.dob
					}).then((response) => {
						if (response.code === 200) {
							this.$f7router.back()
							this.$f7.toast.create({
								text: this.$t('toastMsg.profileUpdateSuccess'),
								closeTimeout: 3000
							}).open()
						}

						this.processing = false
					}).catch((err) => {
						this.showErrors(err)
						this.processing = false
					})
				}
			}
		}
	}
</script>

<style lang="scss">
	#edit-profile {
		.page-content {
			padding-bottom: 48px;
		}
	}
</style>
