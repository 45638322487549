<template>
	<f7-app
		:params="f7Params"
		:class="{
			[`color-theme-${merchant ? merchant.settings.app.appearance.theme_color : 'default'}`]: true,
			'theme-dark': merchant ? merchant.settings.app.appearance.use_dark_theme : false
		}"
	>
		<f7-view main :url="mainUrl" />
		<f7-routable-modals />
		<attributes :show="showAttributes" @close="showAttributes = false" />
		<modifiers :show="showModifiers" @close="showModifiers = false" />
		<otp-verification />
		<f7-actions
			id="app-banner"
			ref="appBanner"
			:opened="showAppBanner"
			@actions:closed="showAppBanner = false"
		>
			<div class="page-bg-color p-3">
				<f7-row class="align-items-center mb-3">
					<f7-col width="30">
						<div class="bg-color-white elevation-4 p-2" style="width: 80px; height: 80px; border-radius: 20%">
							<img :src="merchant.settings.general.logo_img_url" :alt="merchant.business.brand_name" style="max-height:100%; max-width:100%">
						</div>
					</f7-col>
					<f7-col width="70">
						<h2 class="mt-0 mb-2">
							{{ $t('appBanner.title') }}
						</h2>
						<p class="m-0">
							{{ $t('appBanner.text', [merchant.business.brand_name]) }}
						</p>
					</f7-col>
				</f7-row>
				<f7-button raised fill class="mb-2" @click="openAppStore">
					{{ $t('appBanner.button1') }}
				</f7-button>
				<f7-button @click="() => $refs.appBanner.close()">
					{{ $t('appBanner.button2') }}
				</f7-button>
			</div>
		</f7-actions>
	</f7-app>
</template>

<script>
	import Modifiers from '~/components/modifiers'
	import Attributes from '~/components/attributes.vue'
	import OtpVerification from '~/components/otp-verification'

	export default {
		components: {
			Modifiers,
			Attributes,
			OtpVerification
		},
		asyncData ({ $axios, store }) {
			return $axios.$get('/api/guest/settings').then((response) => {
				if (response.code === 200) {
					if (response.data.settings.app.appearance.theme_color && [
						'#fff', '#ffffff'
					].includes(response.data.settings.app.appearance.theme_color.toLowerCase())) {
						response.data.settings.app.appearance.theme_color = 'black'
						response.data.settings.app.appearance.fill_navigation_bar = false
					}

					store.commit('setState', {
						key: 'merchant',
						value: response.data
					})

					if (!store.state.location && response.data.settings.general.default_user_location &&
						Object.values(response.data.settings.general.default_user_location).filter(Number).length === 2) {
						store.commit('setLocation', {
							line: response.data.settings.general.default_user_location.name,
							coordinates: [
								response.data.settings.general.default_user_location.lng,
								response.data.settings.general.default_user_location.lat
							]
						})
					}
				}
			}).catch(() => {})
		},
		data () {
			return {
				f7Params: {
					id: 'com.posbytz.store',
					name: 'POSBytz Store',
					routes: this.$routes
				},
				mainUrl: '/intro',
				showAttributes: false,
				showModifiers: false,
				showAppBanner: false
			}
		},
		head () {
			let metas = []
			const styles = []

			if (this.merchant) {
				if (this.merchant.settings.app.appearance.theme_color && !this.themeColors[
					this.merchant.settings.app.appearance.theme_color
				]) {
					const colorThemeCSSProperties = this.colorThemeCSSProperties(
						this.merchant.settings.app.appearance.theme_color
					)

					if (Object.keys(colorThemeCSSProperties).length) {
						const rgbArr = colorThemeCSSProperties['--f7-theme-color-rgb'].split(', ')

						this.themeColors[
							this.merchant.settings.app.appearance.theme_color
						] = {
							r: rgbArr[0],
							g: rgbArr[1],
							b: rgbArr[2],
							hex: this.merchant.settings.app.appearance.theme_color
						}

						styles.push({
							cssText: `
							/* Custom color theme */
							:root {
								--f7-theme-color: ${colorThemeCSSProperties['--f7-theme-color']};
								--f7-theme-color-rgb: ${colorThemeCSSProperties['--f7-theme-color-rgb']};
								--f7-theme-color-shade: ${colorThemeCSSProperties['--f7-theme-color-shade']};
								--f7-theme-color-tint: ${colorThemeCSSProperties['--f7-theme-color-tint']};
							}`,
							type: 'text/css'
						})
					}
				}

				if (this.merchant.settings.app.appearance.fill_navigation_bar && this.themeColors[
					this.merchant.settings.app.appearance.theme_color
				]) {
					document.documentElement.style.setProperty('--f7-dialog-button-text-color', this.themeColors[
						this.merchant.settings.app.appearance.theme_color
					].hex)

					styles.push({
						cssText: `
						/* Invert navigation bars to fill style */
						:root,
						:root.theme-dark,
						:root .theme-dark {
							--f7-bars-bg-color: ${this.themeColors[this.merchant.settings.app.appearance.theme_color].hex};
							--f7-bars-bg-color-rgb: ${this.themeColors[this.merchant.settings.app.appearance.theme_color].r},
								${this.themeColors[this.merchant.settings.app.appearance.theme_color].g},
								${this.themeColors[this.merchant.settings.app.appearance.theme_color].b};
							--f7-bars-translucent-opacity: 0.9;
							--f7-bars-text-color: #fff;
							--f7-bars-link-color: #fff;
							--f7-navbar-subtitle-text-color: rgba(255,255,255,0.85);
							--f7-bars-border-color: transparent;
							--f7-tabbar-link-active-color: #fff;
							--f7-tabbar-link-inactive-color: rgba(255,255,255,0.54);
							--f7-sheet-border-color: transparent;
							--f7-tabbar-link-active-border-color: #fff;
						}
						.appbar,
						.navbar,
						.toolbar,
						.subnavbar,
						.calendar-header,
						.calendar-footer {
							--f7-touch-ripple-color: var(--f7-touch-ripple-white);
							--f7-link-highlight-color: var(--f7-link-highlight-white);
							--f7-button-text-color: #fff;
							--f7-button-pressed-bg-color: rgba(255,255,255,0.1);
						}
						.navbar-large-transparent,
						.navbar-large.navbar-transparent {
							--f7-navbar-large-title-text-color: #000;

							--r: ${this.themeColors[this.merchant.settings.app.appearance.theme_color].r};
							--g: ${this.themeColors[this.merchant.settings.app.appearance.theme_color].g};
							--b: ${this.themeColors[this.merchant.settings.app.appearance.theme_color].b};
							--progress: var(--f7-navbar-large-collapse-progress);
							--f7-bars-link-color: rgb(
								calc(var(--r) + (255 - var(--r)) * var(--progress)),
								calc(var(--g) + (255 - var(--g)) * var(--progress)),
								calc(var(--b) + (255 - var(--b)) * var(--progress))
							);
						}
						.theme-dark .navbar-large-transparent,
						.theme-dark .navbar-large.navbar-transparent {
							--f7-navbar-large-title-text-color: #fff;
						}`,
						type: 'text/css'
					})
				}

				metas = [
					{ hid: 'apple-mobile-web-app-title', name: 'apple-mobile-web-app-title', content: `${this.merchant.business.name}` },
					{ hid: 'description', name: 'description', content: `${this.merchant.business.name} hybrid app` },
					{ hid: 'og:title', name: 'og:title', content: `${this.merchant.business.name}` },
					{ hid: 'og:site_name', name: 'og:site_name', content: `${this.merchant.business.name}` },
					{ hid: 'og:description', name: 'og:description', content: `${this.merchant.business.name} hybrid app` }
				]
				this.merchant.settings.general.store_meta?.forEach((meta) => {
					metas.push({
						hid: meta.name,
						name: meta.name,
						content: meta.content
					})
				})
			}

			return {
				title: this.merchant.business.name,
				meta: metas,
				style: styles
			}
		},
		computed: {
			processing: {
				get () {
					return this.$store.state.processing
				},
				set (value) {
					this.$store.commit('setState', { key: 'processing', value })
				}
			},
			firebaseToken: {
				get () {
					return this.$store.state.firebaseToken
				},
				set (value) {
					this.$store.commit('setState', { key: 'firebaseToken', value })
				}
			},
			themeColors () {
				return this.$store.state.themeColors
			},
			merchant () {
				return this.$store.state.merchant
			},
			appliedCoupon () {
				return this.$store.state.appliedCoupon
			},
			user () {
				return this.$store.state.user
			},
			location () {
				return this.$store.state.location
			},
			selectedStore () {
				return this.$store.state.selectedStore
			},
			cart: {
				get () {
					return this.$store.state.cart
				},
				set (value) {
					this.$store.commit('setState', { key: 'cart', value })
				}
			},
			redirectSlug () {
				return this.$store.state.redirectSlug
			}
		},
		watch: {
			cart (value) {
				this.$store.dispatch('updateSession', {
					key: 'cart',
					value
				})
			},
			selectedStore (value) {
				if (value) {
					this.$store.dispatch('updateSession', {
						key: 'selectedStore',
						value
					})
				}
			},
			processing (value) {
				this.$f7.preloader[value ? 'show' : 'hide']()
			}
		},
		beforeMount () {
			this.$store.dispatch('setSentryScope')

			if (!this.merchant) {
				this.mainUrl = '/oops?type=not-found'
			} else if (this.user || this.location) {
				this.mainUrl = `/${this.merchant.settings.app.appearance.default_tab === 'explore' ? 'items' : 'home'}`
			}
		},
		mounted () {
			if (!this.isCookieEnabled()) {
				return this.$f7.dialog.create({
					title: this.$t('cookies required'),
					text: this.$t('cookiesNotEnabled'),
					buttons: [{
						text: this.$options.filters.capitalize(this.$t('retry')),
						close: false,
						onClick: this.reload
					}]
				}).open()
			}

			if (typeof this.$bridge.getFirebaseToken === 'function') {
				window.getFirebaseTokenResponse = (token) => {
					this.firebaseToken = token
				}

				this.$bridge.getFirebaseToken()
			}

			window.back = () => {
				if (!this.$$('.view').hasClass('router-transition')) {
					const backLink = this.$$('.link.back')
					const panel = this.$f7.panel.get()

					if (this.$$('.sheet-modal').length !== 0) {
						return null
					} else if (panel && panel.opened) {
						panel.close()
					} else if (this.$$('.modal-in').length > 0) {
						this.$f7.popup.close()
						this.$f7.dialog.close()
						this.$f7.popover.close()
						this.$f7.actions.close()
					} else if (this.$f7.views.main.router.history.length === 1) {
						this.$bridge.minimize()
					} else if (backLink.length) {
						backLink.eq(0).click()
					} else {
						this.$f7.views.main.router.back()
					}
				}
			}

			window.onpopstate = () => window.back()
			this.$f7.on('touchend:active', this.lazyLoad)
			this.$f7.on('routeChange', (newRoute, prevRoute, router) => {
				router.params.pushState = true
				this.$gtm.push({
					event: 'f7Route',
					pageUrl: newRoute.url,
					routeName: newRoute.name
				})
			})

			this.$f7.on('routeChanged', () => {
				const view = this.$$('.view')

				if (view.hasClass('router-transition')) {
					view.removeClass(
						'router-transition router-transition-forward router-transition-backward'
					)
				}
			})

			this.$root.$on('lazyLoad', this.lazyLoad)
			this.$root.$on('showModifiers', () => { this.showModifiers = true })
			this.$root.$on('showAttributes', () => { this.showAttributes = true })
			this.$root.$on('applyModifiers', this.applyModifiers)
			this.$root.$on('addItem', this.addItem)

			if (this.redirectSlug && this.location) {
				setTimeout(() => {
					this.$f7.views.main.router.navigate(`/items/${this.redirectSlug}`)
					this.$store.commit('setState', { key: 'redirectSlug', value: null })
				}, 500)
			}

			if (((
				this.$f7.device.android && this.merchant.settings.app.app_store_links.google_play_store_url
			) || (
				this.$f7.device.ios && this.merchant.settings.app.app_store_links.apple_app_store_url
			)) && this.$bridge.name === 'WEB') {
				const appBannerLastShown = new Date(this.getLocalStorage('appBannerLastShown'))

				if (this.$moment().diff(appBannerLastShown, 'days') >= 1) {
					setTimeout(() => {
						this.showAppBanner = true
						this.setLocalStorage('appBannerLastShown', new Date().toString())
					}, 2000)
				}
			}
		},
		methods: {
			openAppStore () {
				let url = null

				if (this.$f7.device.android) {
					url = `https://play.app.goo.gl/?link=${this.merchant.settings.app.app_store_links.google_play_store_url}`
				} else if (this.$f7.device.ios) {
					url = this.merchant.settings.app.app_store_links.apple_app_store_url.replace('https://', 'itms-apps://')
				}

				if (url) {
					window.location.href = url
				}

				this.$refs.appBanner.close()
			},
			logout () {
				this.$f7.dialog.create({
					title: this.$options.filters.capitalize(this.$t('logout')),
					text: this.$t('logoutConfirm'),
					buttons: [{
							text: this.$options.filters.capitalize(this.$t('cancel')),
							close: true
						},
						{
							text: this.$options.filters.capitalize(this.$t('ok')),
							onClick: () => {
								this.processing = true
								this.$store.dispatch('logout').then(() => {
									setTimeout(() => {
										this.processing = false
										this.$f7.views.main.router.navigate('/login', {
											clearPreviousHistory: true
										})
									}, 2000)
								}).catch((err) => {
									this.showErrors(err)
									this.processing = false
								})
							}
						}
					]
				}).open()
			},
			lazyLoad () {
				clearTimeout(this.timer)
				this.timer = setTimeout(() => {
					this.$$('.lazy').each((i, e) => {
						if (this.$f7.lazy.isInViewport(e)) {
							this.$f7.lazy.loadImage(e)
						}
					})
				}, 200)
			},
			applyModifiers (item) {
				let modifierPrice = 0
				const variation = item.variations[item.variation_index]
				let quantity = variation.unit_measure_type === 'weight' && this.merchant.settings.general.quantity_increment_value ? +this.merchant.settings.general.quantity_increment_value : 1
				const cartItem = this.filterCartItem(this.cart.items, variation)

				if (cartItem) {
					quantity += cartItem.variations[cartItem.variation_index].quantity
				}

				modifierPrice = variation.selected_modifiers
					? variation.selected_modifiers.reduce((sum, m) => {
						sum += m.price

						return sum
					}, 0)
					: 0

				item.variations[item.variation_index].price = variation.original_price + modifierPrice

				this.addItem({ item, quantity })
			},
			checkWeight (quantity) {
				let result = true

				if (this.merchant.settings.order_weight_limit && (
					(this.cart.price.totalQuantity + quantity) > this.merchant.settings.order_weight_limit)
				) {
					result = false
				}

				return result
			},
			addItem ({ item, quantity }) {
				const variation = item.variations[item.variation_index]

				const addItem = () => {
					const cartItemIndex = this.cart.items.findIndex((i) => {
						const cartItemVariation = i.variations[i.variation_index]
						const iSelectedModifiers = cartItemVariation.selected_modifiers || []
						const itemSelectedModifiers = variation.selected_modifiers || []
						const newModifiers = iSelectedModifiers.filter(this.modifierComparer(itemSelectedModifiers))
							.concat(itemSelectedModifiers.filter(this.modifierComparer(iSelectedModifiers)))

						return cartItemVariation.id === variation.id && newModifiers.length === 0
					})

					if (cartItemIndex !== -1) {
						const cartItemVariation = this.cart.items[cartItemIndex].variations[this.cart.items[cartItemIndex].variation_index]

						if (quantity > 0 && this.checkWeight(quantity - cartItemVariation.quantity)) {
							cartItemVariation.quantity = quantity
						} else if (quantity <= 0) {
							cartItemVariation.quantity = 0
							this.cart.items.splice(cartItemIndex, 1)
						} else {
							this.showCartFullAlert()
						}
					} else if (this.checkWeight(quantity)) {
						variation.quantity = quantity
						this.cart.items.push(item)
					} else {
						this.showCartFullAlert()
					}

					this.cart.store = this.cart.items.length ? (this.cart.store || this.selectedStore) : null
					this.cart = Object.assign({}, this.cart)

					if (this.appliedCoupon && this.validateDiscount(this.appliedCoupon, this.cart)) {
						this.$store.dispatch('applyDiscount')
					}

					this.$store.dispatch('cartCalculation')
				}

				if (this.$f7.views.main.router.currentRoute.name !== 'cart' && this.cart.store && this.selectedStore &&
					this.cart.store.id !== this.selectedStore.id) {
					this.$f7.dialog.create({
						title: this.$t('itemsAlreadyInCart'),
						text: this.$t('multipleLocationItems'),
						buttons: [{
								text: this.$options.filters.capitalize(this.$t('cancel')),
								close: true
							},
							{
								text: this.$options.filters.capitalize(this.$t('ok')),
								onClick: () => {
									this.$store.dispatch('resetCart')
									addItem()
								}
							}
						]
					}).open()
				} else {
					addItem()
				}
			}
		}
	}
</script>
