<template>
	<f7-page id="order-tracking" :ptr="true" :ptr-distance="60" @ptr:refresh="refresh">
		<f7-navbar
			:title="`${$tc('order', 1)}# ${$f7route.params.id}`"
			:subtitle="delivery ? `${$t('delivery status')}: ${$t(delivery.state.split('_').join(' '))}` : ''"
			inner-class="text-capitalize"
			:back-link="$t('back')"
			back-link-force
		/>
		<div v-if="loading" class="skeleton-text skeleton-effect-blink">
			<div class="skeleton-block" style="height: 40vh" />
			<div class="timeline">
				<div v-for="i in 3" :key="i" class="timeline-item">
					<div class="timeline-item-date">
						xx xxx
					</div>
					<div class="timeline-item-divider" />
					<div class="timeline-item-content">
						<div class="timeline-item-time">
							xxxxxxx
						</div>
						<div class="timeline-item-title">
							xxxxx
						</div>
						<div class="timeline-item-text">
							xxxxx xxxxx xxxxx xxxxx
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else-if="delivery">
			<google-map
				:map-config="mapConfig"
				:markers="markers"
				:polylines="polylines"
			/>
			<f7-list v-if="!['placed', 'delivered'].includes(delivery.state)" class="my-0">
				<f7-list-item
					no-chevron
					link="#"
					@click="delivery.runner.phone && delivery.runner.phone.number ? $bridge.openDialer(
						delivery.runner.phone.number
					) : null"
				>
					<div slot="header" class="text-capitalize">
						{{ $tc('order', 1) }} {{
							delivery.state === 'at_store' ? $t('preparing') : $t(delivery.state.split('_').join(' '))
						}}
					</div>
					<div v-if="delivery.runner.name" slot="title">
						<span v-if="delivery.state === 'assigned'">{{ $t('deliveryState.assigned', [delivery.runner.name]) }}</span>
						<span v-if="delivery.state === 'at_store'">{{ $t('deliveryState.atStore', [delivery.runner.name]) }}</span>
						<span v-if="delivery.state === 'pickedup'">{{ $t('deliveryState.pickedup', [delivery.runner.name]) }}</span>
					</div>
					<div v-if="delivery.state === 'pickedup'" slot="footer">
						{{ $t('deliveryState.pickedupMessage') }}
					</div>
					<div v-if="delivery.runner.phone && delivery.runner.phone.number" slot="after">
						<f7-icon f7="phone_circle_fill" color="blue" />
					</div>
				</f7-list-item>
			</f7-list>
			<f7-block-title class="mt-3 text-capitalize">
				{{ $t('orderTimeline') }}
			</f7-block-title>
			<div class="timeline">
				<div
					v-for="(history, index) in delivery.status_histories.filter(h => h.visible)"
					:key="index"
					class="timeline-item"
				>
					<div class="timeline-item-date">
						{{ $moment.utc(history.changed_at).local().locale(locale).format('DD') }}
						<small>{{ $moment.utc(history.changed_at).local().locale(locale).format('MMM') }}</small>
					</div>
					<div class="timeline-item-divider" />
					<div class="timeline-item-content">
						<div class="timeline-item-time">
							{{ $moment.utc(history.changed_at).local().locale(locale).format('hh:mm A') }}
						</div>
						<div class="timeline-item-title text-capitalize">
							{{ $t(history.state.split('_').join(' ')) }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			v-else
			class="h-100 display-flex justify-content-center align-items-center"
			style="flex-direction: column"
		>
			<img src="/images/no-result.svg" height="200px">
			<h2 class="mb-0">
				{{ $t('trackingNotFound') }}
			</h2>
			<p class="m-0">
				{{ $t('tryAgainLater') }}
			</p>
		</div>
	</f7-page>
</template>

<script>
	import io from 'socket.io-client'
	import GoogleMap from '~/components/google-map'

	export default {
		components: {
			GoogleMap
		},
		data () {
			return {
				socket: null,
				loading: true,
				mapConfig: {
					draggable: true,
					disableDefaultUI: true,
					mapTypeControl: false,
					zoomControl: true
				},
				markers: [],
				polylines: [],
				delivery: null
			}
		},
		computed: {
			locale () {
				return this.$store.state.locale
			}
		},
		beforeMount () {
			this.socket = io(process.env.BACKEND_DOMAIN)
			this.socket.emit('add-to-room', this.$f7route.params.id)
			this.getOrderDetails()
		},
		mounted () {
			this.socket.on('order', (event) => {
				if (event.type === 'status_change') {
					this.delivery = event.data
					this.updateLocation()
				}
			})
		},
		destroyed () {
			this.socket.disconnect()
		},
		methods: {
			getOrderDetails () {
				this.$store.dispatch('getDeliveryDetails', {
					order_id: this.$f7route.params.id
				}).then((response) => {
					this.delivery = response.data
					this.updateLocation()
				}).catch(this.showErrors).finally(() => {
					this.loading = false
				})
			},
			updateLocation () {
				this.markers = [
					{
						iconConfig: {
							url: '/images/store-marker.svg',
							size: {
								x: 42,
								y: 42
							}
						},
						position: {
							lat: parseFloat(this.delivery.pickup.address.coordinates[1]),
							lng: parseFloat(this.delivery.pickup.address.coordinates[0])
						}
					},
					{
						iconConfig: {
							url: '/images/home-marker.svg',
							size: {
								x: 42,
								y: 42
							}
						},
						position: {
							lat: parseFloat(this.delivery.drop.address.coordinates[1]),
							lng: parseFloat(this.delivery.drop.address.coordinates[0])
						}
					}
				]
				this.polylines = this.markers.map(m => m.position)

				if (this.delivery.runner && this.delivery.runner.current_coordinates && this.delivery.runner.current_coordinates.length) {
					this.markers.push({
						iconConfig: {
							url: '/images/rider-marker.svg',
							size: {
								x: 30,
								y: 30
							},
							anchor: {
								x: 0,
								y: 10
							}
						},
						position: {
							lat: parseFloat(this.delivery.runner.current_coordinates[1]),
							lng: parseFloat(this.delivery.runner.current_coordinates[0])
						}
					})
				}
			}
		}
	}
</script>
