<template>
	<f7-page id="item-details">
		<f7-navbar
			:title="locale === 'en' ? variation.name : (variation.alt_lang || variation.name)"
			:subtitle="selectedStore.name"
			back-link
			back-link-force
		/>
		<f7-photo-browser
			v-if="variation && variation.images.length"
			ref="photos"
			type="popup"
			theme="dark"
			:photos="variation.images"
		/>
		<div
			v-if="variation && variation.mrp > variation.price"
			class="position-absolute text-uppercase bg-color-red text-color-white p-1"
			style="left: 0; border-bottom-right-radius: 10px; z-index: 2"
		>
			{{ merchant.settings.app.appearance && merchant.settings.app.appearance.discount_banner_type === 'percentage' ?
				`${((variation.mrp - variation.price) * 100 / variation.mrp).toFixed(2)}%` :
				toCurrency(variation.mrp - variation.price)
			}} {{ $t('off') }}
		</div>
		<div
			v-if="variation"
			class="display-flex justify-content-center elevation-4"
			style="position: absolute; right: 15px; top: 70px; background-color: white; border-radius: 50%; z-index: 2"
		>
			<div
				class="display-flex align-items-center justify-content-center p-2"
				:style="{
					borderRadius: '50%',
					color: 'white'
				}"
			>
				<f7-link
					:icon-f7="variation.favorite ? 'heart_fill' : 'heart'"
					:icon-color="variation.favorite ? 'red' : 'primary'"
					icon-size="24"
					@click="favorite(variation)"
				/>
			</div>
		</div>
		<f7-swiper
			v-if="loading.item || (variation && variation.images.length)"
			:params="{
				autoplay: {
					delay: 5000
				}
			}"
			:pagination="variation && variation.images.length > 1 ? true : false"
		>
			<f7-swiper-slide
				v-for="(image, index) in loading.item ? [1, 2, 3] : variation.images"
				:key="index"
				:class="{
					'skeleton-text': loading.item,
					'skeleton-effect-blink': loading.item
				}"
				style="height: 250px"
			>
				<f7-skeleton-block v-if="loading.item" style="height: 250px" />
				<img
					v-else
					:src="image"
					:alt="variation.name"
					class="display-block m-auto"
					style="max-height: 100%; max-width: 100%"
					@click="$refs.photos.open(index)"
				>
			</f7-swiper-slide>
		</f7-swiper>
		<img
			v-else
			src="/images/placeholder.svg"
			:alt="variation ? variation.name : ''"
			height="250px"
			class="display-block m-auto"
			style="max-height: 100%; max-width: 100%"
		>
		<f7-block class="my-3">
			<div v-if="loading.item" class="skeleton-text skeleton-effect-blink">
				<h4 class="mt-0 mb-2">
					xxxxx xxxxx xxxxx xxxxx xxxxx xxxxx xxxxx xxxxx xxxxx xxxxx
				</h4>
				<div class="display-flex justify-content-space-between align-items-center">
					<h1 class="my-0">
						xxxxx
					</h1>
					<h2 class="m-0">
						xxxxx xxxxx
					</h2>
				</div>
				<p class="m-0">
					xxxxx xxxxx xxxxx
				</p>
				<p class="m-0">
					xxxxx xxxxx
				</p>
				<p class="m-0">
					xxxxx xxxxx
				</p>
			</div>
			<div v-else-if="variation">
				<h4 class="m-0">
					{{ locale === 'en' ? variation.name : (variation.alt_lang || variation.name) }}
				</h4>
				<p v-if="variation.combos.length" class="text-capitalize m-0">
					{{ variation.combos[0].name }}: {{ variation.combos[0].items.map(i => i.name).join(', ') }}
				</p>
				<f7-list v-if="variation.item_attributes.length" class="attributes border mt-2 mb-0">
					<f7-list-item
						:title="item.selected_attributes.map(a => a.label).join(', ')"
						link="#"
						@click="showAttributes(item)"
					/>
				</f7-list>
				<div class="display-flex justify-content-space-between align-items-center mt-2">
					<div>
						<h1 class="display-inline font-weight-bold">
							{{ toCurrency((variation.price) / (variation.unit_measure_type === 'weight' && merchant.settings.general.quantity_increment_value === '0.5' ? 2 : 1)) }}
							<span v-if="variation.unit_measure_type === 'weight' && merchant.settings.general.quantity_increment_value">
								{{ `/ ${merchant.settings.general.quantity_increment_value} kg` }}
							</span>
						</h1>
						<del v-if="variation.mrp > variation.price">
							<small>
								{{ toCurrency(variation.mrp) }}
							</small>
						</del>
					</div>
					<div class="text-align-center">
						<cart-button :item="item" />
						<small
							v-if="variation.modifiers && variation.modifiers.length"
							class="display-block text-color-gray text-capitalize"
						>{{ $t('customisable') }}</small>
					</div>
				</div>
				<p v-if="merchant.settings.general.catalog.tax_split_up && variation.taxes.length" class="text-capitalize m-0">
					{{ $tc('tax', 1) }}: {{ variation.taxes.map(t => t.name).join(', ') }}
				</p>
				<p v-if="variation.description" class="text-capitalize m-0">
					{{ $t('description') }}: {{ variation.description }}
				</p>
				<div v-if="loading.stores" class="text-align-center mt-4">
					<f7-preloader
						v-observe-visibility="{
							once: true,
							callback: (isVisible) => {
								if (isVisible) {
									getItemStores()
								}
							}
						}"
						color="primary"
					/>
				</div>
				<div v-else-if="itemStores.length && !merchant.settings.app.appearance.home_page.show_nearest_location" class="mt-3">
					<f7-block-header>
						{{ $t('availabilityInOtherStores') }}
					</f7-block-header>
					<f7-card
						v-for="(itemStore, index) in itemStores"
						:key="index"
						class="elevation-4 m-0"
						:class="{
							'mb-3': index < itemStores.length - 1
						}"
						style="line-height: 1"
						@click.native="showStoreDetails(itemStore)"
					>
						<f7-card-content class="p-2">
							<f7-row class="align-items-center" no-gap>
								<f7-col width="20" class="text-align-center">
									<f7-icon f7="placemark" class="mb-1" />
									<small class="display-block font-weight-bold">
										{{ (itemStore.distance).toFixed(2) }} {{ $t('km') }}
									</small>
								</f7-col>
								<f7-col width="80">
									<div class="p-2">
										<f7-row class="align-items-center mb-2" no-gap>
											<f7-col width="70">
												<h4 class="text-capitalize display-inline m-0">
													{{ itemStore.name }}
												</h4>
												<f7-badge
													class="text-capitalize"
													:color="itemStore.available ? 'green': 'red'"
												>
													{{ itemStore.available ? $t('open') : $t('closedNow') }}
												</f7-badge>
											</f7-col>
											<f7-col width="30">
												<f7-badge
													:color="itemStore.item.stock_in ? 'green' : 'red'"
													class="float-right text-capitalize"
												>
													{{ itemStore.item.stock_in ? $t('available') : $t('sold out') }}
												</f7-badge>
											</f7-col>
										</f7-row>
										<p class="text-color-gray text-capitalize m-0">
											{{ itemStore.address.line }}
										</p>
									</div>
								</f7-col>
							</f7-row>
						</f7-card-content>
					</f7-card>
				</div>
			</div>
		</f7-block>
		<cart-toolbar />
	</f7-page>
</template>

<script>
	import CartButton from '~/components/cart-button'
	import CartToolbar from '~/components/cart-toolbar'

	export default {
		components: {
			CartButton,
			CartToolbar
		},
		data () {
			return {
				loading: {
					item: false,
					stores: true
				},
				item: null,
				itemStores: []
			}
		},
		computed: {
			locale () {
				return this.$store.state.locale
			},
			merchant () {
				return this.$store.state.merchant
			},
			location () {
				return this.$store.state.location
			},
			user () {
				return this.$store.state.user
			},
			itemProp () {
				return this.$store.getters.navigationProp('item')
			},
			variation () {
				return this.item ? this.item.variations[this.item.variation_index] : null
			},
			selectedStore: {
				get () {
					return this.$store.state.selectedStore
				},
				set (store) {
					this.$store.commit('setSelectedStore', store)
				}
			},
			processing: {
				get () {
					return this.$store.state.processing
				},
				set (value) {
					this.$store.commit('setState', { key: 'processing', value })
				}
			},
			cartItems () {
				return this.$store.state.cart.items
			}
		},
		watch: {
			cartItems (item) {
				if (!this.filterCartItem(item, this.variation) && this.variation.modifiers.length) {
					this.variation.price = this.variation.original_price
				}
			}
		},
		beforeMount () {
			if (this.itemProp) {
				this.item = this.itemProp
			} else {
				this.getItem()
			}
		},
		destroyed () {
			this.$store.commit('removeNavigationProp', 'item')
		},
		methods: {
			getItem () {
				this.loading.item = true
				this.$store.dispatch('getItems', {
					identifier: this.$f7route.params.id
				}).then((response) => {
					this.item = response.data.items[0] || null
				}).catch((err) => {
					this.$f7router.back()
					this.showErrors(err)
				}).finally(() => {
					this.loading.item = false
					this.$root.$emit('lazyLoad')
				})
			},
			getItemStores () {
				this.$store.dispatch('getItemStores', this.variation.id).then((response) => {
					if (response.code === 200) {
						this.itemStores = response.data.item_stores.filter(s => s.id !== this.selectedStore.id)
					}
				}).catch((err) => {
					this.showErrors(err)
				}).finally(() => {
					this.loading.stores = false
				})
			},
			showStoreDetails (store) {
				this.selectedStore = { ...store }
				this.$store.commit('removeNavigationProp', 'item')
				this.$f7router.refreshPage()
			},
			showAttributes (item) {
				this.$store.commit('setState', { key: 'selectedItem', value: item })
				this.$root.$emit('showAttributes')
			}
		}
	}
</script>
