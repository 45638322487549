import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _30d7ea83 = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _2586931b = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _e0aa84e0 = () => interopDefault(import('../pages/addresses/index.vue' /* webpackChunkName: "pages/addresses/index" */))
const _99b49376 = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _227574fe = () => interopDefault(import('../pages/categories.vue' /* webpackChunkName: "pages/categories" */))
const _6b7d9178 = () => interopDefault(import('../pages/coupons.vue' /* webpackChunkName: "pages/coupons" */))
const _b3c0f1de = () => interopDefault(import('../pages/email-verification.vue' /* webpackChunkName: "pages/email-verification" */))
const _123c36fe = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _02010e72 = () => interopDefault(import('../pages/help-and-support.vue' /* webpackChunkName: "pages/help-and-support" */))
const _d4718ef8 = () => interopDefault(import('../pages/home.vue' /* webpackChunkName: "pages/home" */))
const _491e07d2 = () => interopDefault(import('../pages/intro.vue' /* webpackChunkName: "pages/intro" */))
const _7cdfbdae = () => interopDefault(import('../pages/items/index.vue' /* webpackChunkName: "pages/items/index" */))
const _d8493aee = () => interopDefault(import('../pages/locations.vue' /* webpackChunkName: "pages/locations" */))
const _4104be94 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _72d34588 = () => interopDefault(import('../pages/oops.vue' /* webpackChunkName: "pages/oops" */))
const _6355eb4d = () => interopDefault(import('../pages/orders/index.vue' /* webpackChunkName: "pages/orders/index" */))
const _0bab2e55 = () => interopDefault(import('../pages/payments/index.vue' /* webpackChunkName: "pages/payments/index" */))
const _2bed00fd = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _02d196ad = () => interopDefault(import('../pages/account/edit-profile.vue' /* webpackChunkName: "pages/account/edit-profile" */))
const _0b7a303f = () => interopDefault(import('../pages/account/referrals.vue' /* webpackChunkName: "pages/account/referrals" */))
const _7ec7bb57 = () => interopDefault(import('../pages/items/filters.vue' /* webpackChunkName: "pages/items/filters" */))
const _4eb96104 = () => interopDefault(import('../pages/payments/pg.vue' /* webpackChunkName: "pages/payments/pg" */))
const _c63c3d52 = () => interopDefault(import('../pages/addresses/_type.vue' /* webpackChunkName: "pages/addresses/_type" */))
const _38b44d26 = () => interopDefault(import('../pages/conversations/_id.vue' /* webpackChunkName: "pages/conversations/_id" */))
const _16fd5396 = () => interopDefault(import('../pages/items/_id.vue' /* webpackChunkName: "pages/items/_id" */))
const _89a417c2 = () => interopDefault(import('../pages/legal/_slug.vue' /* webpackChunkName: "pages/legal/_slug" */))
const _1b7e97b8 = () => interopDefault(import('../pages/orders/_id/index.vue' /* webpackChunkName: "pages/orders/_id/index" */))
const _571200c2 = () => interopDefault(import('../pages/stores/_id.vue' /* webpackChunkName: "pages/stores/_id" */))
const _1d9fa24b = () => interopDefault(import('../pages/orders/_id/help.vue' /* webpackChunkName: "pages/orders/_id/help" */))
const _77207bb1 = () => interopDefault(import('../pages/orders/_id/track.vue' /* webpackChunkName: "pages/orders/_id/track" */))
const _775d1b7d = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _30d7ea83,
    name: "about-us"
  }, {
    path: "/account",
    component: _2586931b,
    name: "account"
  }, {
    path: "/addresses",
    component: _e0aa84e0,
    name: "addresses"
  }, {
    path: "/cart",
    component: _99b49376,
    name: "cart"
  }, {
    path: "/categories",
    component: _227574fe,
    name: "categories"
  }, {
    path: "/coupons",
    component: _6b7d9178,
    name: "coupons"
  }, {
    path: "/email-verification",
    component: _b3c0f1de,
    name: "email-verification"
  }, {
    path: "/faq",
    component: _123c36fe,
    name: "faq"
  }, {
    path: "/help-and-support",
    component: _02010e72,
    name: "help-and-support"
  }, {
    path: "/home",
    component: _d4718ef8,
    name: "home"
  }, {
    path: "/intro",
    component: _491e07d2,
    name: "intro"
  }, {
    path: "/items",
    component: _7cdfbdae,
    name: "items"
  }, {
    path: "/locations",
    component: _d8493aee,
    name: "locations"
  }, {
    path: "/login",
    component: _4104be94,
    name: "login"
  }, {
    path: "/oops",
    component: _72d34588,
    name: "oops"
  }, {
    path: "/orders",
    component: _6355eb4d,
    name: "orders"
  }, {
    path: "/payments",
    component: _0bab2e55,
    name: "payments"
  }, {
    path: "/signup",
    component: _2bed00fd,
    name: "signup"
  }, {
    path: "/account/edit-profile",
    component: _02d196ad,
    name: "account-edit-profile"
  }, {
    path: "/account/referrals",
    component: _0b7a303f,
    name: "account-referrals"
  }, {
    path: "/items/filters",
    component: _7ec7bb57,
    name: "items-filters"
  }, {
    path: "/payments/pg",
    component: _4eb96104,
    name: "payments-pg"
  }, {
    path: "/addresses/:type",
    component: _c63c3d52,
    name: "addresses-type"
  }, {
    path: "/conversations/:id?",
    component: _38b44d26,
    name: "conversations-id"
  }, {
    path: "/items/:id",
    component: _16fd5396,
    name: "items-id"
  }, {
    path: "/legal/:slug?",
    component: _89a417c2,
    name: "legal-slug"
  }, {
    path: "/orders/:id",
    component: _1b7e97b8,
    name: "orders-id"
  }, {
    path: "/stores/:id?",
    component: _571200c2,
    name: "stores-id"
  }, {
    path: "/orders/:id/help",
    component: _1d9fa24b,
    name: "orders-id-help"
  }, {
    path: "/orders/:id/track",
    component: _77207bb1,
    name: "orders-id-track"
  }, {
    path: "/",
    component: _775d1b7d,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
