<template>
	<f7-page
		id="store-details"
		ref="page"
		infinite
		:infinite-distance="1"
		:infinite-preloader="items.pagination && items.pagination.current_page < items.pagination.next_page"
		@infinite="loadMoreResults('items', 'getStoreItems')"
	>
		<f7-photo-browser
			v-if="selectedStore.images && selectedStore.images.length"
			ref="photos"
			type="popup"
			theme="dark"
			:photos="selectedStore.images"
		/>
		<div class="position-fixed" style="top: 0; left: 0; right: 0; background-color: #F5F5F5; z-index: 2">
			<div
				:data-background="selectedStore.images && selectedStore.images.length ?
					selectedStore.images[0] : '/images/placeholder.svg'"
				class="position-relative lazy lazy-fade-in"
				:style="{
					height: '200px',
					background: '#ccc',
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat',
					backgroundSize: selectedStore.images && selectedStore.images[0] ? '100% 100%' : '60% auto'
				}"
				@click="selectedStore.images && selectedStore.images.length ? $refs.photos.open(0) : null"
			>
				<f7-link
					href="#"
					class="bg-color-white elevation-4"
					style="position: absolute; right: 15px; top: 15px; height: 22px; width: 22px; border-radius: 50%"
					icon-f7="xmark_circle_fill"
					back
					icon-only
					@click.stop="$store.commit('removeNavigationProp', 'store')"
				/>
				<f7-row class="w-100 position-absolute p-2" style="background: rgb(0, 0, 0, .5); bottom: 0px">
					<f7-col width="25" class="text-align-center">
						<f7-icon f7="placemark" color="white" class="mb-1" />
						<small class="display-block text-color-white font-weight-bold">
							{{ (selectedStore.distance).toFixed(2) }} {{ $t('km') }}
						</small>
					</f7-col>
					<f7-col width="75">
						<h4 class="display-flex align-items-center text-capitalize text-color-white m-0">
							<span class="mr-1">{{ selectedStore.name }}</span>
							<f7-link
								icon-ios="f7:info"
								icon-md="material:info_fill"
								icon-size="20px"
								icon-color="white"
								class="store-info"
							/>
						</h4>
						<p class="text-capitalize text-color-white m-0">
							{{ selectedStore.address.line }}
						</p>
					</f7-col>
				</f7-row>
			</div>
		</div>
		<items :loading="loading" :items="items.data" />
		<cart-toolbar />
	</f7-page>
</template>

<script>
	import Items from '~/components/items'
	import CartToolbar from '~/components/cart-toolbar'

	export default {
		components: {
			Items,
			CartToolbar
		},
		data () {
			return {
				loading: true,
				items: {
					data: [],
					pagination: null
				}
			}
		},
		computed: {
			locale () {
				return this.$store.state.locale
			},
			nearbyStores () {
				return this.$store.state.nearbyStores
			},
			storeProp () {
				return this.$store.getters.navigationProp('store')
			},
			location () {
				return this.$store.state.location
			},
			cart () {
				return this.$store.state.cart
			},
			selectedStore: {
				get () {
					return this.$store.state.selectedStore
				},
				set (store) {
					this.$store.commit('setSelectedStore', store)
				}
			},
			isSelectedStoreActive () {
				return this.selectedStore.available
			},
			allowLoadMore: {
				get () {
					return this.$store.state.allowLoadMore
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'allowLoadMore',
						value
					})
				}
			}
		},
		beforeMount () {
			this.selectedStore = { ...this.storeProp }
			this.getStoreItems()
		},
		mounted () {
			this.$f7.tooltip.create({
				targetEl: '.store-info',
				trigger: 'click',
				text: `<p class="text-capitalize m-0">
					${this.isSelectedStoreActive ? this.$t('openNow') : this.$t('closedNow')}
				</p><p class="m-0">
					${this.storeClosingTime(this.selectedStore, this.locale)}
				</p><p class="m-0">
					${this.storeNextOpeningTime(this.selectedStore, this.locale)}
				</p>`
			})
		},
		destroyed () {
			if (this.nearbyStores.findIndex(s => s.id === parseInt(this.$f7route.params.id)) === -1) {
				this.$store.commit('setSelectedStore', this.cart.store || this.nearbyStores[0])
			}
		},
		methods: {
			getStoreItems (filters = {}) {
				filters = Object.assign({
					location_id: this.$f7route.params.id
				}, filters)

				return this.$store.dispatch('getItems', filters).then((response) => {
					if (response.code === 200) {
						this.items.data = filters.page ? this.items.data.concat(response.data.items) : response.data.items
						this.items.pagination = response.data.pagination
						this.$nextTick(() => {
							if (this.$refs.page) {
								this.$f7.lazy.create(this.$refs.page.$el)
							}
						})
					}
				}).catch(this.showErrors).finally(() => {
					this.loading = false
				})
			}
		}
	}
</script>

<style lang="scss">
	#store-details {
		.page-content {
			padding-top: 200px;
		}
	}
</style>
