<template>
	<f7-page id="account">
		<f7-navbar :title="$t('account')" inner-class="text-capitalize" />
		<f7-block-title
			class="text-capitalize"
		>
			{{ $t('my account') }}
		</f7-block-title>
		<f7-list v-if="user" class="mb-0" accordion-list>
			<f7-list-item v-if="user" :title="user.name" accordion-item>
				<f7-icon slot="media" f7="person" color="gray" />
				<div slot="footer" class="display-flex align-items-center">
					{{ user.phone.number }} {{ user.email ? `&bull; ${user.email}` : "" }}
				</div>
				<f7-accordion-content>
					<f7-list>
						<f7-list-item class="text-capitalize" :title="$t('update profile')" link="/account/edit-profile">
							<f7-icon slot="media" f7="square_pencil" color="gray" />
						</f7-list-item>
						<f7-list-item
							link="#"
							class="text-capitalize"
							:title="$t('change password')"
							no-chevron
							@click="showChangePassword = true"
						>
							<f7-icon slot="media" f7="lock_rotation" color="gray" />
						</f7-list-item>
						<f7-list-item
							link="#"
							class="text-capitalize"
							:title="$t('change mobile number')"
							no-chevron
							@click="showChangeMobile = true"
						>
							<f7-icon slot="media" f7="device_phone_portrait" color="gray" />
						</f7-list-item>
						<f7-list-item
							link="#"
							class="text-capitalize"
							:title="$t('change email address')"
							no-chevron
							@click="showChangeEmail = true"
						>
							<f7-icon slot="media" material="mail_outline" color="gray" />
						</f7-list-item>
						<f7-list-item v-if="merchant.subscription.products && merchant.subscription.products.referral && user.referral_code" class="text-capitalize" :title="$t('referrals')" link="/account/referrals">
							<f7-icon slot="media" f7="person_3" color="gray" />
						</f7-list-item>
						<f7-list-item class="text-capitalize" :title="$t('deactive account')" link="#" :disabled="processing" @click="deleteAccount">
							<f7-icon slot="media" f7="trash" color="gray" />
						</f7-list-item>
						<f7-list-item class="text-capitalize" :title="$t('logout')" link="#" @click="logout">
							<f7-icon slot="media" f7="power" color="gray" />
						</f7-list-item>
					</f7-list>
				</f7-accordion-content>
			</f7-list-item>
			<f7-list-item class="text-capitalize" :title="$t('favorites')" link="/items?type=favorites">
				<f7-icon slot="media" f7="square_favorites_alt" color="gray" />
			</f7-list-item>
			<f7-list-item class="text-capitalize" :title="$tc('address', 2)" link="/addresses">
				<f7-icon slot="media" f7="placemark" color="gray" />
			</f7-list-item>
			<f7-list-item class="text-capitalize" :title="$tc('order', 2)" link="/orders">
				<f7-icon slot="media" f7="cube_box" color="gray" />
			</f7-list-item>
		</f7-list>
		<f7-list v-else>
			<f7-list-item link="/login">
				{{ $t('signIn') | capitalize }}
			</f7-list-item>
		</f7-list>
		<f7-block-title class="text-capitalize">
			{{ $t('language') }}
		</f7-block-title>
		<f7-list>
			<f7-list-item
				link="#"
				:title="$t('choose language')"
				:after="options.find(o => o.value === locale).text"
				class="text-capitalize"
				no-chevron
				@click="showChangeLocale = true"
			>
				<f7-icon slot="media" f7="globe" color="gray" />
			</f7-list-item>
		</f7-list>
		<f7-block-title class="text-capitalize">
			{{ $t('about') }}
		</f7-block-title>
		<f7-list class="mb-0" accordion-list>
			<f7-list-item :title="$t('about')" class="text-capitalize" link="/about-us">
				<f7-icon slot="media" f7="info_circle" color="gray" />
			</f7-list-item>
			<f7-list-item :title="$t('h&s')" class="text-capitalize" link="/help-and-support">
				<f7-icon slot="media" material="headset_mic" color="gray" />
			</f7-list-item>
			<f7-list-item :title="$t('legal')" class="text-capitalize" accordion-item>
				<f7-icon slot="media" material="gavel" color="gray" />
				<f7-accordion-content>
					<f7-list>
						<f7-list-item :title="$t('t&c')" link="/legal/terms-conditions">
							<f7-icon slot="media" f7="doc_text" color="gray" />
						</f7-list-item>
						<f7-list-item :title="$t('r&c')" link="/legal/refund-cancellation">
							<f7-icon slot="media" material="history" color="gray" />
						</f7-list-item>
						<f7-list-item :title="$t('privacyPolicy')" link="/legal/privacy-policy">
							<f7-icon slot="media" f7="lock_shield" color="gray" />
						</f7-list-item>
					</f7-list>
				</f7-accordion-content>
			</f7-list-item>
		</f7-list>
		<f7-block-title class="text-align-center">
			<p v-if="appVersion" class="mt-0 mb-3">
				{{ $t('appVersion') | capitalize }} v{{ appVersion }}
			</p>
			<img
				v-if="!$f7.device.webView && $f7.device.android && merchant.settings.app.app_store_links.google_play_store_url"
				src="/images/play-store.png"
				alt="Play Store"
				width="200px"
				@click="setHref(`https://play.app.goo.gl/?link=${merchant.settings.app.app_store_links.google_play_store_url}`)"
			>
			<img
				v-else-if="!$f7.device.webView && $f7.device.ios && merchant.settings.app.app_store_links.apple_app_store_url"
				src="/images/apple-store.png"
				alt="Apple Store"
				width="200px"
				@click="setHref(merchant.settings.app.app_store_links.apple_app_store_url.replace('https://', 'itms-apps://'))"
			>
		</f7-block-title>
		<f7-actions
			id="change-mobile"
			:opened="showChangeMobile"
			@actions:open="initMobileActionSheet"
			@actions:opened="focusInput('#change-mobile input')"
			@actions:closed="showChangeMobile = false"
		>
			<f7-actions-group>
				<f7-actions-label class="justify-content-space-between px-3 text-capitalize">
					{{ $t('change mobile number') }}
					<f7-link
						icon-f7="xmark"
						icon-size="20px"
						@click="showChangeMobile = false"
					/>
				</f7-actions-label>
				<div class="page-bg-color text-align-center p-3">
					<ValidationObserver ref="changeMobileValidator">
						<ValidationProvider
							v-slot="{ errors }"
							vid="phone.number"
							:name="$t('mobile number')"
							rules="mobile"
						>
							<custom-input
								v-model="mobile"
								type="tel"
								name="mobile number"
								:placeholder="$options.filters.capitalize($t('mobile number'))"
								:error="errors[0]"
							/>
						</ValidationProvider>
					</ValidationObserver>
					<f7-button
						large
						raised
						fill
						:disabled="processing"
						@click="changeMobileNumber"
					>
						{{ $t('next') }}
						<f7-icon class="float-right" f7="arrow_right" style="top: 10px" />
					</f7-button>
				</div>
			</f7-actions-group>
		</f7-actions>
		<f7-actions
			id="change-email"
			:opened="showChangeEmail"
			@actions:open="initEmailActionSheet"
			@actions:opened="focusInput('#change-email input')"
			@actions:closed="showChangeEmail = false"
		>
			<f7-actions-group>
				<f7-actions-label class="justify-content-space-between px-3 text-capitalize">
					{{ $t('change email') }}
					<f7-link
						icon-f7="xmark"
						icon-size="20px"
						@click="showChangeEmail = false"
					/>
				</f7-actions-label>
				<div class="page-bg-color text-align-center p-3">
					<ValidationObserver ref="changeEmailValidator">
						<ValidationProvider
							v-slot="{ errors }"
							:name="$t('email')"
							rules="required|email"
						>
							<custom-input
								v-model="email"
								type="text"
								name="email"
								:placeholder="$options.filters.capitalize($t('email address'))"
								:error="errors[0]"
							/>
						</ValidationProvider>
					</ValidationObserver>
					<f7-button
						large
						raised
						fill
						:disabled="processing"
						@click="changeEmail"
					>
						{{ $t('save') }}
					</f7-button>
				</div>
			</f7-actions-group>
		</f7-actions>
		<f7-actions
			id="change-password"
			:opened="showChangePassword"
			@actions:open="initPasswordActionSheet"
			@actions:opened="focusInput('#change-password input')"
			@actions:closed="showChangePassword = false"
		>
			<f7-actions-group>
				<f7-actions-label class="justify-content-space-between px-3 text-capitalize">
					{{ $t('change password') }}
					<f7-link
						icon-f7="xmark"
						icon-size="20px"
						@click="showChangePassword = false"
					/>
				</f7-actions-label>
				<div class="page-bg-color text-align-center p-3">
					<ValidationObserver ref="validator">
						<ValidationProvider
							v-slot="{ errors }"
							:name="$t('current password')"
							rules="required"
						>
							<custom-input
								v-model="currentPassword"
								type="password"
								name="current_password"
								:placeholder="$options.filters.capitalize($t('current password'))"
								:error="errors[0]"
							/>
						</ValidationProvider>
						<ValidationProvider
							v-slot="{ errors }"
							vid="password"
							:name="$t('password')"
							rules="required|min:8|max:32"
						>
							<custom-input
								v-model="newPassword"
								type="password"
								name="password"
								:placeholder="$options.filters.capitalize($t('new password'))"
								:error="errors[0]"
							/>
						</ValidationProvider>
						<ValidationProvider
							v-slot="{ errors }"
							:name="$t('password confirmation')"
							rules="required|equals:password"
						>
							<custom-input
								v-model="newPasswordConfirmation"
								type="password"
								name="password_confirmation"
								:placeholder="$options.filters.capitalize($t('confirm new password'))"
								:error="errors[0]"
							/>
						</ValidationProvider>
					</ValidationObserver>
					<f7-button
						large
						raised
						fill
						:disabled="processing"
						@click="changePassword"
					>
						{{ $t('change') }}
					</f7-button>
				</div>
			</f7-actions-group>
		</f7-actions>
		<f7-actions
			ref="localeActionSheet"
			:opened="showChangeLocale"
			@actions:closed="showChangeLocale = false"
		>
			<f7-actions-group>
				<f7-actions-label class="justify-content-space-between px-3 text-capitalize">
					{{ $t('choose language') }}
					<f7-link
						icon-f7="xmark"
						icon-size="20px"
						@click="$refs.localeActionSheet.close()"
					/>
				</f7-actions-label>
				<f7-list class="my-0">
					<f7-list-item
						v-for="(lang, index) in options"
						:key="index"
						radio
						:checked="lang.value === locale"
						@click="setLocale(lang.value)"
					>
						<span slot="title" class="text-capitalize">
							{{ lang.text }}
						</span>
					</f7-list-item>
				</f7-list>
			</f7-actions-group>
		</f7-actions>
		<menu-toolbar />
	</f7-page>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from 'vee-validate'
	import MenuToolbar from '~/components/menu-toolbar'
	import CustomInput from '~/components/custom-input'

	export default {
		components: {
			MenuToolbar,
			CustomInput,
			ValidationObserver,
			ValidationProvider
		},
		data () {
			return {
				appVersion: null,
				showChangeMobile: false,
				showChangeEmail: false,
				mobile: {
					code: '',
					number: ''
				},
				email: '',
				showChangePassword: false,
				showChangeLocale: false,
				currentPassword: '',
				newPassword: '',
				newPasswordConfirmation: '',
				options: [
					{
						text: 'English',
						value: 'en'
					},
					{
						text: 'Arabic',
						value: 'ar'
					},
					{
						text: 'French',
						value: 'fr'
					}
				]
			}
		},
		computed: {
			merchant () {
				return this.$store.state.merchant
			},
			user () {
				return this.$store.state.user
			},
			processing: {
				get () {
					return this.$store.state.processing
				},
				set (value) {
					this.$store.commit('setState', { key: 'processing', value })
				}
			},
			otpVerification: {
				get () {
					return this.$store.state.otpVerification
				},
				set (value) {
					this.$store.commit('setState', { key: 'otpVerification', value })
				}
			},
			locale: {
				get () {
					return this.$store.state.locale
				},
				set (value) {
					this.$store.commit('setLocale', value)
				}
			}
		},
		mounted () {
			window.emitAppVersion = this.setAppVersion
			this.$bridge.getAppVersion()
		},
		methods: {
			setAppVersion (version) {
				this.appVersion = version
			},
			setLocale (lang) {
				this.locale = lang
				this.$refs.localeActionSheet.close()
			},
			async changePassword () {
				if (await this.$refs.validator.validate()) {
					this.processing = true
					this.$store.dispatch('changePassword', {
						current_password: this.currentPassword,
						password: this.newPassword,
						password_confirmation: this.newPasswordConfirmation
					}).then((response) => {
						if (response.code === 200) {
							this.showChangePassword = false
							this.$f7.toast.create({
								text: `${this.$t('toastMsg.passwordChangeSuccess')}!`,
								closeTimeout: 3000
							}).open()
						}

						this.processing = false
					}).catch((err) => {
						this.showErrors(err)
						this.processing = false
					})
				}
			},
			async changeMobileNumber () {
				if (await this.$refs.changeMobileValidator.validate()) {
					this.processing = true
					this.$store.dispatch('changeMobile', {
						phone: {
							code: this.mobile.code,
							number: this.mobile.number,
							country: this.mobile.country
						}
					}).then((response) => {
						if (response.code === 200) {
							this.showChangeMobile = false
							this.otpVerification = {
								type: 'change-password',
								medium: `${this.mobile.code} - ${this.mobile.number}`
							}
						}

						this.processing = false
					}).catch((err) => {
						this.$refs.validator = this.$refs.changeMobileValidator
						this.showErrors(err)
						this.processing = false
					})
				}
			},
			async changeEmail () {
				if (await this.$refs.changeEmailValidator.validate()) {
					this.processing = true
					this.$store.dispatch('changeEmail', {
						email: this.email
					}).then((response) => {
						if (response.code === 200) {
							this.showChangeEmail = false
							this.$f7.dialog.alert(this.$t('confirmationEmailSent', [this.email]), this.$t('confirmEmail'))
						}

						this.processing = false
					}).catch((err) => {
						this.$refs.validator = this.$refs.changeEmailValidator
						this.showErrors(err)
						this.processing = false
					})
				}
			},
			deleteAccount () {
				this.$f7.dialog.create({
					title: this.$options.filters.capitalize(this.$t('delete account')),
					text: this.$t('areYouSure'),
					buttons: [{
							text: this.$options.filters.capitalize(this.$t('cancel')),
							close: true
						},
						{
							text: this.$options.filters.capitalize(this.$t('ok')),
							onClick: () => {
								this.processing = true
								this.$store.dispatch('deleteAccount').then(() => {
									this.$f7.views.main.router.navigate('/login', {
										clearPreviousHistory: true
									})
								}).catch(this.showErrors).finally(() => {
									this.processing = false
								})
							}
						}
					]
				}).open()
			},
			initMobileActionSheet () {
				this.mobile = {
					...this.user.phone,
					isValid: true
				}

				window.requestAnimationFrame(this.$refs.changeMobileValidator.reset)
			},
			initEmailActionSheet () {
				this.email = this.user.email
				window.requestAnimationFrame(this.$refs.changeEmailValidator.reset)
			},
			initPasswordActionSheet () {
				this.currentPassword = ''
				this.newPassword = ''
				this.newPasswordConfirmation = ''
				window.requestAnimationFrame(this.$refs.validator.reset)
			},
			logout () {
				this.$f7.dialog.create({
					title: this.$options.filters.capitalize(this.$t('logout')),
					text: this.$t('logoutConfirm'),
					buttons: [{
							text: this.$options.filters.capitalize(this.$t('cancel')),
							close: true
						},
						{
							text: this.$options.filters.capitalize(this.$t('ok')),
							onClick: () => {
								this.processing = true
								this.$store.dispatch('logout').then(() => {
									this.$f7.views.main.router.navigate('/login', {
										clearPreviousHistory: true
									})
								}).catch(this.showErrors).finally(() => {
									this.processing = false
								})
							}
						}
					]
				}).open()
			}
		}
	}
</script>

<style lang="scss">
	#change-mobile {
		.vti__dropdown-list.above {
			top: -80px;
		}
	}
</style>
