<template>
	<f7-page id="help-and-support">
		<f7-navbar :title="$t('h&s')" inner-class="text-capitalize" :back-link="$t('back')" />
		<google-map
			:map-config="mapConfig"
			:markers="markers"
		/>
		<f7-list v-if="selectedStore && selectedStore.address" class="mt-0" media-list>
			<f7-list-item
				link="#"
				@click="selectedStore.address.coordinates.length ? $bridge.openExternalUrl(
					`https://www.google.com/maps/dir/?api=1&origin=${selectedStore.address.coordinates[1]},${selectedStore.address.coordinates[0]}`
				) : null"
			>
				<div v-if="selectedStore.name" slot="title" class="text-capitalize">
					{{ selectedStore.name }}
				</div>
				<div slot="subtitle" style="white-space: initial">
					<span v-if="selectedStore.address.door_no">{{ selectedStore.address.door_no }},</span>
					<span v-if="selectedStore.address.line">{{ selectedStore.address.line }},</span>
					<span v-if="selectedStore.address.area">{{ selectedStore.address.area }},</span>
					<span v-if="selectedStore.address.city">{{ selectedStore.address.city }},</span>
					<span v-if="selectedStore.address.zip">{{ selectedStore.address.zip }}</span>
				</div>
				<f7-icon slot="media" material="directions" size="32" class="mt-2" color="gray" />
			</f7-list-item>
		</f7-list>
		<f7-block-title
			v-if="Object.values(merchant.settings.general.support || {}).filter(Boolean).length"
			class="text-capitalize"
		>
			{{ $t('contact info') }}
		</f7-block-title>
		<f7-list class="mb-0" accordion-list>
			<f7-list-item
				v-if="merchant.settings.general.support && merchant.settings.general.support.support_phone"
				:header="$t('mobile number')"
				:title="`${ merchant.settings.general.support.support_phone }`"
				class="text-capitalize"
				link="#"
				@click="$bridge.openDialer(merchant.settings.general.support.support_phone)"
			>
				<f7-icon slot="media" material="phone_iphone" icon-size="28" color="gray" />
			</f7-list-item>
			<f7-list-item
				v-if="merchant.settings.general.support && merchant.settings.general.support.whatsapp_phone"
				:header="$t('whatsapp')"
				:title="`${ merchant.settings.general.support.whatsapp_phone }`"
				class="text-capitalize"
				link="#"
				@click="$bridge.openExternalUrl(
					`https://api.whatsapp.com/send?phone=${merchant.settings.general.support.whatsapp_phone}`
				)"
			>
				<div slot="media">
					<img slot="media" src="images/whatsapp-icon.svg" style="width: 24px; height:24px">
				</div>
			</f7-list-item>
			<f7-list-item
				v-if="merchant.settings.general.support && merchant.settings.general.support.email"
				:header="$t('email')"
				:title="merchant.settings.general.support.email"
				class="text-capitalize"
				link="#"
				@click="$bridge.openExternalUrl(
					`mailto:${merchant.settings.general.support.email}`
				)"
			>
				<f7-icon slot="media" material="mail" color="gray" />
			</f7-list-item>
		</f7-list>
	</f7-page>
</template>

<script>
	import GoogleMap from '~/components/google-map'

	export default {
		components: {
			GoogleMap
		},
		data () {
			return {
				mapConfig: {
					zoom: 18,
					zoomControl: true,
					centerMarker: true,
					disableDefaultUI: true
				},
				markers: []
			}
		},
		computed: {
			merchant () {
				return this.$store.state.merchant
			},
			selectedStore () {
				return this.$store.state.selectedStore
			}
		},
		beforeMount () {
			if (this.selectedStore && this.selectedStore.address) {
				this.updateLocation(this.selectedStore.address)
			}
		},
		methods: {
			updateLocation (location) {
				this.markers = [
					{
						position: {
							lat: parseFloat(location.coordinates[1]),
							lng: parseFloat(location.coordinates[0])
						}
					}
				]
			}
		}
	}
</script>
