<template>
	<f7-actions
		id="otp-verification"
		ref="otpVerification"
		:opened="otpVerification.type !== null"
		@actions:opened="initOtp"
		@actions:closed="close"
	>
		<f7-actions-group>
			<f7-actions-label class="display-flex justify-content-space-between">
				<span v-if="otpVerification.type === 'forgot-password'" class="text-capitalize">
					{{ otpVerified ? $t('reset password') : $t('forgot password') }}
				</span>
				<span v-else>
					{{ $t('otpVerification') }}
				</span>
				<f7-link
					icon-f7="xmark"
					icon-size="20px"
					@click="$refs.otpVerification.close()"
				/>
			</f7-actions-label>
			<div
				v-if="!otpVerified"
				class="page-bg-color text-align-center p-3"
			>
				<p class="m-0">
					{{ $t('otpSentTo', [otpVerification.medium]) }}
					<span v-if="otpVerification.type === 'forgot-password'">{{ $t('resetPasswordMsg') }}</span>
				</p>
				<p class="text-color-primary">
					{{ $moment().startOf('day').seconds(seconds).format('mm:ss') }}
				</p>
				<f7-row>
					<f7-col>
						<f7-list no-hairlines-md>
							<f7-list-input type="number" input-style="text-align: center" maxlength="1" data-index="0" />
						</f7-list>
					</f7-col>
					<f7-col>
						<f7-list no-hairlines-md>
							<f7-list-input type="number" input-style="text-align: center" maxlength="1" data-index="1" />
						</f7-list>
					</f7-col>
					<f7-col>
						<f7-list no-hairlines-md>
							<f7-list-input type="number" input-style="text-align: center" maxlength="1" data-index="2" />
						</f7-list>
					</f7-col>
					<f7-col>
						<f7-list no-hairlines-md>
							<f7-list-input type="number" input-style="text-align: center" maxlength="1" data-index="3" />
						</f7-list>
					</f7-col>
					<f7-col>
						<f7-list no-hairlines-md>
							<f7-list-input type="number" input-style="text-align: center" maxlength="1" data-index="4" />
						</f7-list>
					</f7-col>
					<f7-col>
						<f7-list no-hairlines-md>
							<f7-list-input type="number" input-style="text-align: center" maxlength="1" data-index="5" />
						</f7-list>
					</f7-col>
				</f7-row>
				<f7-row>
					<f7-col>
						<p class="text-color-red mb-0" style="height: 21px">
							{{ errorMsg }}
						</p>
					</f7-col>
				</f7-row>
				<p class="text-color-gray">
					{{ $t('didntReceiveOtp') }}
					<f7-link class="underline" @click="resendOtp">
						{{ $t('resendOtp') }}
					</f7-link>
				</p>
			</div>
			<div v-else class="bg-color-white text-align-center p-3">
				<ValidationObserver ref="validator">
					<ValidationProvider
						v-slot="{ errors }"
						:name="$t('password')"
						rules="required|min:6|max:18"
					>
						<custom-input
							v-model="password"
							type="password"
							name="password"
							:placeholder="$options.filters.capitalize($t('new password'))"
							:tooltip="passwordComplexityMsg()"
							:error="errors[0]"
							:events="{
								focus: showTooltip,
								blur: hideTooltip
							}"
						/>
					</ValidationProvider>
					<ValidationProvider
						v-slot="{ errors }"
						vid="confirmation"
						:name="$t('confirm password')"
						rules="required|min:6|max:18|equals:password"
					>
						<custom-input
							v-model="passwordConfirmation"
							type="password"
							name="confirm-password"
							:placeholder="$options.filters.capitalize($t('confirm new password'))"
							:error="errors[0]"
						/>
					</ValidationProvider>
				</ValidationObserver>
				<div class="display-flex justify-content-center">
					<f7-button
						class="w-100"
						large
						fill
						raised
						:disabled="processing"
						@click="resetPassword"
					>
						{{ $t('reset') }}
						<f7-icon class="float-right" f7="arrow_right" style="top: 10px" />
					</f7-button>
				</div>
			</div>
		</f7-actions-group>
	</f7-actions>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from 'vee-validate'
	import CustomInput from '~/components/custom-input'

	const initialState = () => ({
		timer: null,
		seconds: 300,
		otp: '',
		errorMsg: '',
		otpVerified: false,
		password: '',
		passwordConfirmation: ''
	})

	export default {
		components: {
			CustomInput,
			ValidationObserver,
			ValidationProvider
		},
		data () {
			return initialState()
		},
		computed: {
			processing: {
				get () {
					return this.$store.state.processing
				},
				set (value) {
					this.$store.commit('setState', { key: 'processing', value })
				}
			},
			otpVerification () {
				return this.$store.state.otpVerification
			},
			otpSource () {
				return this.$store.state.merchant.settings.general.registration_verification_source
			}
		},
		watch: {
			otp (otp) {
				if (otp.length) {
					this.errorMsg = ''
				}
			}
		},
		methods: {
			initTimer () {
				this.timer = setInterval(() => {
					this.seconds--

					if (this.seconds <= 0) {
						clearInterval(this.timer)
						this.close()
					}
				}, 1000)
			},
			generateOtp ($event) {
				const index = parseInt($event.target.closest('li').getAttribute('data-index'))

				if ($event.target.value) {
					this.otp += $event.target.value

					if (this.otp.length < 6) {
						this.$$('#otp-verification input').eq(index + 1).focus()
					} else {
						this.$$('#otp-verification input.input-focused').eq(0).blur()
						this.processing = true
						this.$store.dispatch('verifyOtp', { otp: this.otp })
							.then((response) => {
								if (response.code === 200) {
									if (this.otpVerification.type === 'signup') {
										this.$refs.otpVerification.close()
										this.$f7.views.main.router.navigate('/home', {
											clearPreviousHistory: true
										})
									} else if (this.otpVerification.type === 'forgot-password') {
										this.otpVerified = true
									} else {
										this.close()
										this.$f7.toast.create({
											text: this.$t('toastMsg.mobileNoChangeSuccessful'),
											closeTimeout: 3000
										}).open()
									}
								}

								this.processing = false
							})
							.catch((err) => {
								if (err.response && err.response.data) {
									this.errorMsg = err.response.data.message
								}

								this.$$('#otp-verification input').forEach((i) => { i.value = '' })
								this.otp = ''
								this.focusInput('#otp-verification input')
								this.processing = false
							})
					}
				} else if ($event.keyCode === 8) {
					this.otp = this.otp.slice(0, -1)

					if (index > 0) {
						this.$$('#otp-verification input').eq(index - 1).focus()
					}
				}
			},
			close () {
				clearInterval(this.timer)
				Object.assign(this.$data, initialState())
				this.$store.commit('resetOtpVerification')
				this.$$('#otp-verification input').forEach((i) => { i.value = '' })
			},
			initOtp () {
				this.initTimer()
				this.focusInput('#otp-verification input')
				this.$$('#otp-verification input[type=\'number\']').forEach((el) => {
					el.addEventListener('keyup', this.generateOtp)
					el.addEventListener('keypress', ($event) => {
						if ($event.target.value.length >= 1) {
							$event.preventDefault()

							return false
						}
					})
				})
			},
			async resetPassword () {
				if (await this.$refs.validator.validate()) {
					this.processing = true
					this.$store.dispatch('resetPassword', {
						password: this.password,
						password_confirmation: this.passwordConfirmation
					}).then((response) => {
						if (response.code === 200) {
							this.$refs.otpVerification.close()
							this.$f7.toast.create({
								text: this.$t('loginNewPassword'),
								closeTimeout: 3000
							}).open()
						}

						this.processing = false
					}).catch((err) => {
						this.showErrors(err)
						this.processing = false
					})
				}
			},
			resendOtp () {
				this.processing = true
				this.$store.dispatch('resendOtp').then((response) => {
					if (response.code === 200) {
						clearInterval(this.timer)
						this.seconds = 300
						this.initTimer()
						this.focusInput('#otp-verification input')
					}

					this.processing = false
				}).catch((err) => {
					this.showErrors(err)
					this.processing = false
				})
			}
		}
	}
</script>

<style lang="scss">
	.md #otp-verification {
		.item-input {
			padding-left: 0;

			.item-inner {
				padding: 0
			}
		}

		.item-input:not(.item-input-outline) .item-input-wrap:after {
			background-color: var(--f7-color-gray);
		}

		.item-input-focused:not(.item-input-outline) .item-input-wrap:after {
			background: var(--f7-input-focused-border-color, var(--f7-theme-color));
		}
	}
</style>
