var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`position-relative bg-color-${
		_vm.merchant.settings.app.appearance.use_dark_theme ? 'black' : 'white'
	} rounded elevation-4 p-3 mb-3`},[(_vm.type === 'tel')?_c('vue-tel-input',{staticClass:"text-capitalize",attrs:{"mode":"international","name":_vm.name,"value":_vm.value.number,"input-options":{ placeholder: _vm.placeholder, styleClasses: 'text-capitalize' },"default-country":_vm.value.country ? _vm.value.country.code : (
			_vm.merchant.settings.general.country_code || ''
		),"disabled-fetching-country":(
			_vm.value.country ? _vm.value.country.code : _vm.merchant.settings.general.country_code
		) ? true : false},on:{"input":_vm.updateValue}}):_c('input',{ref:"input",staticClass:"w-100",style:({
			paddingRight: _vm.type === 'password' ? '30px' : false
		}),attrs:{"type":_vm.type,"name":_vm.name,"placeholder":_vm.placeholder,"data-tooltip":_vm.tooltip},domProps:{"value":_vm.value},on:{"input":_vm.updateValue,"focus":function($event){_vm.events.focus ? _vm.events.focus($event) : null},"blur":function($event){_vm.events.blur ? _vm.events.blur($event) : null}}}),_vm._v(" "),(_vm.type === 'password')?_c('f7-link',{staticClass:"eye",attrs:{"icon-f7":_vm.showPassword ? 'eye_slash_fill' : 'eye_fill',"color":"gray"},on:{"click":_vm.togglePassword}}):_vm._e(),_vm._v(" "),_c('small',{staticClass:"position-absolute text-color-red"},[_vm._v(_vm._s(_vm.error))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }