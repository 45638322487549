<template>
	<f7-page id="login" no-navbar @page:afterin="initSignin">
		<div class="position-relative bg-color-primary elevation-4" style="height: 200px">
			<img
				:src="merchant.settings.app.appearance.login_screen_bg_img_url || merchant.settings.general.logo_img_url || '/images/logo.png'"
				alt="Login Background"
				class="position-absolute m-auto"
				style="top: 0; bottom: 0; left: 0; right: 0; max-height: 100%; max-width: 100%"
			>
		</div>
		<div class="page-bg-color p-3">
			<h1 class="m-0">
				{{ $t('welcomeBack') }}
			</h1>
			<p class="text-color-gray mt-0">
				{{ $t('pleaseLogin') }}
			</p>
			<ValidationObserver ref="validator">
				<ValidationProvider
					v-if="inputType === 'mobile'"
					ref="mobileValidator"
					v-slot="{ errors }"
					vid="phone.number"
					:name="$t('mobile number')"
					rules="mobile|mobileRequired"
				>
					<custom-input
						id="mobile-field"
						v-model="userName"
						type="tel"
						name="mobile number"
						:placeholder="$options.filters.capitalize($t('mobile number'))"
						:error="errors[0]"
						@input="validateInputType($event)"
					/>
				</ValidationProvider>
				<ValidationProvider
					v-else
					ref="emailValidator"
					v-slot="{ errors }"
					:name="$t('email / mobile number')"
					rules="email|required"
				>
					<custom-input
						id="email-field"
						v-model="userName"
						type="text"
						name="email"
						:placeholder="`${$options.filters.capitalize($t('email / mobile number'))}`"
						:error="errors[0]"
						@input="validateInputType($event)"
					/>
				</ValidationProvider>
				<ValidationProvider
					v-if="showPassword"
					v-slot="{ errors }"
					:name="$t('password')"
					rules="required|min:6|max:18"
				>
					<custom-input
						v-model="password"
						type="password"
						name="password"
						:placeholder="$options.filters.capitalize($t('password'))"
						:error="errors[0]"
					/>
				</ValidationProvider>
			</ValidationObserver>
			<f7-button
				v-if="!showPassword"
				large
				raised
				fill
				class="mb-4"
				:disabled="processing"
				@click="verifyAccount"
			>
				{{ $t('next') }}
				<f7-icon class="float-right" f7="arrow_right" style="top: 10px" />
			</f7-button>
			<f7-block class="text-align-right px-0 mt-0 mb-3">
				<f7-link v-if="showPassword" class="underline text-capitalize" @click="forgotPassword">
					{{ $t('forgotPassword') }}
				</f7-link>
			</f7-block>
			<f7-button
				v-if="showPassword"
				large
				raised
				fill
				class="mb-4"
				:disabled="processing"
				@click="validateLogin"
			>
				{{ $t('login') }}
				<f7-icon class="float-right" f7="arrow_right" style="top: 10px" />
			</f7-button>
			<p class="line-with-words mb-4" :class="{ 'mt-4': !showPassword }">
				<span class="page-bg-color">{{ $t('or') }}</span>
			</p>
			<div
				v-if="$bridge.name === 'WEB'"
				id="google-signin-button"
				class="mb-3"
			/>
			<f7-button
				v-if="$bridge.name !== 'WEB' && merchant.settings.integrations.google_sign_in.enabled"
				class="display-flex mb-3 social-button"
				:style="{
					background: merchant.settings.app.appearance.use_dark_theme ? 'black' : 'white',
				}"
				@click="socialLogin({name:'google', type:'login'})"
			>
				<p class="w-100 m-0">
					<f7-row class="align-items-center" no-gap>
						<f7-col width="20">
							<img src="/images/google.svg" alt="Google" width="20px" class="display-flex">
						</f7-col>
						<f7-col width="65">
							<span class="font-weight-normal">
								{{ $t('signinWithGoogle') }}
							</span>
						</f7-col>
						<f7-col width="15" />
					</f7-row>
				</p>
			</f7-button>

			<f7-button
				v-if="merchant.settings.integrations.facebook_login.enabled"
				class="display-flex mb-3 social-button"
				:style="{
					background: merchant.settings.app.appearance.use_dark_theme ? 'black' : 'white',
				}"
				@click="socialLogin({name:'facebook', type:'login'})"
			>
				<p class="w-100">
					<f7-row class="align-items-center" no-gap>
						<f7-col width="20">
							<img src="/images/facebook.svg" alt="Facebook" width="20px" class="display-flex">
						</f7-col>
						<f7-col width="65">
							<span class="font-weight-normal">
								{{ $t('signinWithFB') }}
							</span>
						</f7-col>
						<f7-col width="15" />
					</f7-row>
				</p>
			</f7-button>
			<p class="text-align-center m-0">
				{{ $t('dontHaveAnAccount') }}
				<f7-link href="/signup" class="underline">
					{{ $t('register now') | capitalize }}
				</f7-link>
			</p>
		</div>
	</f7-page>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from 'vee-validate'
	import CustomInput from '~/components/custom-input'

	export default {
		components: {
			CustomInput,
			ValidationObserver,
			ValidationProvider
		},
		data () {
			return {
				password: '',
				showPassword: false,
				inputType: 'email',
				userName: ''
			}
		},
		computed: {
			merchant () {
				return this.$store.state.merchant
			},
			processing: {
				get () {
					return this.$store.state.processing
				},
				set (value) {
					this.$store.commit('setState', { key: 'processing', value })
				}
			},
			otpVerification: {
				get () {
					return this.$store.state.otpVerification
				},
				set (value) {
					this.$store.commit('setState', { key: 'otpVerification', value })
				}
			}
		},
		mounted () {
			if (this.merchant.settings.integrations.google_sign_in.enabled) {
				this.initGoogleSdk(this.merchant.settings.integrations.google_sign_in.client_id)
			}

			if (this.merchant.settings.integrations.facebook_login.enabled && this.$bridge.name === 'WEB') {
				this.initFacebookSdk(this.merchant.settings.integrations.facebook_login.app_id)
			}

			window.emitSocialPlatform = this.emitSocialPlatform
		},
		methods: {
			validateInputType ($event) {
				this.inputType = (/^[\d+\s]+$/.test($event?.eventData || $event)) ? 'mobile' : 'email'

				if (this.inputType === 'mobile' && typeof $event === 'string') {
					this.userName = {
						number: $event,
						country: {
							code: this.merchant.settings.general.country_code,
							name: ''
						}
					}
				} else if (this.inputType === 'email' && typeof $event !== 'string') {
					this.userName = $event?.eventData ? $event.eventData.toString() : ''
				}

				setTimeout(() => {
					document.querySelector(`#${this.inputType}-field input`)?.focus()
				}, 100)
			},
			initSignin () {
				this.focusInput('input[type="tel"]')
				this.renderGoogleButton('login')
			},
			async verifyAccount () {
				if ((await this.$refs[`${this.inputType}Validator`]?.validate())?.valid) {
					this.processing = true

					const payload = {}

					if (this.inputType === 'mobile') {
						payload.phone = {
							code: this.userName.code,
							number: this.userName.number,
							country: this.userName.country
						}
					} else {
						payload.email = this.userName
					}

					this.$store.dispatch('verifyAccount', {
						...payload
					}).then((response) => {
						if (response.code === 200) {
							this.showPassword = true
							this.focusInput('#login input[type="password"]')
						}

						this.processing = false
					}).catch((err) => {
						if (err.response && err.response.status === 404) {
							this.$f7router.navigate('/signup', {
								props: {
									[`user_${this.inputType}`]: this.userName
								}
							})

							setTimeout(() => {
								this.$f7.toast.create({
									text: this.$t(this.inputType === 'mobile' ? 'toastMsg.mobileNoNotReg' : 'toastMsg.emailNotRegistered'),
									closeTimeout: 3000
								}).open()
							}, 300)
						} else {
							this.showErrors(err)
						}

						this.processing = false
					})
				}
			},
			login (data) {
				const accountLockingTime = this.getLocalStorage('accountLockingTime')

				if (accountLockingTime) {
					const seconds = this.$moment(new Date())
						.diff(this.$moment(new Date(accountLockingTime)), 'seconds')

					if (seconds < 600) {
						let remaining = Math.round(10 - (seconds / 60))

						remaining = remaining === 0 ? 1 : remaining

						return this.$f7.toast.create({
							text: this.$t('toastMsg.loginAttemptExceeded', [remaining, this.$tc('min', remaining > 1 ? 2 : 1)]),
							closeTimeout: 3000
						}).open()
					}

					this.removeLocalStorage('accountLockingTime')
				}

				this.processing = true

				this.$store.dispatch('login', data).then((response) => {
					if (response.code === 200) {
						this.removeLocalStorage('accountLockingTime')

						if ((this.inputType === 'mobile' && response.data.info.is_phone_verified) || (this.inputType === 'email' && response.data.info.is_email_verified)) {
							this.$f7router.navigate(`/${this.merchant.settings.app.appearance.default_tab === 'explore' ? 'items' : 'home'}`, {
								clearPreviousHistory: true
							})
						} else {
							this.otpVerification = {
								type: 'signup',
								medium: this.inputType === 'mobile' ? `${this.userName.code} - ${this.userName.number}` : this.userName
							}
						}
					}

					this.processing = false
				}).catch((err) => {
					this.processing = false

					if ((data.gp_user_id || data.fb_user_id) && err.response && err.response.status === 401) {
						this.$f7router.navigate('/signup', { props: data })

						return setTimeout(() => {
							this.$f7.toast.create({
								text: this.$t('toastMsg.emailNotRegistered'),
								closeTimeout: 3000
							}).open()
						}, 300)
					} else if (err.response && err.response.status === 403) {
						this.setLocalStorage('accountLockingTime', new Date())
					}

					this.showErrors(err)
				})
			},
			async validateLogin () {
				if (await this.$refs.validator.validate()) {
					if (this.inputType === 'mobile') {
						this.login({
							phone: {
								code: this.userName.code,
								number: this.userName.number
							},
							password: this.password
						})
					} else {
						this.login({
							email: this.userName,
							password: this.password
						})
					}
				}
			},
			async forgotPassword () {
				if ((await this.$refs[`${this.inputType}Validator`]?.validate())?.valid) {
					this.processing = true
					const payload = {}

					if (this.inputType === 'mobile') {
						payload.phone = {
							code: this.userName.code,
							number: this.userName.number
						}
					} else {
						payload.email = this.userName
					}

					this.$store.dispatch('forgotPassword', {
						...payload
					}).then((response) => {
						if (response.code === 200) {
							this.otpVerification = {
								type: 'forgot-password',
								medium: this.inputType === 'mobile' ? `${this.userName.code} - ${this.userName.number}` : this.userName
							}
						}

						this.processing = false
					}).catch((err) => {
						this.showErrors(err)
						this.processing = false
					})
				}
			},
			socialLogin (data) {
				this.processing = true
				this.$bridge.connectSocialPlatform(
					this.$bridge.name === 'ANDROID' ? JSON.stringify(data) : data
				)
			},
			emitSocialPlatform (platform) {
				this.processing = false

				if (typeof platform === 'string') {
					platform = JSON.parse(platform)
				}

				if (platform.type === 'login') {
					if (platform.data) {
						const key = platform.name === 'google' ? 'gp' : 'fb'

						this.login({
							[`${key}_user_id`]: platform.data.id,
							[`${key}_data`]: platform.data
						})
					} else {
						this.$f7.toast.create({
							text: this.$t('toastMsg.platformError', [platform.name]),
							closeTimeout: 3000
						}).open()
					}
				}
			}
		}
	}
</script>
