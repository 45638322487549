<template>
	<f7-toolbar
		v-if="cart.items.length > 0"
		class="bg-color-primary"
		bottom
		@click.native="$f7router.navigate('/cart')"
	>
		<f7-row class="w-100 align-items-center px-3" style="line-height: 1">
			<f7-col width="60">
				<p class="text-color-white text-capitalize m-0">
					{{ cart.items.length }} {{ $tc('item', cart.items.length > 1 ? 2 : 1) }} | {{
						toCurrency(cart.price.subtotal + cart.price.tax)
					}}
				</p>
				<small class="text-color-white">{{ $t('extraCharges') }}</small>
			</f7-col>
			<f7-col width="40">
				<div class="display-flex justify-content-flex-end align-items-center">
					<p class="text-color-white text-uppercase my-0 mr-2">
						{{ $t('view cart') }}
					</p>
					<f7-icon f7="cart" size="22" color="white" />
				</div>
			</f7-col>
		</f7-row>
	</f7-toolbar>
</template>

<script>
	export default {
		computed: {
			locale () {
				return this.$store.state.locale
			},
			merchant () {
				return this.$store.state.merchant
			},
			cart () {
				return this.$store.state.cart
			}
		}
	}
</script>
