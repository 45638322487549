export default (ctx, inject) => {
	if (typeof window.bridge === 'object') {
		window.bridge.name = 'ANDROID'
		inject('bridge', window.bridge)
		delete window.bridge
	} else if (window.webkit?.messageHandlers?.getAppVersion) {
		inject('bridge', {
			name: 'IOS',
			getAppVersion: () => {
				window.webkit.messageHandlers.getAppVersion.postMessage(null)
			},
			getCurrentLocation: () => {
				window.webkit.messageHandlers.getCurrentLocation.postMessage(null)
			},
			connectSocialPlatform: (platform) => {
				window.webkit.messageHandlers.connectSocialPlatform.postMessage(platform)
			},
			minimize: () => {
				window.webkit.messageHandlers.minimize.postMessage(null)
			},
			initPg: (url) => {
				window.webkit.messageHandlers.initPg.postMessage(url)
			},
			closePg: () => {
				window.webkit.messageHandlers.closePg.postMessage(null)
			},
			openDialer: (phoneNumber) => {
				window.webkit.messageHandlers.openDialer.postMessage(phoneNumber)
			},
			openExternalUrl: (url) => {
				window.webkit.messageHandlers.openExternalUrl.postMessage(url)
			},
			download: (options) => {
				window.webkit.messageHandlers.download.postMessage(options)
			},
			share: (data) => {
				window.webkit.messageHandlers.share.postMessage(data)
			},
			getFirebaseToken: () => {
				window.webkit.messageHandlers.getFirebaseToken.postMessage(null)
			}
		})
	} else {
		inject('bridge', {
			name: 'WEB',
			getAppVersion: () => {
				window.emitAppVersion(process.env.APP_VERSION)
			},
			getCurrentLocation: () => {
				if (navigator.geolocation) {
					navigator.geolocation.getCurrentPosition((position) => {
						window.emitCurrentLocation({
							lat: position.coords.latitude,
							lng: position.coords.longitude
						})
					}, (err) => {
						window.emitCurrentLocation(null)

						if (err.code === 1) {
							window.$nuxt.$f7.dialog
								.alert('Please grant location access to find your location.', 'Access Required')
						}
					}, {
						maximumAge: 10000,
						timeout: 5000,
						enableHighAccuracy: true
					})
				} else {
					window.emitCurrentLocation(null)
					window.$nuxt.$f7.dialog
						.alert('Geolocation is not supported in your browser.', 'Unsupported')
				}
			},
			connectSocialPlatform: (platform) => {
				switch (platform.name) {
				case 'facebook':
					if (window.FB && window.FB.login) {
						const getFBUser = (response) => {
							if (response.status === 'connected') {
								window.FB.api('/me', {
									access_token: response.authResponse.accessToken,
									fields: 'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email'
								}, (fbUser) => {
									window.emitSocialPlatform({
										name: platform.name,
										type: platform.type,
										data: fbUser
									})
								})
							} else {
								window.emitSocialPlatform({
									name: platform.name,
									type: platform.type,
									data: null
								})
							}
						}

						window.FB.getLoginStatus((response) => {
							if (response.status === 'connected') {
								getFBUser(response)
							} else {
								window.FB.login(getFBUser, {
									scope: 'email',
									enable_profile_selector: true
								})
							}
						})
					} else {
						window.emitSocialPlatform({
							name: platform.name,
							type: platform.type,
							data: null
						})
					}

					break
				case 'google':
					window.emitSocialPlatform(platform)

					break
				}
			},
			minimize: () => {},
			initPg: (data) => {
				window.initPg(data)
			},
			closePg: () => {
				window.closePg()
			},
			openDialer: (phoneNumber) => {
				document.location.href = `tel:${phoneNumber}`
			},
			openExternalUrl: (url) => {
				window.open(url)
			},
			share (data) {
				if (navigator.share) {
					navigator.share(data).catch(console.error) // eslint-disable-line
				}
			}
		})
	}
}
