var render = function render(){var _vm=this,_c=_vm._self._c;return _c('f7-page',{attrs:{"id":"signup"},on:{"page:afterin":_vm.initSignup}},[_c('f7-navbar',{attrs:{"title":_vm.$options.filters.capitalize(_vm.$t('signUp')),"back-link":_vm.$t('back'),"back-link-force":""}}),_vm._v(" "),_c('div',{staticClass:"p-3"},[_c('ValidationObserver',{ref:"validator"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('name'),"rules":"required|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('custom-input',{attrs:{"type":"text","name":"name","placeholder":_vm.$options.filters.capitalize(_vm.$t('name')),"error":errors[0]},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"vid":"phone.number","name":_vm.$t('mobile number'),"rules":"mobileRequired|mobile"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('custom-input',{attrs:{"type":"tel","name":"mobile number","placeholder":_vm.$options.filters.capitalize(_vm.$t('mobile number')),"error":errors[0]},model:{value:(_vm.mobile),callback:function ($$v) {_vm.mobile=$$v},expression:"mobile"}})]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":_vm.$t('email'),"rules":{
					email: true,
					required: _vm.otpSource === 'email'
				}},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('custom-input',{attrs:{"type":"text","name":"email","placeholder":`${_vm.$options.filters.capitalize(_vm.$t('email'))} ${_vm.otpSource === 'email' ? '' : `(${_vm.$t('optional')})`}`,"error":errors[0]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":_vm.$t('password'),"rules":"required|min:8|max:32"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('custom-input',{attrs:{"type":"password","name":"password","placeholder":_vm.$options.filters.capitalize(_vm.$t('password')),"error":errors[0]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}])}),_vm._v(" "),(_vm.merchant.subscription.products && _vm.merchant.subscription.products.referral)?_c('ValidationProvider',{attrs:{"vid":"referral_code","name":_vm.$t('referral code'),"rules":"alpha_num|length:8"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('custom-input',{attrs:{"type":"text","name":"referral_code","placeholder":_vm.$options.filters.capitalize(_vm.$t('referral code')),"error":errors[0]},model:{value:(_vm.referralCode),callback:function ($$v) {_vm.referralCode=$$v},expression:"referralCode"}})]}}],null,false,1347545744)}):_vm._e(),_vm._v(" "),(_vm.$bridge.name === 'WEB')?_c('div',{staticClass:"mb-3",attrs:{"id":"google-signup-button"}}):_vm._e(),_vm._v(" "),(_vm.$bridge.name !== 'WEB' && _vm.merchant.settings.integrations.google_sign_in.enabled)?_c('f7-button',{staticClass:"display-flex mb-3 social-button",style:({
					background: _vm.merchant.settings.app.appearance.use_dark_theme ? 'black' : 'white',
				}),on:{"click":function($event){return _vm.connectSocialPlatform({name:'google', type:'signup'})}}},[_c('p',{staticClass:"w-100 m-0"},[_c('f7-row',{staticClass:"align-items-center",attrs:{"no-gap":""}},[_c('f7-col',{attrs:{"width":"20"}},[_c('img',{staticClass:"display-flex",attrs:{"src":"/images/google.svg","alt":"Google","width":"20px"}})]),_vm._v(" "),_c('f7-col',{attrs:{"width":"65"}},[_c('span',{staticClass:"font-weight-normal"},[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(_vm.$tc('signupWithGoogle'))+"\n\t\t\t\t\t\t\t")])]),_vm._v(" "),_c('f7-col',{attrs:{"width":"15"}})],1)],1),_vm._v(" "),_c('ValidationProvider',{staticClass:"position-absolute text-color-red",staticStyle:{"left":"75px","top":"26px","font-weight":"initial","text-transform":"initial"},attrs:{"vid":"gp_user_id","name":_vm.$t('google account'),"tag":"small"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_vm._v("\n\t\t\t\t\t"+_vm._s(errors[0])+"\n\t\t\t\t")]}}],null,false,3709096591)})],1):_vm._e(),_vm._v(" "),(_vm.merchant.settings.integrations.facebook_login.enabled)?_c('f7-button',{staticClass:"display-flex mb-3 social-button",style:({
					background: _vm.merchant.settings.app.appearance.use_dark_theme ? 'black' : 'white',
				}),on:{"click":function($event){return _vm.connectSocialPlatform({name:'facebook', type:'signup'})}}},[_c('p',{staticClass:"w-100 m-0"},[_c('f7-row',{staticClass:"align-items-center",attrs:{"no-gap":""}},[_c('f7-col',{attrs:{"width":"20"}},[_c('img',{staticClass:"display-flex",attrs:{"src":"/images/facebook.svg","alt":"Facebook","width":"20px"}})]),_vm._v(" "),_c('f7-col',{attrs:{"width":"65"}},[_c('span',{staticClass:"font-weight-normal"},[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(_vm.$tc('signupWithFB'))+"\n\t\t\t\t\t\t\t")])]),_vm._v(" "),_c('f7-col',{attrs:{"width":"15"}})],1)],1),_vm._v(" "),_c('ValidationProvider',{staticClass:"position-absolute text-color-red",staticStyle:{"left":"75px","top":"26px","font-weight":"initial","text-transform":"initial"},attrs:{"vid":"fb_user_id","name":_vm.$t('facebook account'),"tag":"small"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_vm._v("\n\t\t\t\t\t"+_vm._s(errors[0])+"\n\t\t\t\t")]}}],null,false,3709096591)})],1):_vm._e()],1),_vm._v(" "),_c('f7-button',{attrs:{"large":"","raised":"","fill":"","disabled":_vm.processing},on:{"click":_vm.signUp}},[_vm._v("\n\t\t\t"+_vm._s(_vm.$t('create account'))+"\n\t\t\t"),_c('f7-icon',{staticClass:"float-right",staticStyle:{"top":"10px"},attrs:{"f7":"arrow_right"}})],1),_vm._v(" "),_c('p',{staticClass:"text-align-center mb-0"},[_vm._v("\n\t\t\t"+_vm._s(_vm.$t('byCreatingAnAccount'))+"\n\t\t")]),_vm._v(" "),_c('p',{staticClass:"text-align-center m-0"},[_vm._v("\n\t\t\t"+_vm._s(_vm.$t('iAcceptThe'))+"\n\t\t\t"),_c('f7-link',{staticClass:"underline text-capitalize",attrs:{"href":"/legal/terms-conditions"}},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t('t&c'))+"\n\t\t\t")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }