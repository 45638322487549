<template>
	<f7-page id="signup" @page:afterin="initSignup">
		<f7-navbar :title="$options.filters.capitalize($t('signUp'))" :back-link="$t('back')" back-link-force />
		<div class="p-3">
			<ValidationObserver ref="validator">
				<ValidationProvider
					v-slot="{ errors }"
					:name="$t('name')"
					rules="required|alpha_spaces"
				>
					<custom-input
						v-model="name"
						type="text"
						name="name"
						:placeholder="$options.filters.capitalize($t('name'))"
						:error="errors[0]"
					/>
				</ValidationProvider>
				<ValidationProvider
					v-slot="{ errors }"
					vid="phone.number"
					:name="$t('mobile number')"
					rules="mobileRequired|mobile"
				>
					<custom-input
						v-model="mobile"
						type="tel"
						name="mobile number"
						:placeholder="$options.filters.capitalize($t('mobile number'))"
						:error="errors[0]"
					/>
				</ValidationProvider>
				<ValidationProvider
					v-slot="{ errors }"
					:name="$t('email')"
					:rules="{
						email: true,
						required: otpSource === 'email'
					}"
				>
					<custom-input
						v-model="email"
						type="text"
						name="email"
						:placeholder="`${$options.filters.capitalize($t('email'))} ${otpSource === 'email' ? '' : `(${$t('optional')})`}`"
						:error="errors[0]"
					/>
				</ValidationProvider>
				<ValidationProvider
					v-slot="{ errors }"
					:name="$t('password')"
					rules="required|min:8|max:32"
				>
					<custom-input
						v-model="password"
						type="password"
						name="password"
						:placeholder="$options.filters.capitalize($t('password'))"
						:error="errors[0]"
					/>
				</ValidationProvider>
				<ValidationProvider
					v-if="merchant.subscription.products && merchant.subscription.products.referral"
					v-slot="{ errors }"
					vid="referral_code"
					:name="$t('referral code')"
					rules="alpha_num|length:8"
				>
					<custom-input
						v-model="referralCode"
						type="text"
						name="referral_code"
						:placeholder="$options.filters.capitalize($t('referral code'))"
						:error="errors[0]"
					/>
				</ValidationProvider>
				<div
					v-if="$bridge.name === 'WEB'"
					id="google-signup-button"
					class="mb-3"
				/>
				<f7-button
					v-if="$bridge.name !== 'WEB' && merchant.settings.integrations.google_sign_in.enabled"
					class="display-flex mb-3 social-button"
					:style="{
						background: merchant.settings.app.appearance.use_dark_theme ? 'black' : 'white',
					}"
					@click="connectSocialPlatform({name:'google', type:'signup'})"
				>
					<p class="w-100 m-0">
						<f7-row class="align-items-center" no-gap>
							<f7-col width="20">
								<img src="/images/google.svg" alt="Google" width="20px" class="display-flex">
							</f7-col>
							<f7-col width="65">
								<span class="font-weight-normal">
									{{ $tc('signupWithGoogle') }}
								</span>
							</f7-col>
							<f7-col width="15" />
						</f7-row>
					</p>
					<ValidationProvider
						v-slot="{ errors }"
						vid="gp_user_id"
						:name="$t('google account')"
						tag="small"
						class="position-absolute text-color-red"
						style="left: 75px; top: 26px; font-weight: initial; text-transform: initial"
					>
						{{ errors[0] }}
					</ValidationProvider>
				</f7-button>

				<f7-button
					v-if="merchant.settings.integrations.facebook_login.enabled"
					class="display-flex mb-3 social-button"
					:style="{
						background: merchant.settings.app.appearance.use_dark_theme ? 'black' : 'white',
					}"
					@click="connectSocialPlatform({name:'facebook', type:'signup'})"
				>
					<p class="w-100 m-0">
						<f7-row class="align-items-center" no-gap>
							<f7-col width="20">
								<img src="/images/facebook.svg" alt="Facebook" width="20px" class="display-flex">
							</f7-col>
							<f7-col width="65">
								<span class="font-weight-normal">
									{{ $tc('signupWithFB') }}
								</span>
							</f7-col>
							<f7-col width="15" />
						</f7-row>
					</p>
					<ValidationProvider
						v-slot="{ errors }"
						vid="fb_user_id"
						:name="$t('facebook account')"
						tag="small"
						class="position-absolute text-color-red"
						style="left: 75px; top: 26px; font-weight: initial; text-transform: initial"
					>
						{{ errors[0] }}
					</ValidationProvider>
				</f7-button>
			</ValidationObserver>
			<f7-button
				large
				raised
				fill
				:disabled="processing"
				@click="signUp"
			>
				{{ $t('create account') }}
				<f7-icon class="float-right" f7="arrow_right" style="top: 10px" />
			</f7-button>
			<p class="text-align-center mb-0">
				{{ $t('byCreatingAnAccount') }}
			</p>
			<p class="text-align-center m-0">
				{{ $t('iAcceptThe') }}
				<f7-link
					href="/legal/terms-conditions"
					class="underline text-capitalize"
				>
					{{ $t('t&c') }}
				</f7-link>
			</p>
		</div>
	</f7-page>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from 'vee-validate'
	import CustomInput from '~/components/custom-input'

	export default {
		components: {
			CustomInput,
			ValidationObserver,
			ValidationProvider
		},
		props: {
			user_mobile: { // eslint-disable-line
				type: Object,
				default: null
			},
			gp_data: { // eslint-disable-line
				type: Object,
				default: null
			},
			fb_data: { // eslint-disable-line
				type: Object,
				default: null
			},
			user_email: { // eslint-disable-line
				type: String,
				default: ''
			}
		},
		data () {
			return {
				name: '',
				mobile: {
					code: '',
					number: ''
				},
				email: '',
				password: '',
				google: null,
				facebook: null,
				signingUp: false
			}
		},
		computed: {
			merchant () {
				return this.$store.state.merchant
			},
			referralCode: {
				get () {
					return this.$store.state.referralCode
				},
				set (value) {
					this.$store.commit('setState', { key: 'referralCode', value })
				}
			},
			processing: {
				get () {
					return this.$store.state.processing
				},
				set (value) {
					this.$store.commit('setState', { key: 'processing', value })
				}
			},
			otpVerification: {
				get () {
					return this.$store.state.otpVerification
				},
				set (value) {
					this.$store.commit('setState', { key: 'otpVerification', value })
				}
			},
			otpSource () {
				return this.$store.state.merchant.settings.general.registration_verification_source
			}
		},
		beforeMount () {
			if (this.user_mobile) {
				this.mobile = { ...this.user_mobile }
			}

			if (this.user_email) {
				this.email = this.user_email
			}

			if (this.gp_data) {
				this.name = this.gp_data.name
				this.email = this.gp_data.email
				this.google = Object.assign({}, this.gp_data)
			}

			if (this.fb_data) {
				this.name = this.fb_data.name
				this.email = this.fb_data.email
				this.facebook = Object.assign({}, this.fb_data)
			}
		},
		mounted () {
			if (this.merchant.settings.integrations.google_sign_in.enabled) {
				this.initGoogleSdk(this.merchant.settings.integrations.google_sign_in.client_id)
			}

			if (this.merchant.settings.integrations.facebook_login.enabled && this.$bridge.name === 'WEB') {
				this.initFacebookSdk(this.merchant.settings.integrations.facebook_login.app_id)
			}

			window.emitSocialPlatform = this.emitSocialPlatform
		},
		methods: {
			initSignup () {
				this.focusInput('input[type="text"]')
				this.renderGoogleButton('signup')
			},
			async signUp () {
				if (await this.$refs.validator.validate()) {
					this.processing = true
					this.$store.dispatch('register', {
						name: this.name,
						phone: {
							code: this.mobile.code,
							number: this.mobile.number,
							country: this.mobile.country
						},
						email: this.email,
						password: this.password,
						referral_code: this.referralCode,
						gp_user_id: this.google ? this.google.id : null,
						gp_data: this.google,
						fb_user_id: this.facebook ? this.facebook.id : null,
						fb_data: this.facebook
					}).then((response) => {
						if (response.code === 201) {
							this.otpVerification = {
								type: 'signup',
								medium: this.otpSource === 'email' ? this.email : `${this.mobile.code} - ${this.mobile.number}`
							}
						} else {
							this.$f7.dialog.alert(this.$t('errMsg'), `${this.$options.filters.capitalize(this.$t('oops'))}!`)
						}
					}).catch(this.showErrors).finally(() => {
						this.processing = false
					})
				}
			},
			connectSocialPlatform (data) {
				if (this[data.name] === null) {
					this.processing = true
					this.$bridge.connectSocialPlatform(
						this.$bridge.name === 'ANDROID' ? JSON.stringify(data) : data
					)
				}
			},
			emitSocialPlatform (platform) {
				if (typeof platform === 'string') {
					platform = JSON.parse(platform)
				}

				if (platform.type === 'signup') {
					if (platform.data) {
						this.email = platform.data.email
						this.name = platform.data.name
						this[platform.name] = platform.data
					} else {
						this.$f7.toast.create({
							text: this.$t('toastMsg.platformError', [platform.name]),
							closeTimeout: 3000
						}).open()
					}
				}

				this.processing = false
			}
		}
	}
</script>
