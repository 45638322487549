<template>
	<div
		:class="`position-relative bg-color-${
			merchant.settings.app.appearance.use_dark_theme ? 'black' : 'white'
		} rounded elevation-4 p-3 mb-3`"
	>
		<vue-tel-input
			v-if="type === 'tel'"
			mode="international"
			:name="name"
			:value="value.number"
			class="text-capitalize"
			:input-options="{ placeholder: placeholder, styleClasses: 'text-capitalize' }"
			:default-country="value.country ? value.country.code : (
				merchant.settings.general.country_code || ''
			)"
			:disabled-fetching-country="(
				value.country ? value.country.code : merchant.settings.general.country_code
			) ? true : false"
			@input="updateValue"
		/>
		<input
			v-else
			ref="input"
			:type="type"
			:name="name"
			:value="value"
			:placeholder="placeholder"
			class="w-100"
			:style="{
				paddingRight: type === 'password' ? '30px' : false
			}"
			:data-tooltip="tooltip"
			@input="updateValue"
			@focus="events.focus ? events.focus($event) : null"
			@blur="events.blur ? events.blur($event) : null"
		>
		<f7-link
			v-if="type === 'password'"
			class="eye"
			:icon-f7="showPassword ? 'eye_slash_fill' : 'eye_fill'"
			color="gray"
			@click="togglePassword"
		/>
		<small class="position-absolute text-color-red">{{ error }}</small>
	</div>
</template>

<script>
	import { VueTelInput } from 'vue-tel-input'

	export default {
		name: 'CustomInput',
		components: {
			VueTelInput
		},
		$_veeValidate: {
			name () {
				return this.name
			},
			value () {
				return this.value
			}
		},
		props: {
			type: {
				type: String,
				default: 'text'
			},
			name: {
				type: String,
				default: ''
			},
			value: {
				type: [String, Object],
				default: null
			},
			placeholder: {
				type: String,
				default: ''
			},
			tooltip: {
				type: String,
				default: ''
			},
			error: {
				type: String,
				default: ''
			},
			events: {
				type: Object,
				default: () => ({})
			}
		},
		data () {
			return {
				showPassword: false
			}
		},
		computed: {
			merchant () {
				return this.$store.state.merchant
			}
		},
		methods: {
			togglePassword () {
				this.$refs.input.type = this.$refs.input.type === 'text' ? 'password' : 'text'
				this.showPassword = !this.showPassword
			},
			updateValue ($event, data) {
				if (this.type === 'tel') {
					this.$emit('input', {
						code: data.countryCallingCode ? `+${data.countryCallingCode}` : '',
						number: data.nationalNumber || $event,
						eventData: $event,
						country: {
							code: data.country?.iso2,
							name: data.country?.name
						},
						isValid: data.valid
					})
				} else {
					this.$emit('input', typeof $event === 'string' ? $event : $event.target.value)
				}
			}
		}
	}
</script>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>

<style lang="css" scoped>
	input {
		font-size: 16px;
	}

	small {
		bottom: 1px;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		display: -webkit-box;
	}

	.eye {
		position: absolute;
		top: 14px;
		right: 15px;
	}
</style>
